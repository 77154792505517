import React, {useEffect, useState} from 'react';
import AnnaTalkingAvatar from './AnnaTalkingAvatar';
import {TypeAnimation} from 'react-type-animation';
import getVideoDuration from '../../../helpers/getVideoDuration';

const MessageWidget = (props) => {
    const {payload, messages, setState, state, setOptionsShown} = props
    const [showMessage, setShowMessage] = useState(true)
    const [videoLoaded, setVideoLoaded] = useState(false)
    const {message} = payload


    // const getMessage=()=>{
    //     if (message.includes('My dad always says')) {
    //         const messageSplit = message.split('- ')
    //         return (
    //
    //             [message]
    //         )
    //     } else {
    //         return [message]
    //     }
    // }
    // const renderMessage = () => {
    //     if (message.includes('My dad always says')) {
    //         const messageSplit = message.split('- ')
    //         return (
    //             <>
    //                 <div>{`- ${messageSplit[0]} ${messageSplit[1]}`}</div>
    //                 <div>{`- ${messageSplit[2]}`}</div>
    //             </>
    //         )
    //     } else {
    //         return <span>{message}</span>
    //     }
    // }

    // const [dur, setDur] = useState(null)

    // const getPreviousVideoMessage = () => {
    //     const currentVideoIndex = messages.findIndex(el => el.payload?.video === payload?.video)
    //     if (currentVideoIndex !== 0) {
    //         return messages[currentVideoIndex - 1]
    //     } else return null
    // }
    // useEffect(() => {
    //     const previousVideoMessage = getPreviousVideoMessage()
    //     if (!!previousVideoMessage && previousVideoMessage.type === 'bot') {
    //         getVideoDuration(`./assets/videos/${previousVideoMessage.payload.video}.mp4`).then((res => {
    //             setDur(res + 2)
    //         }))
    //     } else {
    //         setDur(0)
    //     }
    // }, []);

    // useEffect(() => {
    //     if (dur !== null) {
    //         if (payload.video) {
    //             const videoDurationInMiliseconds = (dur) * 1000
    //
    //             setTimeout(() => {
    //                 setShowMessage(true)
    //
    //             }, videoDurationInMiliseconds)
    //         } else {
    //             setShowMessage(true)
    //
    //         }
    //     }
    // }, [dur]);

    return showMessage ? (
        <div className={'d-flex w-100'}>


            <AnnaTalkingAvatar video={payload.video}
                               setVideoLoaded={setVideoLoaded}
                               isFirstVideo={messages?.length === 1}
                               // setDisableInput={(value)=>setState({...state, disableInput: value})}
                               setOptionsShown={setOptionsShown}
            />
            <div className="react-chatbot-kit-chat-bot-message ">
                {videoLoaded ? <TypeAnimation
                        sequence={
                           [message]
                        }
                        wrapper="span"
                        speed={40}
                        style={{display: 'inline-block'}}
                        cursor={false}
                        omitDeletionAnimation={true}
                    /> :
                    <span className={'chatbot-loader-container'}/>
                }
                <div className="react-chatbot-kit-chat-bot-message-arrow"></div>
            </div>


        </div>
    ) : null
}


export default MessageWidget;
