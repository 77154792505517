import React, {useEffect, useRef, useState} from 'react';
import getDelay from '../../../helpers/getDelay';
const messagesVideoNames=['hello',
    // 'sorry',
    "batteryLifeQuestionClear",
    "weightQuestionClear",
    "noiseCancellationQualityQuestion",
    "bluetoothQuestionClear",
    "androidQuestion",
    "iosQuestion",
    "priceQuestionClear",
    "jokeQuestion",
undefined]
const AnnaTalkingAvatar = ({video,  isFirstVideo, setVideoLoaded, setDisableInput, setOptionsShown}) => {

    const [shouldAutoplay, setShouldAutoplay] = useState(!!isFirstVideo)

    const videoRef = useRef(null);
    const inputElement = document.querySelector('.react-chatbot-kit-chat-input');
    const buttonElement = document.querySelector('.react-chatbot-kit-chat-btn-send');
    const disableInputElements = (shouldDisable) => {


        if (inputElement) {
            inputElement.disabled = shouldDisable;
            if(!shouldDisable) {
                inputElement.focus()
            }
        }

        if (buttonElement) {
            buttonElement.disabled = shouldDisable;
        }
    };

    useEffect(() => {
        disableInputElements(true)
    }, [video]);
    const handleEnded = () => {
        if(messagesVideoNames.includes(video)) {
            disableInputElements(false)
        }
        if(setOptionsShown) {
            setOptionsShown(true)
        }
    };

    useEffect(() => {
        const videoElement = videoRef.current;
        const handleLoadedData = () => {
            setVideoLoaded(true);
            videoElement.play();  // Trigger play manually

            videoElement.addEventListener('ended', handleEnded);

        };

        if (videoElement) {


            videoElement.addEventListener('loadeddata', handleLoadedData);


            return () => {
                videoElement.removeEventListener('ended', handleEnded);
                // videoElement.removeEventListener('loadeddata', handleLoadedData);

            };
        }
    }, [setDisableInput, video, setVideoLoaded]);

    useEffect(() => {
       if(!video) {
           setVideoLoaded(true)
       }
    }, []);


    return (
        <div className="react-chatbot-kit-chat-bot-avatar-container" bis_skin_checked="1">
            {video ? <video src={ `/assets/videos/${video}.mp4`}
                            key={video}
                            muted={false}
                            autoPlay={false}
                            className={'bot-video-avatar'}
                            ref={videoRef}
                            preload={'auto'}
                            playsInline={true}
                            // onLoadedData={()=>{
                            //     console.log('onloadeddata')
                            //     setVideoLoaded(true)
                            //     videoRef.current.play()
                            // }}
                /> :
                <img alt={'anna-avatar'}
                  src={process.env.PUBLIC_URL + '/assets/img/research-guide/anna.jpeg'}
                  className="react-chatbot-kit-chat-bot-avatar-image"
                     onLoadedData={()=>{
                    setVideoLoaded(true)
                }}
                />}

        </div>
    );
};

export default AnnaTalkingAvatar;
