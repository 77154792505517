export class SimpleBot {
    constructor() {
        this.batteryLifeQuestionClear = `Batteries often last between 20 and 60 hours`;
        this.weightQuestionClear = `Headphone Tech’s headphones are between 99 and 440 grams`
        this.bluetoothQuestionClear = `This range for different headphones is usually between 9 and 30 meters.`
        this.frequencyQuestionClear = `We have two types of frequency response: 20 Hz - 20,000 Hz and 4 Hz - 40,000 Hz`
        this.chargingTimeQuestionClear = `This time for different headphones is usually between 1.5 and 7 hours.`
        this.priceQuestion2Clear=`The algorithm will do its best to find you a headphone that meets most of your  preferences and costs close to your  preferred price.`
        this.priceQuestionClear = `Please note that Headphone Tech’s noise-cancelling headphones cost between 119 and 375 USD.`
        this.warrantyBetweenClear = `So do you prefer 12 months or 18 months of warranty`
    }

    warrantyLowerClear() {
        return ({
            message: `Headphone Tech’s headphones have either 12 or 18 months warranty. So, do you prefer the warranty to be 12 months or 18 months?`

        })
    }

    sorry() {
        return ({message: `Sorry didn't understand you`, id: 'sorry'})
    }

    hello() {
        return ({
            message: `Hello, this is the headphone virtual advisor at Headphone Tech.`, id: 'hello'
        })
    }


    helpToFind() {
        return ({
            message: `Please answer the following questions about your headphone needs.`,
            id: 'helpToFind'
        })
    }

    batteryLifeQuestion() {
        return ({
            message: `How many hours do you need your headphone’s battery to last after every charge?`,
            id: 'batteryLifeQuestion'
        })
    }

    batteryLifeResponseAnswer1() {
        return ({
            message: `OK, thank you. You need a headphone with a short battery life.`,
            id: 'batteryLifeResponseAnswer1'
        })
    }

    batteryLifeResponseAnswer2() {
        return ({
            message: `OK, thank you. You need a headphone with a very long battery life. Please note that the longest lasting battery at Headphone Tech is 60 hours`,
            id: 'batteryLifeResponseAnswer2'
        })
    }

    batteryLifeResponseAnswer3() {
        return ({
            message: 'OK, thank you.',
            id: 'batteryLifeResponseAnswer3'
        })
    }

    weightQuestion() {
        return ({
            message: `How much (in grams) do you prefer our headphone to weigh?`,
            id: 'weightQuestion'
        })
    }

    weightResponseAnswer1(weight) {
        return ({
            message: `OK, thank you.`,
            id: 'weightResponseAnswer1'
        })
    }

    weightResponseAnswer2(weight) {
        return ({
            message: `OK, thank you. You need a very light headphone. Please note thatat Headphone Tech, the lightest headphone weighs 99 grams.`,
            id: 'weightResponseAnswer2'
        })
    }

    weightResponseAnswer3(weight) {
        return ({
            message: `OK, thank you.`,
            id: 'weightResponseAnswer3'
        })
    }

    weightResponseAnswer4(weight) {
        return ({
            message: `OK, thank you. You do not need a light headphone.`,
            id: 'weightResponseAnswer4'
        })
    }

    noiseCancellationQualityQuestion() {
        return ({
            message: `What level of noise cancellation quality do you prefer your headphone to have? This quality ranges between 3.5 and 5 in increments of 0.5.`,
            id: 'noiseCancellationQualityQuestion'
        })
    }

    noiseCancellationQualityResponseAnswer1() {
        return ({
            message: `OK, thank you. You do not need a headphone with high quality noise cancellation.`,
            id: 'noiseCancellationQualityResponseAnswer1'
        })
    }

    noiseCancellationQualityResponseAnswer2() {
        return ({
            message: `OK, thank you. You need a headphone with great noise cancellation quality. 
            Please note that 5 indicates the highest quality for noise cancellation.`,
            id: 'noiseCancellationQualityResponseAnswer2'
        })
    }

    noiseCancellationQualityResponseAnswer3() {
        return ({
            message: `OK, thank you.`,
            id: 'noiseCancellationQualityResponseAnswer3'
        })
    }

    noiseCancellationQualityResponseAnswer4() {
        return ({
            message: `Sure, I will try to find you a headphone with great noise cancellation quality.`,
            id: 'noiseCancellationQualityResponseAnswer4'
        })
    }

//     noiseCancellingQuestion() {
//         return ({
//             message: `What type of noise-cancelling do you
// prefer your headphone to use? Passive,
// active, or adaptive?`,
//             id: 'noiseCancellingQuestion'
//         })
//     }

//     noiseCancellingResponseNotValid() {
//         return ({
//             message: `You might have misunderstood
// the question. Noise-cancelling
// headphones can be passive,
// active, or adaptive. Which of the
// three types do you prefer?`,
//             id: 'noiseCancellingResponseNotValid'
//         })
//     }

//     noiseCancellingResponseValid() {
//         return ({
//             message: `Great; thank you.`,
//             id: 'noiseCancellingResponseValid'
//         })
//     }

    bluetoothQuestion() {
        return ({
            message: `How many meters do you need your headphone Bluetooth range to be?`,
            id: 'bluetoothQuestion'
        })
    }

    bluetoothResponseAnswer1(bluetoothMeters) {
        return ({
            message: `Thank you for your response.`,
            id: 'bluetoothResponseAnswer1'
        })
    }

    bluetoothResponseAnswer2(bluetoothMeters) {
        return ({
            message: `Thank you for your response. Please note that the longest Bluetooth range Headphone Tech has, is 30 meters.`,
            id: 'bluetoothResponseAnswer2'
        })
    }

    frequencyQuestion() {
        return ({
            message: `What frequency response do you prefer your headphone to have?`,
            id: 'frequencyQuestion'
        })
    }

    frequencyResponseNotValidAnswer() {
        return ({
            message: `
There might be a misunderstanding. Headphone Tech has one of the two following frequency responses: 20 Hz - 20,000 Hz and 4 Hz - 40,000 Hz. Which one do you prefer?`,
            id: 'frequencyResponseNotValidAnswer'
        })
    }

    frequencyResponseValidAnswer(frequency) {
        return ({
            message: `OK, thank you for your response.`,
            id: 'frequencyResponseValidAnswer'
        })
    }

    chargingTimeQuestion() {
        return ({
            message: `How many hours can you wait for a full recharge of your headphone?`,
            id: 'chargingTimeQuestion'
        })
    }

    chargingTimeResponseNotValidAnswer() {
        return ({
            message: `Please note that Headphone Tech’s products take 1.5 to 7 hours to recharge fully. What is your preference in terms of waiting for a full recharge of your headphone.`,
            id: 'chargingTimeResponseNotValidAnswer'
        })
    }

    chargingTimeResponseValidAnswer(chargingTime) {
        return ({
            message: `OK, thank you for providing this information.`,
            id: 'chargingTimeResponseValidAnswer'
        })
    }

    foldableQuestion() {
        return ({
            message: `Do you need your headphone to be foldable?`,
            id: 'foldableQuestion'
        })
    }

    foldableResponseAnswer1() {
        return ({
            message: `Thank you. Your preference regarding headphone foldability is noted.`,
            id: 'foldableResponseAnswer1'
        })
    }

    foldableResponseAnswer2() {
        return ({
            message: `Thank you. Your preference regarding headphone foldability is noted.`,
            id: 'foldableResponseAnswer2'
        })
    }

    androidQuestion() {
        return ({
            message: `Do you need your headphone to have Android control?`,
            id: 'androidQuestion'
        })
    }

    androidResponseAnswer1() {
        return ({
            message: `Noted; thank you.`,
            id: 'androidResponseAnswer1'
        })
    }

    androidResponseAnswer2() {
        return ({
            message: `Noted; thank you.`,
            id: 'androidResponseAnswer2'
        })
    }

    iosQuestion() {
        return ({
            message: `Do you need your headphone to have iOS control?`,
            id: 'iosQuestion'
        })
    }

    iosResponseAnswer1() {
        return ({
            message: `Sure, thank you.`,
            id: 'iosResponseAnswer1'
        })
    }

    iosResponseAnswer2() {
        return ({
            message: `Sure, thank you.`,
            id: 'iosResponseAnswer2'
        })
    }

    googleQuestion() {
        return ({
            message: `Do you need your headphone to work with Google Assistant?`,
            id: 'googleQuestion'
        })
    }

    googleResponseAnswer1() {
        return ({
            message: `All right; thank you for indicating your preference.`,
            id: 'googleResponseAnswer1'
        })
    }

    googleResponseAnswer2() {
        return ({
            message: `All right; thank you for indicating your preference.`,
            id: 'googleResponseAnswer2'
        })
    }


    alexaQuestion() {
        return ({
            message: `Do you need your headphone to work with Amazon Alexa?`,
            id: 'alexaQuestion'
        })
    }

    alexaResponseAnswer1() {
        return ({
            message: `Your preference is noted.`,
            id: 'alexaResponseAnswer1'
        })
    }

    alexaResponseAnswer2() {
        return ({
            message: `Your preference is noted.`,
            id: 'alexaResponseAnswer2'
        })
    }

    warrantyQuestion() {
        return ({
            message: `How long do you need the warranty to be? 12 months or 18 months`,
            id: 'warrantyQuestion'
        })
    }

    warrantyResponseValidAnswer() {
        return ({
            message: `OK, thank you for indicating your preference.`,
            id: 'warrantyResponseValidAnswer'
        })
    }

    warrantyResponseNotValidAnswer() {
        return ({
            message: `Thank you for indicating your preference. However, please note that the longest warranty time of Headphone Tech’s headphones is 18 months.`,
            id: 'warrantyResponseNotValidAnswer'
        })
    }

    priceQuestion() {
        return ({
            message: `How much is the price (in USD) you are comfortable paying for the headphone.`,
            id: 'priceQuestion'
        })
    }

    priceResponseValidAnswer(price) {
        return ({
            message: `Thank you. Headphone Tech has headphones that are close to your preferred price.`,
            id: 'priceResponseValidAnswer'
        })
    }

    priceResponseNotValidAnswer(price) {
        return ({
            message: `Thank you for providing your preference. Please note that at Headphone Tech, noise-cancelling headphones are between 119 and 375 USD.`,
            id: 'priceResponseNotValidAnswer'
        })
    }

    mostImportantFeature1Question() {
        return ({
            type: 'radio',
            message: `What is the most important feature of noise-cancelling headphones for you?`,
            name: 'features1',
            id: 'mostImportantFeature1Question'
        })
    }

    mostImportantFeature1ResponseAnswer() {
        return ({
            message: `Your preference is noted.`,
            id: 'mostImportantFeature1ResponseAnswer'
        })
    }


    mostImportantFeature2Question() {
        return ({
            type: 'radio',
            message: `What is the second most important feature of noise-cancelling headphones for you?`,
            name: 'features2',
            id: 'mostImportantFeature2Question'
        })
    }

    mostImportantFeature2ResponseAnswer() {
        return ({
            message: `Thank you for indicating your preference.`,
            id: 'mostImportantFeature2ResponseAnswer'
        })
    }

//     thanks() {
//         return ({
//             message: `Thank you! Your answers were very
// helpful.`, id: 'thanks'
//         })
//     }
    additionalQuestionsQuestion(){
        return ({
            type: 'button questions',
            message: `Also, before you are provided with a recommendation, can you please answer a few questions about your interaction with this virtual advisor? Thank you`,
            name: 'additionalQuestionsQuestion',
            id: 'additionalQuestionsQuestion'
        })
    }
    foundRecommended() {
        return {
            message: `Based on your answers, here is the headphone that best suits your requirements.`,
            //For additional questions vv
            // message: `Thank you so much for your responses. Based on your answers, here is the headphone that best suits your requirements.`,
            type: 'headphones',
            name: 'product',
            id: 'foundRecommended'
        }
    }


//     jobComplete() {
//         return ({
//             message: `The product recommendation process is
// complete now.
// It would be great if you can provide
// some feedback about your experience
// of interacting with Headphone Tech’s
// virtual advisor to find the best
// headphone that match your
// preferences.`,
//             id: 'jobComplete'
//         })
//     }

    lastMessage() {
        return ({
            type: 'radio links',
            message: `What do you think about this recommendation?`,
            name: 'lastMessage',
            id: 'lastMessage'
        })
    }
    finishFirst() {
        return ({
            type: 'button ok',
            message: `Thank you. You will now be forwarded to a survey to provide some feedback about your experience of interacting with Headphone Tech ́s virtual advisor to find the best headphone that match your preferences.`,
            name: 'finishFirst',
            id: 'finishFirst'
        })
    }
    finishSecondOne() {
        return ({
            type: 'button ok',
            message: `Thank you. You will now be forwarded to a page where you can check out our other headphone models.`,
            name: 'finishSecondOne',
            id: 'finishSecondOne'
        })
    }
    finishSecondTwo() {
        return ({
            type: 'button ok',
            message: `Afterwards, you will be forwarded to a survey to provide some feedback about your experience of interacting with Headphone Tech ́s virtual advisor to find the best headphone that match your preferences.`,
            name: 'finishSecondTwo',
            id: 'finishSecondTwo'
        })
    }
}



