import React from 'react';
import {useSelector} from 'react-redux';

const StepTwo = () => {
    const {botType} = useSelector((state) => state.user);
    return (
        <div className={'pt-20'}>
            <h3 className={'mb-5'}>Welcome! We need your help!</h3>
            <div>
                <p> At <i>Headphone Tech</i>, we design, manufacture and sell audio equipment.</p>
                <p>To better assist our consumers in selecting the best headphones that fit their requirements, we

                    {botType === 'human-like' || botType === 'talking-head-f' || botType === 'talking-head-m' ?
                        <span>have recently started to use the services of a “virtual advisor“, called {' '}
                            <b className={'text-decoration-underline'}>{botType === 'talking-head-f'||botType === 'human-like'  ? 'Anna' : 'Michael'}</b>.</span> :
                        <span>
                            have recently developed a <span
                            className={'text-decoration-underline'}>&ldquo;virtual advisor&ldquo;</span>.
                        </span>}
                </p>
                <p>{botType === 'machine-like' ? 'The virtual advisor is a computer program that asks consumers a number of questions \n' +
                    'about their requirements and preferences and suggest the headphones that best match\n' +
                    'them' : `${botType === 'talking-head-f'||botType === 'human-like' ? 'Anna' : 'Michael'}’s job is to ask consumers a number of questions about their requirements and \n` +
                    'preferences and suggest the headphones that best match them.'}</p>
                <p>In this study, we ask
                    you {botType === 'machine-like' ? 'to use the virtual advisor and assess its recommendations' : `to interact with ${botType === 'talking-head-f' ||botType === 'human-like' ? 'Anna' : 'Michael'} and assess her recommendations`}.</p>
                <div className={'d-flex justify-content-end w-100'}>
                    { botType === 'talking-head-f' ?
                            <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/Human_Like_f.jpg'}
                                 className={'headphones-image'}/>
                            : botType === 'human-like'  ?
                            <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/Human_Like.jpg'}
                                 className={'headphones-image'}/> :
                        <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/Machine_Like.jpg'}
                                               className={'headphones-image'}/>

                    }
                </div>
            </div>
        </div>
    );
};

export default StepTwo;
