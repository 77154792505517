import React from 'react';

const AnnaAvatar = () => {
    return (
        <div className="react-chatbot-kit-chat-bot-avatar-container" bis_skin_checked="1">
            <img alt={'anna-avatar'}
                 src ={process.env.PUBLIC_URL + '/assets/img/research-guide/anna.jpeg'}
                className="react-chatbot-kit-chat-bot-avatar-image"/>

        </div>
    );
};

export default AnnaAvatar;
