import React from 'react';
import {useSelector} from 'react-redux';

const StepFour = () => {
    const {botType} = useSelector((state) => state.user);
    return (
        <div className={'pt-20'}>
            <h3 className={'mb-5'}>What is the challenge?</h3>
            <div>
                <p>Our <span className={'text-decoration-underline'}>virtual advisor{botType==='human-like' || botType === 'talking-head-f' || botType === 'talking-head-m' ? `,${botType === 'talking-head-f'||botType === 'human-like' ? 'Anna':'Michael'},`:''}</span> will ask you a number of questions about your <span className={'text-decoration-underline'}>preferences</span> for a pair of over-ear noise-cancelling headphones.</p>
                <p>{botType==='human-like' || botType === 'talking-head-f' ? 'She':'It'} will then provide you with <span className={'text-decoration-underline'}>recommendations</span> as to which headphones best match your preferences.</p>
                <p>Your task is to <span className={'text-decoration-underline'}>answer {botType==='human-like' || botType === 'talking-head-f'  || botType === 'talking-head-m' ? `${botType === 'talking-head-f'||botType === 'human-like' ? 'Anna':'Michael'}’s` : 'virtual advisor’s'} questions and evaluate {botType==='human-like' ? 'her' : 'its'} recommendation</span></p>
                <p className={'mt-40'}>We will now provide you with an introduction to noise-cancelling headphones.</p>
            </div>
            <div className={'d-flex justify-content-end w-100'}>
                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/headphones.png'} className={'headphones-image'} />
            </div>
        </div>
    );
};

export default StepFour;
