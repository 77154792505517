import React from 'react';
import { Rating } from 'react-simple-star-rating'
import {useSelector} from 'react-redux';

const StepSix = () => {
    return (
        <div className={'pt-20'}>
            <h3 className={'mb-5'}>What should you consider when purchasing noise-cancelling headphones?</h3>
            <div className={'d-flex flex-column w-100'}>
                    <div>
                        {/* <p><span className={'text-decoration-underline'}><b>3. Type of noise cancellation:</b></span></p>
                        <ul>
                            <li>
                                <span className={'text-decoration-underline'}><b>Passive:</b></span> relies on well-designed ear cups to block out noise.
                            </li>
                            <li>
                                <span className={'text-decoration-underline'}><b>Active:</b></span> uses a processor inside each cup that detects outside sound and produces a signal that cancels it out.
                            </li>
                            <li>
                                <span className={'text-decoration-underline'}><b>Adaptive:</b></span> similar to active noise cancellation with one difference: it changes the level of noise cancelling to adapt to the surroundings in terms of loudness.
                            </li>
                        </ul> */}
                        <p><span className={'text-decoration-underline'}><b>3. Noise cancellation quality (based on experts' tests): </b></span></p>
                        <p>The extent that a headphone can block out noises of different frequencies.</p>
                        <div className='d-flex align-items-center flex-wrap'>
                        <div className='d-flex align-items-center flex-shrink-0'>Noise cancellation quality is based on experts' rigorous tests and ranges between </div>
                        <div className='d-flex align-items-center flex-shrink-0'>
                        <div style={{margin: '0 3px 3px 5px'}}><Rating initialValue={0} readonly={true} showTooltip={false} size={18} /></div>
                        <div>0 (lowest quality) and</div>
                        </div>
                        <div className='d-flex align-items-center flex-shrink-0'>
                        <div style={{margin: '0 3px 3px 5px'}}><Rating initialValue={5} readonly={true} showTooltip={false} size={18} /></div>
                        <div>5 (highest quality).</div>
                        </div>
                        </div>
                        <p>Better noise cancellation quality helps users by reducing distractions and increasing focus. It also improves sound quality providing a better listening experience.</p>
                    </div>
                <div className={'d-flex w-100 justify-content-end'}>
                    <img loading={'eager'}  alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/noise-cancelling.jpeg'} className={'noise-image'} />
                </div>
            </div>
        </div>
    );
};

export default StepSix;
