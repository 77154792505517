import groupedProducts from './products.json';

const groups = ['b0', 'b1', 'b2', 'b3']
const products = groupedProducts.reduce((acc, currentProduct, currentIndex)=>{
    groups.forEach((group, groupIndex)=>{
        acc.push({...currentProduct, id: `${currentIndex}-${groupIndex}`, model: currentProduct.model[group], batteryLife:currentProduct.batteryLife[group], noiseCancellationQuality:currentProduct.noiseCancellationQuality[group]  })
    })

    return acc
}, [])

export default products
