import React, {useEffect, useState} from 'react';
import MichaelTalkingAvatar from './MichaelTalkingAvatar';
import {TypeAnimation} from 'react-type-animation';
import getVideoDuration from '../../../helpers/getVideoDuration';

const MessageWidget = (props) => {
    const {payload, messages, setState, state, setOptionsShown} = props
    const [showMessage, setShowMessage] = useState(true)
    const [videoLoaded, setVideoLoaded] = useState(false)
    const {message} = payload


    return showMessage ? (
        <div className={'d-flex w-100'}>


            <MichaelTalkingAvatar video={payload.video}
                                  setVideoLoaded={setVideoLoaded}
                                  isFirstVideo={messages?.length === 1}
                                  setDisableInput={(value)=>setState({...state, disableInput: value})}
                                  setOptionsShown={setOptionsShown}
            />
            <div className="react-chatbot-kit-chat-bot-message ">
                {videoLoaded ? <TypeAnimation
                        sequence={
                           [message]
                        }
                        wrapper="span"
                        speed={40}
                        style={{display: 'inline-block'}}
                        cursor={false}
                        omitDeletionAnimation={true}
                    /> :
                    <span className={'chatbot-loader-container'}/>
                }
                <div className="react-chatbot-kit-chat-bot-message-arrow"></div>
            </div>


        </div>
    ) : null
}


export default MessageWidget;
