export const getSurveyLink = (botType) => {

    switch (botType) {
        case 'human-like':
            return 'https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_bxx9gvRXwRz46qi';
            break;
        case 'talking-head-f':
            return 'https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_6hZuhAOPvwM3yzY';
            break;
        case 'talking-head-m':
            return 'https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_6ujJf1WgyeI15bM';
            break;
        default:
            return 'https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_3wvxxB55Jcuf5f8';
            break
    }
}
