import React, {Fragment} from 'react';
import SEO from '../../components/seo';
import LayoutOne from '../../layouts/LayoutOne';
import LayoutThree from '../../layouts/LayoutThree';
import ResearchGuideWrapper from '../../wrappers/research-guide/ResearchGuideWrapper';

const ResearchGuide = () => {
    return (
        <Fragment>
            <SEO
                titleTemplate="Electronics Home"
                description="Electronics home of flone react minimalist eCommerce template."
            />
            <LayoutThree headerTop="visible">
              <ResearchGuideWrapper/>

            </LayoutThree>
        </Fragment>
    );
};

export default ResearchGuide;
