import React, {useState} from 'react';
import Buttons from '../../wrappers/research-guide/Buttons';
import {setUser} from '../../store/slices/user-slice';
import {useDispatch} from 'react-redux';

const StepOne = ({setCurrentStep, agree, setAgree}) => {

    const [otherCountry, setOtherCountry] = useState()
    const [frequency, setFrequency] = useState()
    const [country, setCountry] = useState()
    const [profilicId, setProfilicId] = useState()
    const [showError, setShowError] = useState(false)
    const dispatch = useDispatch();

    const onProfilicIdChange = (event) => {
        setProfilicId(event.target.value)
    }
    const onChangeFrequency = (event) => {

        setFrequency(event.target.value);
    }
    const onChangeCountry = (event) => {

        if (event.target.value === 'Other') {
            document.getElementById("otherCountry").focus();
        }
        setCountry(event.target.value);

    }

    const onOtherCountryChange = (event) => {
        setOtherCountry(event.target.value)
    }


    const onNextClick = async () => {
        if(profilicId && (country==='UK' || country === 'Other' || (!!country  && country!=='USA') ||
        frequency==='Never' || frequency==='Once in a few month or longer')) {
            const jsonDataUser = JSON.stringify({
                profilicId: profilicId,
                frequencyOfShopping: frequency,
                agreeToParticipate: agree,
                name: '',
                country: country === 'Other' ? otherCountry : country,
                botType: '',
                version: '',//L, P
                group: '', //b0, b1, b2, b3,
                filter: null,
                userAnswers: {
                    name:null,
                    batteryLife: null,
                    chargeTime: null,
                    weight: null,
                    noiseCancellingType: null,
                    noiseCancellationQuality: null,
                    foldable: null,
                    bluetoothRange: null,
                    frequencyResponse: null,
                    androidControl: null,
                    googleAssistant: null,
                    iosControl: null,
                    amazonAlexa: null,
                    warranty: null,
                    price: null,
                    feature1: null,
                    feature2: null,
                    wantToSeeOtherHeadphones: null
                },
                product: null,
                chosenProduct: null,
                messages: []
            });
            const blobUserData = new Blob([jsonDataUser], {type: 'application/json'});
            navigator.sendBeacon(`${process.env.REACT_APP_BASE_URL || ''}/create-user-data-row`, blobUserData);

            window.location.replace('https://app.prolific.com/submissions/complete?cc=CQ360EST')
        }
        if (!agree || !country || !frequency || !profilicId) {
            setShowError(true)
        } else {
            if (country === 'Other' && (otherCountry === '' || !otherCountry)) {
                setShowError(true)
            } else {
                setShowError(false)
                dispatch(setUser({
                    profilicId: profilicId,
                    frequencyOfShopping: frequency,
                    agreeToParticipate: agree,
                    name: '',
                    country: country === 'Other' ? otherCountry : country
                }))


                setCurrentStep(2)
            }
        }
    }

    return (
        <div className={'flex-grow-1 d-flex flex-column align-items-center mt-40'}>
            <div className={'form'}>
                <h4>
                    What is your Profilic ID?
                </h4>
                <input id={'profilicId'} className={'input'} value={profilicId} onChange={onProfilicIdChange}/>
                <h4 className={'mt-3'}>
                    How often (on average) do you shop online?
                </h4>
                <div onChange={onChangeFrequency}>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="frequency" id="frequency1"
                               value={'More than once a week'}/>
                        <label className="form-check-label" htmlFor="frequency1">
                            More than once a week
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="frequency" id="frequency2"
                               value={'About once per week'}/>
                        <label className="form-check-label" htmlFor="frequency2">
                            About once per week
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="frequency" id="frequency3"
                               value={'Several times a month'}
                        />
                        <label className="form-check-label" htmlFor="frequency3">
                            Several times a month
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="frequency" id="frequency4"
                               value={'About once a month'}
                        />
                        <label className="form-check-label" htmlFor="frequency4">
                            About once a month
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="frequency" id="frequency5"
                               value={'Once in a few month or longer'}
                        />
                        <label className="form-check-label" htmlFor="frequency5">
                            Once in a few month or longer
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="frequency" id="frequency6"
                               value={'Never'}
                        />
                        <label className="form-check-label" htmlFor="frequency6">
                            Never
                        </label>
                    </div>
                </div>


                <h4 className={'mt-3'}>
                    In what country do you currently reside?
                </h4>
                <div onChange={onChangeCountry}>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="country" id="country1" value={'USA'}/>
                        <label className="form-check-label" htmlFor="country1">
                            USA
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="country" id="country2"
                               value={'UK'}/>
                        <label className="form-check-label" htmlFor="country2">
                            UK
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="country" id="country3" value={'Other'}
                        />
                        <label className="form-check-label" htmlFor="country3">
                            Other
                        </label>
                        <input type={'text'} name={'otherCountry'} id={'otherCountry'} className={'input'}
                               onChange={onOtherCountryChange} value={otherCountry}/>
                    </div>
                </div>
                {showError && <div className={'error mt-3 text-danger'}>Please fill all fields</div>}
            </div>
            <Buttons currentStep={1} setStep={setCurrentStep} onNextClick={onNextClick}/>
        </div>
    );
};

export default StepOne;
