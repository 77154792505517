export class MichaelTalking {
    constructor() {
    }

    batteryLifeQuestionClear() {
        return ({message: `Batteries often last between 20 and 60 hours`, video:'batteryLifeQuestionClear' })
    }

    weightQuestionClear() {
        return ({message: `Our headphones are between 99 and 440 grams`, video: 'weightQuestionClear'})
    }

    bluetoothQuestionClear() {
        return ({message: `This range for different headphones is usually between 9 and 30 meters.`, video:'bluetoothQuestionClear'})
    }

    frequencyQuestionClear() {
        return ({message: `We have two types of frequency response: 20 Hz - 20,000 Hz and 4 Hz - 40,000 Hz`, video: 'frequencyQuestionClear'})
    }

    chargingTimeQuestionClear() {
        return ({message: `This time for different headphones is usually between 1.5 and 7 hours.`, video: 'chargingTimeQuestionClear'})
    }

    priceQuestionClear() {
        return ({
            message: `Please note that our noise-cancelling headphones  cost between 119 and 375 USD.`, video: 'priceQuestionClear'
        })
    }

    priceQuestion2Clear() {
        return ({
            message: `I will do my best to find you a headphone that meets most of your preferences and costs close to your preferred price.`,
            video: 'priceQuestion2Clear'
        })
    }


    sorry() {
        return ({
            message: `I'm sorry but I'm not sure I understood what you meant. Can you please rephrase your response?`,
            id: 'sorry',
            video: 'sorry'
        })

    }

    hello() {
        return ({
            message: 'Hello, this is Michael. I am your personal virtual advisor at Headphone Tech. Can I ask your name?',
            id: 'hello',
            video: 'hello'
        })
    }


    niceToMeetYou(name) {
        return ({
            message: `Nice to meet you.`, id: 'niceToMeetYou', video: 'niceToMeetYou'
        })
    }

    helpToFind() {
        return ({
            message: 'To help you find the best headphone for your needs, I am going to ask you some questions.',
            id: 'helpToFind',
            video: 'helpToFind'
        })
    }

    batteryLifeQuestion() {
        return ({
            message: `Can you please let me know how many hours you need your headphone's battery to last after every charge?`,
            id: 'batteryLifeQuestion',
            video: 'batteryLifeQuestion'
        })
    }

    batteryLifeResponseAnswer1() {
        return ({
            message: `I see. So, a short battery life is sufficient for you. `,
            id: 'batteryLifeResponseAnswer1',
            video: 'batteryLifeResponseAnswer1'
        })
    }

    batteryLifeResponseAnswer2() {
        return ({
            message: `I see. So, you need a headphone with a very long battery life. I should however, let you know that our longest lasting battery is 60 hours.`,
            id: 'batteryLifeResponseAnswer2',
            video: 'batteryLifeResponseAnswer2'
        })
    }

    batteryLifeResponseAnswer3(batteryLife) {
        return ({
            message: `I agree, that is a good choice 😊`,
            id: 'batteryLifeResponseAnswer3',
            video: 'batteryLifeResponseAnswer3'
        })
    }

    weightQuestion() {
        return ({
            message: `How much (in grams) do you prefer your headphone to weigh?`,
            id: 'weightQuestion',
            video: 'weightQuestion'
        })
    }

    weightResponseAnswer1(weight) {
        return ({
            message: `Sounds good 😊; I’ll keep that in mind. `,
            id: 'weightResponseAnswer1',
            video: 'weightResponseAnswer1'
        })
    }

    weightResponseAnswer2(weight) {
        return ({
            message: `Sounds good 😊; you need a very light headphone. Please note that our lightest headphone weighs 99 grams.`,
            id: 'weightResponseAnswer2',
            video: 'weightResponseAnswer2'
        })
    }

    weightResponseAnswer3(weight) {
        return ({
            message: `Sounds good 😊; you need a pretty light headphone. I’ll look for a headphone that matches your preference.`,
            id: 'weightResponseAnswer3',
            video: 'weightResponseAnswer3'
        })
    }

    weightResponseAnswer4(weight) {
        return ({
            message: `Sounds good 😊; you need a headphone that is quite light but not necessarily too light. I’ll look for a headphone that matches your preference.`,
            id: 'weightResponseAnswer4',
            video: 'weightResponseAnswer4'
        })
    }

    weightResponseAnswer5(weight) {
        return ({
            message: `Sounds good 😊; you do not need a light headphone. I’ll look for a headphone that matches your preference`,
            id: 'weightResponseAnswer5',
            video: 'weightResponseAnswer5'
        })
    }

    noiseCancellationQualityQuestion() {
        return ({
            message: `What level of noise cancellation quality do you prefer your headphone to have? This quality for our headphones ranges between 3.5 and 5 in increments of 0.5.`,
            id: 'noiseCancellationQualityQuestion',
            video: 'noiseCancellationQualityQuestion'
        })
    }

    noiseCancellationQualityResponseAnswer1() {
        return ({
            message: `I  see. So, a headphone with low to medium noise cancellation quality is fine by you.`,
            id: 'noiseCancellationQualityResponseAnswer1',
            video: 'noiseCancellationQualityResponseAnswer1'
        })
    }

    noiseCancellationQualityResponseAnswer2() {
        return ({
            message: `I see. So, you need a headphone with great noise cancellation quality. I should however, let you know that 5 indicates the highest quality for noise-cancellation.`,
            id: 'noiseCancellationQualityResponseAnswer2',
            video: 'noiseCancellationQualityResponseAnswer2'
        })
    }

    noiseCancellationQualityResponseAnswer3(noiseCancellationQuality) {
        return ({
            message: `Sure, that sounds like a reasonable choice`,
            id: 'noiseCancellationQualityResponseAnswer3',
            video: 'noiseCancellationQualityResponseAnswer3'
        })
    }

    noiseCancellationQualityResponseAnswer4() {
        return ({
            message: `Sure, I will try to find you a headphone with great noise cancellation quality.`,
            id: 'noiseCancellationQualityResponseAnswer4',
            video: 'noiseCancellationQualityResponseAnswer4'
        })
    }

//     noiseCancellingQuestion() {
//         return ({
//             message: `I would like to know the type of noise-
// cancelling you prefer your headphone
// to use: Passive, active, or adaptive?`,
//             id: 'noiseCancellingQuestion'
//         })
//     }


//     noiseCancellingResponseNotValid() {
//         return ({
//             message: `I think, I might not have asked
// my question clearly. Noise-
// cancelling headphones can be
// passive, active, or adaptive.
// Which of the three types do you
// prefer?`,
//             id: 'noiseCancellingResponseNotValid'
//         })
//     }

//     noiseCancellingResponseValid(type) {
//         if (type === 'passive') {
//             return ({
//                 message: `Sure, got it. Yeah, passive
// headphones are usually more budget-
// friendly too 😊`,
//                 id: 'noiseCancellingResponseValid'
//             })
//         } else if (type === 'active') {
//             return ({
//                 message: `Good choice 😊 Most high quality
// noise-cancelling headphones use
// active noise-cancelling.`,
//                 id: 'noiseCancellingResponseValid'
//             })
//         } else if (type === 'adaptive') {
//             return ({
//                 message: `Good choice 😊 Most high quality
// noise-cancelling headphones use
// adaptive noise-cancelling. `,
//                 id: 'noiseCancellingResponseValid'
//             })
//         } else {
//             return ({
//                 message: `Great; thank you😊`,
//                 id: 'noiseCancellingResponseValid'
//             })
//         }

//     }

    bluetoothQuestion() {
        return ({
            message: `How many meters do you need your headphone Bluetooth range to be?`,
            id: 'bluetoothQuestion',
            video: 'bluetoothQuestion'
        })
    }

    bluetoothResponseAnswer1(bluetoothMeters) {
        return ({
            message: `OK; thanks! I’ll look for a headphone with your preferred Bluetooth range.`,
            id: 'bluetoothResponseAnswer1', video: 'bluetoothResponseAnswer1'
        })
    }

    bluetoothResponseAnswer2(bluetoothMeters) {
        return ({
            message: `OK: thanks! I understand that you need a headphone with a large Bluetooth range. However, please note that the longest Bluetooth range our headphones have, is 30 meters.`,
            id: 'bluetoothResponseAnswer2',
            video: 'bluetoothResponseAnswer2'
        })
    }

    frequencyQuestion() {
        return ({
            message: `What frequency response do you prefer your headphone to have?`,
            id: 'frequencyQuestion',
            video: 'frequencyQuestion'
        })
    }

    frequencyResponseNotValidAnswer() {
        return ({
            message: `I think, there might be a misunderstanding. Our headphones have one of the two following frequency responses: 20 Hz - 20,000 Hz and 4 Hz - 40,000 Hz. Which one do you prefer?`,
            id: 'frequencyResponseNotValidAnswer',
            video: 'frequencyResponseNotValidAnswer'
        })
    }

    frequencyResponseValidAnswer(frequency) {
        if (frequency === '20Hz-20,000Hz') {
            return ({
                message: `Great choice! 20 - 20,000Hz is in fact the standard frequency range for most headphones.`,
                id: 'frequencyResponseValidAnswer',
                video: 'frequencyResponseValidAnswer1'
            })
        } else {
            return ({
                message: `Great choice! 4 - 40,000 Hz covers the entire frequency range that humans could hear.`,
                id: 'frequencyResponseValidAnswer',
                video: 'frequencyResponseValidAnswer2'

            })
        }

    }

    chargingTimeQuestion() {
        return ({
            message: `Can you please let me the number of hours you are willing to wait for a full recharge of your headphone?`,
            id: 'chargingTimeQuestion',
            video: 'chargingTimeQuestion'
        })
    }

    chargingTimeResponseNotValidAnswer() {
        return ({
            message: `Mm, I'm sorry but our headphones take 1.5 to 7 hours to recharge fully. What is your preference in terms of waiting for a full recharge of your headphone?`,
            id: 'chargingTimeResponseNotValidAnswer',
            video: 'chargingTimeResponseNotValidAnswer'
        })
    }

    chargingTimeResponseValidAnswer(chargingTime) {
        return ({
            message: `Sounds good 😊`,
            id: 'chargingTimeResponseValidAnswer',
            video: 'chargingTimeResponseValidAnswer'
        })
    }

    foldableQuestion() {
        return ({
            message: `Do you need your headphone to be foldable?`,
            id: 'foldableQuestion',
            video: 'foldableQuestion'
        })
    }

    foldableResponseAnswer1() {
        return ({
            message: `Thank you. I will do my best to find you a headphone that is foldable.`,
            id: 'foldableResponseAnswer1',
            video: 'foldableResponseAnswer1'
        })
    }

    foldableResponseAnswer2() {
        return ({
            message: `Thank you. I understand; you do not need a foldable headphone.`,
            id: 'foldableResponseAnswer2',
            video: 'foldableResponseAnswer2'
        })
    }

    androidQuestion() {
        return ({
            message: `Do you need your headphone to have Android control?`,
            id: 'androidQuestion',
            video: 'androidQuestion'
        })
    }

    androidResponseAnswer1() {
        return ({
            message: `I agree, having a headphone with Android control can be really helpful.`,
            id: 'androidResponseAnswer1',
            video: 'androidResponseAnswer1'
        })
    }

    androidResponseAnswer2() {
        return ({
            message: `Understood; thank you`,
            id: 'androidResponseAnswer2',
            video: 'androidResponseAnswer2'
        })
    }

    iosQuestion() {
        return ({
            message: `Do you need your headphone to have iOS control?`,
            id: 'iosQuestion',
            video: 'iosQuestion'
        })
    }

    iosResponseAnswer1() {
        return ({
            message: `I agree, a headphone with iOS control can make Mac users’ life easier.`,
            id: 'iosResponseAnswer1',
            video: 'iosResponseAnswer1'
        })
    }

    iosResponseAnswer2() {
        return ({
            message: `Got it. You do not need iOS control for your headphone.`,
            id: 'iosResponseAnswer2',
            video: 'iosResponseAnswer2'
        })
    }

//     googleQuestion() {
//         return ({
//             message: `Do you need your headphone to work
// with Google Assistant?`,
//             id: 'googleQuestion'
//         })
//     }
//
//     googleResponseAnswer1() {
//         return ({
//             message: `Of course. I will look for a
// headphone that works with
// Google Assistant.`,
//             id: 'googleResponseAnswer1'
//         })
//     }

//     googleResponseAnswer2() {
//         return ({
//             message: `I see. You do not need a
// headphone that works with
// Google Assistant.`,
//             id: 'googleResponseAnswer2'
//         })
//     }


//     alexaQuestion() {
//         return ({
//             message: `Do you need your headphone to work
// with Amazon Alexa?`,
//             id: 'alexaQuestion'
//         })
//     }
//
//     alexaResponseAnswer1() {
//         return ({
//             message: `Sounds good; thank you.`,
//             id: 'alexaResponseAnswer1'
//         })
//     }
//
//     alexaResponseAnswer2() {
//         return ({
//             message: `Sounds good; thank you.`,
//             id: 'alexaResponseAnswer2'
//         })
//     }
//
//     warrantyQuestion() {
//         return ({
//             message: `How long do you need the warranty to
// be? 12 months or 18 months`,
//             id: 'warrantyQuestion'
//         })
//     }
//
//     warrantyResponseValidAnswer() {
//         return ({
//             message: `Fair enough; thank you 😊`,
//             id: 'warrantyResponseValidAnswer'
//         })
//     }

//     warrantyResponseNotValidAnswer() {
//         return ({
//             message: `Thank you for letting me know
// your preference. However, I
// should bring it to your attention
// that our headphones have either
// 12 or 18 months of warranty. Can
// you please let me know whether
// you prefer a headphone with 12-
// or 18-months warranty?`,
//             id: 'warrantyResponseNotValidAnswer'
//         })
//     }

    priceQuestion() {
        return ({
            message: `I also need to know the price (in USD) you are comfortable paying for the headphone.`,
            id: 'priceQuestion',
            video: 'priceQuestion'
        })
    }

    priceResponseValidAnswer(price) {

        return ({
            message: `I'll keep that in mind. We're almost there. Two more questions and I will be able to provide you with a recommendation.`,
            id: 'priceResponseValidAnswer',
            video: 'priceResponseValidAnswer'
        })

    }

    priceResponseNotValidAnswer(price) {
        return ({
            message: `I understand. Thank you for letting me know your budget. However, I would like to bring to your attention that our headphones are between 119 and 375 USD.`,
            id: 'priceResponseNotValidAnswer',
            video: 'priceResponseNotValidAnswer'
        })
    }

    mostImportantFeature1Question() {
        return ({
            type: 'radio',
            message: `What is the most important feature of noise-cancelling headphones for you?`,
            name: 'features1',
            id: 'mostImportantFeature1Question',
            video: 'mostImportantFeature1Question'
        })
    }

    mostImportantFeature1ResponseAnswer() {
        return ({
            message: `Sounds good`,
            id: 'mostImportantFeature1ResponseAnswer',
            video: 'mostImportantFeature1ResponseAnswer'
        })
    }


    mostImportantFeature2Question() {
        return ({
            type: 'radio',
            message: `What is the second most important feature of noise-cancelling headphones for you?`,
            name: 'features2',
            id: 'mostImportantFeature2Question',
            video: 'mostImportantFeature2Question'
        })
    }

    mostImportantFeature2ResponseAnswer() {
        return ({
            message: `Got it; thanks!`,
            id: 'mostImportantFeature2ResponseAnswer',
            video: 'mostImportantFeature2ResponseAnswer'
        })
    }

    thanks() {
        return ({
            message: `Awesome; thank you! Your answers were very helpful.`,
            id: 'thanks',
            video: 'thanks'
        })
    }

    joke() {
        return (
            {
                message: `Now, I am going to look for a headphone that best matches your preferences. While you wait for the headphone recommendation, would you like to hear a joke?`,
                id: 'jokeQuestion',
                video: 'jokeQuestion'
            }
        )
    }

    jokeResponseAnswerPostive() {
        return (
            {
                message: `- My dad always says, “Don’t spend too much money on expensive 
headphones.”
- That’s .... sound advice`,
                id: 'jokePositive',
                video: 'jokePositive'
            }
        )
    }

    jokeResponseAnswerNegative() {
        return (
            {
                message: `Ok, no problem.`,
                id: 'jokeNegative',
                video: 'jokeNegative'
            }
        )
    }


    foundRecommended() {
        return {
            message: `Based on your answers, I think, this headphone best suits your requirements.`,
            type: 'headphones',
            name: 'product',
            id: 'foundRecommended',
            video: 'foundRecommended'
        }
    }


//     jobComplete() {
//         return ({
//             message: `My job is complete here.
// It would be great if you can provide us
// with some feedback about your
// experience of interacting with me to
// find the best headphone that match
// your preferences.`,
//             id: 'jobComplete'
//         })
//     }

    lastMessage() {
        return ({
            type: 'radio links',
            message: `What do you think about this recommendation?`,
            name: 'lastMessage',
            id: 'lastMessage',
            video: 'lastMessage'
        })
    }

    finishFirst() {
        return ({
            type: 'button ok',
            message: `Thank you. You will now be forwarded to a survey to provide some feedback about your experience of interacting with me to find the best headphone that match your preferences.`,
            name: 'finishFirst',
            id: 'finishFirst',
            video: 'finishFirst'
        })
    }

    finishSecondOne() {
        return ({
            type: 'button ok',
            message: `Thank you. You will now be forwarded to a page where you can check out our other headphone models.`,
            name: 'finishSecondOne',
            id: 'finishSecondOne',
            video: 'finishSecondOne'
        })
    }

    finishSecondTwo() {
        return ({
            type: 'button ok',
            message: `Afterwards, you will be forwarded to a survey to provide some feedback about your experience of interacting with me to find the best headphone that match your preferences.`,
            name: 'finishSecondTwo',
            id: 'finishSecondTwo',
            video: 'finishSecondTwo'
        })
    }
}


