import React from "react";
import Logo from "../../components/header/Logo";
import IconGroup from "../../components/header/IconGroup";
import NavMenu from "../../components/header/NavMenu";
import MobileMenu from "../../components/header/MobileMenu";

const HeaderTwo = () => {

  return (
    <header className="header-area clearfix header-hm9 transparent-bar">
      <div className="container">
        <div className="header-top-area d-none d-lg-block">
          <div className="row" >
            <div className="col-lg-4 d-none d-lg-block">
              {/* header logo */}
              <Logo
                imageUrl="/assets/img/logo/logo-blue.png"
                logoClass="logo-hm-10"
              />
            </div>
            <div className="col-lg-4">
              <NavMenu />
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              {/* Icon group */}
              <IconGroup />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`header-bottom sticky-bar header-res-padding header-padding-2`}
      >
        <div className="container">
          <div className="row">
            <div className="col-6 d-block d-lg-none">
              {/* header logo */}
              <Logo imageUrl="/assets/img/logo/logo-white.png" logoClass="logo-hm-10 mt-1" />
            </div>
            <div className="col-6 d-block d-lg-none">
              {/* Icon group */}
              <IconGroup />
            </div>
          </div>
          {/* mobile menu */}
          <MobileMenu />
        </div>
      </div>
    </header>
  );
};

export default HeaderTwo;
