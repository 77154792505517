import React from 'react';
import {Anna} from './Anna';
import products from '../../../data/allProducts';
import separateNumbersAndWords from '../../../helpers/separateNumbersAndWords';
import recommendHeadphone from '../../../helpers/recommendHeadphone';
import {useDispatch, useSelector} from 'react-redux';
import {setRecommendedProduct, setUserAnswers} from '../../../store/slices/user-slice';
import {answerTheQuestion, fixTypo, getStringIncludesWord} from '../../../helpers/userQuestions';
import levenstein from 'fast-levenshtein';
import {
    getFrequencyToShowInAnswer, handleBetweenWarranty,
    handleNo,
    handleWarrantyEighteen, handleWarrantyHigh, handleWarrantyLow,
    handleWarrantyTwelve,
    handleYes
} from '../../../helpers/parseSynonyms';
import getDelay from '../../../helpers/getDelay';
import extractName from '../../../helpers/extractName';
import { detectBestUserAnswer } from '../../../helpers/userAnswers';

const ActionProvider = ({createChatBotMessage, setState, children, state}) => {
    const AnnaBot = new Anna()
    const {version} = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const botLastMessage = children.props.children.props.state.botLastMessage;

    const handleSorry = (questionToRepeat) => {
        const botMessage = createChatBotMessage(AnnaBot.sorry().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage}],
            })
        })
        if (questionToRepeat) {
            setTimeout(() => questionToRepeat(), getDelay(AnnaBot.sorry().message))
        }

    }

    const handleNiceToMeetYou = (response) => {
        const name = extractName(response)
        const botMessage = createChatBotMessage(AnnaBot.niceToMeetYou(name).message)

        setState((prev) => {

            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.niceToMeetYou(name).id}],
                userAnswers: {...prev.userAnswers, name}
            })
        })
    }
    const handleBatteryLifeQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.batteryLifeQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.batteryLifeQuestion().id}],
            })
        })
        setTimeout(() => handleClarifyBotQuestion('batteryLifeQuestion'), getDelay(AnnaBot.batteryLifeQuestion().message))

    }
    const handleNumberOfQuestions = () => {
        const botMessage = createChatBotMessage(AnnaBot.helpToFind().message);
        setState((prev) => {


            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.helpToFind().id}],
            })
        })
    }

    const chooseBatteryLifeResponse = (response) => {
        let botMessage
        const batteryLife = separateNumbersAndWords(response)?.[0]
        if (Number(batteryLife) < 20) {
            botMessage = createChatBotMessage(AnnaBot.batteryLifeResponseAnswer1().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.batteryLifeResponseAnswer1(batteryLife).id
                    }],
                    userAnswers: {...prev.userAnswers, batteryLife}
                })
            })
        } else if (Number(batteryLife) > 60) {
            botMessage = createChatBotMessage(AnnaBot.batteryLifeResponseAnswer2().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.batteryLifeResponseAnswer2(batteryLife).id
                    }],
                    userAnswers: {...prev.userAnswers, batteryLife}
                })
            })
        } else if (Number(batteryLife) >= 20 && Number(batteryLife) <= 60) {
            botMessage = createChatBotMessage(AnnaBot.batteryLifeResponseAnswer3(batteryLife).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.batteryLifeResponseAnswer3(batteryLife).id
                    }],
                    userAnswers: {...prev.userAnswers, batteryLife}
                })
            })

        } else {
            handleSorry(handleBatteryLifeQuestion)
        }


    }


    const handleWeightQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.weightQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.weightQuestion().id}],
            })
        })
        setTimeout(() => handleClarifyBotQuestion('weightQuestion'), getDelay(AnnaBot.weightQuestion().message))

    }

    const chooseWeightResponse = (response) => {
        let botMessage
        const weight = separateNumbersAndWords(response)?.[0]
        if (Number(weight) >= 0.1 && Number(weight) < 0.44) {
            botMessage = createChatBotMessage(AnnaBot.weightResponseAnswer1(weight).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.weightResponseAnswer1(weight).id
                    }],
                    userAnswers: {...prev.userAnswers, weight}
                })
            })
        } else if (Number(weight) >= 0.44 && Number(weight) < 99) {
            botMessage = createChatBotMessage(AnnaBot.weightResponseAnswer2(weight).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.weightResponseAnswer2(weight).id
                    }],
                    userAnswers: {...prev.userAnswers, weight}
                })
            })
        } else if (Number(weight) >= 99 && Number(weight) <= 250) {
            botMessage = createChatBotMessage(AnnaBot.weightResponseAnswer3(weight).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.weightResponseAnswer3(weight).id
                    }],
                    userAnswers: {...prev.userAnswers, weight}
                })
            })
        } else if (Number(weight) >= 251 && Number(weight) <= 440) {
            botMessage = createChatBotMessage(AnnaBot.weightResponseAnswer4(weight).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.weightResponseAnswer4(weight).id
                    }],
                    userAnswers: {...prev.userAnswers, weight}
                })
            })
        } else if (Number(weight) > 440) {
            botMessage = createChatBotMessage(AnnaBot.weightResponseAnswer5(weight).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.weightResponseAnswer4(weight).id
                    }],
                    userAnswers: {...prev.userAnswers, weight}
                })
            })
        } else {
            handleSorry(handleWeightQuestion)
        }


    }

    const handleNoiseCancellingQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.noiseCancellingQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.noiseCancellingQuestion().id}],
            })
        })
    }

    const handleNoiseCancellationQualityQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.noiseCancellationQualityQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.noiseCancellationQualityQuestion().id}],
            })
        })
    }

    const chooseNoiseCancellationQualityResponse = (response) => {
        let botMessage
        const noiseCancellationQuality = separateNumbersAndWords(response)?.[0]
        if (Number(noiseCancellationQuality) < 3.5) {
            botMessage = createChatBotMessage(AnnaBot.noiseCancellationQualityResponseAnswer1().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.noiseCancellationQualityResponseAnswer1().id
                    }],
                    userAnswers: {...prev.userAnswers, noiseCancellationQuality}
                })
            })
        } else if (Number(noiseCancellationQuality) >= 3.5 && Number(noiseCancellationQuality) <= 5) {
            botMessage = createChatBotMessage(AnnaBot.noiseCancellationQualityResponseAnswer3(noiseCancellationQuality).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.noiseCancellationQualityResponseAnswer3(noiseCancellationQuality).id
                    }],
                    userAnswers: {...prev.userAnswers, noiseCancellationQuality}
                })
            })
        } else if (Number(noiseCancellationQuality) > 5) {
            botMessage = createChatBotMessage(AnnaBot.noiseCancellationQualityResponseAnswer2().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.noiseCancellationQualityResponseAnswer2().id
                    }],
                    userAnswers: {...prev.userAnswers, noiseCancellationQuality}
                })
            })
        } else if (!Number(response) && detectBestUserAnswer(response)) {
            botMessage = createChatBotMessage(AnnaBot.noiseCancellationQualityResponseAnswer4().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.noiseCancellationQualityResponseAnswer4().id
                    }],
                    userAnswers: {...prev.userAnswers, noiseCancellationQuality: 5}
                })
            })
        } else {
            handleSorry(handleNoiseCancellationQualityQuestion)
        }


    }

    const chooseNoiseCancellingResponse = (response) => {
        let botMessage

        const validResponses = ['active', 'passive', 'adaptive']
        const validResponses2 = ['first', 'second', 'third']

        const allValidResponses = [...validResponses, ...validResponses2]

        // Join the valid responses into a regular expression pattern
        const validResponsesPattern = allValidResponses.map(response => `\\b${response}\\b`).join('|');
        const regex = new RegExp(validResponsesPattern, 'gi'); // Use 'gi' for case-insensitive matching
        let fixedResponse = []
        response.split(' ').forEach(el => {

            for (let i = 0; i < allValidResponses.length; i++) {

                if (levenstein.get(allValidResponses[i], el) <= 1) {
                    fixedResponse.push(allValidResponses[i])
                }
            }
        })
// Extract valid responses from the response string
        const extractedResponses = fixedResponse.join(' ').match(regex) || [];
        const noiseCancelling = extractedResponses?.[0] || ''
        if (validResponses.some(el => noiseCancelling.toLowerCase().includes(el))) {
            botMessage = createChatBotMessage(AnnaBot.noiseCancellingResponseValid().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.noiseCancellingResponseValid(noiseCancelling).id
                    }],
                    userAnswers: {...prev.userAnswers, noiseCancellingType: noiseCancelling}
                })
            })
        } else if (validResponses2.some(el => noiseCancelling.toLowerCase().includes(el))) {
            if (noiseCancelling.toLowerCase().includes('first')) {
                botMessage = createChatBotMessage(AnnaBot.noiseCancellingResponseValid('passive').message)
                setState((prev) => {
                    return ({
                        ...prev,
                        messages: [...prev.messages, {
                            ...botMessage,
                            botMessageId: AnnaBot.noiseCancellingResponseValid('passive').id
                        }],
                        userAnswers: {...prev.userAnswers, noiseCancellingType: 'passive'}
                    })
                })
            } else if (noiseCancelling.toLowerCase().includes('second')) {
                botMessage = createChatBotMessage(AnnaBot.noiseCancellingResponseValid('active').message)
                setState((prev) => {
                    return ({
                        ...prev,
                        messages: [...prev.messages, {
                            ...botMessage,
                            botMessageId: AnnaBot.noiseCancellingResponseValid('active').id
                        }],
                        userAnswers: {...prev.userAnswers, noiseCancellingType: 'active'}
                    })
                })
            } else if (noiseCancelling.toLowerCase().includes('third')) {
                botMessage = createChatBotMessage(AnnaBot.noiseCancellingResponseValid('adaptive').message)
                setState((prev) => {
                    return ({
                        ...prev,
                        messages: [...prev.messages, {
                            ...botMessage,
                            botMessageId: AnnaBot.noiseCancellingResponseValid('adaptive').id
                        }],
                        userAnswers: {...prev.userAnswers, noiseCancellingType: 'adaptive'}
                    })
                })
            }
        } else {
            botMessage = createChatBotMessage(AnnaBot.noiseCancellingResponseNotValid().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.noiseCancellingResponseNotValid().id
                    }],
                })
            })
        }


    }
    const handleBluetoothRangeQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.bluetoothQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.bluetoothQuestion().id}],
            })
        })
        setTimeout(() => handleClarifyBotQuestion('bluetoothQuestion'), getDelay(AnnaBot.bluetoothQuestion().message))

    }

    const chooseBluetoothRangeResponse = (response) => {
        let botMessage
        const bluetoothRange = separateNumbersAndWords(response)?.[0]

        if (Number(bluetoothRange) <= 30) {
            botMessage = createChatBotMessage(AnnaBot.bluetoothResponseAnswer1(bluetoothRange).message)

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.bluetoothResponseAnswer1(bluetoothRange).id
                    }],
                    userAnswers: {...prev.userAnswers, bluetoothRange}

                })
            })

        } else if (Number(bluetoothRange) > 30) {
            botMessage = createChatBotMessage(AnnaBot.bluetoothResponseAnswer2(bluetoothRange).message)

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.bluetoothResponseAnswer2(bluetoothRange).id
                    }],
                    userAnswers: {...prev.userAnswers, bluetoothRange}

                })
            })
        } else {
            handleSorry(handleBluetoothRangeQuestion)
        }


    }

    const handleFrequencyQuestion = () => {
        const botMessage = createChatBotMessage(
            AnnaBot.frequencyQuestion().message,
            {
                widget: 'frequencyResponseWidget',
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.frequencyQuestion().id}],
            })
        })

    }

    const chooseFrequencyResponse = (response) => {
        let botMessage
        const frequency = response
        botMessage = createChatBotMessage(AnnaBot.frequencyResponseValidAnswer(getFrequencyToShowInAnswer(frequency
        )).message)
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {
                    ...botMessage,
                    botMessageId: AnnaBot.frequencyResponseValidAnswer(getFrequencyToShowInAnswer(frequency)).id
                }],
                userAnswers: {
                    ...prev.userAnswers,
                    frequencyResponse: frequency
                }

            })
        })

    }

    const handleChargingTimeQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.chargingTimeQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.chargingTimeQuestion().id}],
            })
        })
        setTimeout(() => handleClarifyBotQuestion('chargingTimeQuestion'), getDelay(AnnaBot.chargingTimeQuestion().message))

    }

    const chooseChargingTimeResponse = (response) => {
        let botMessage
        const chargingTime = separateNumbersAndWords(response)?.[0]

        if (Number(chargingTime) >= 1.5 && Number(chargingTime) <= 7) {
            botMessage = createChatBotMessage(AnnaBot.chargingTimeResponseValidAnswer(chargingTime).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.chargingTimeResponseValidAnswer(chargingTime).id
                    }],
                    userAnswers: {...prev.userAnswers, chargeTime: chargingTime}

                })
            })
        } else {
            botMessage = createChatBotMessage(AnnaBot.chargingTimeResponseNotValidAnswer(chargingTime).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.chargingTimeResponseNotValidAnswer(chargingTime).id
                    }],

                })
            })
        }


    }

    const handleFoldableQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.foldableQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.foldableQuestion().id}],
            })
        })
    }

    const chooseFoldableResponse = (response) => {
        let botMessage

        if (handleYes(response)) {
            botMessage = createChatBotMessage(AnnaBot.foldableResponseAnswer1().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.foldableResponseAnswer1().id}],
                    userAnswers: {...prev.userAnswers, foldable: true}
                })
            })
        } else if (handleNo(response)) {
            botMessage = createChatBotMessage(AnnaBot.foldableResponseAnswer2().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.foldableResponseAnswer2().id}],
                    userAnswers: {...prev.userAnswers, foldable: false}
                })
            })
        } else {
            handleSorry(handleFoldableQuestion)
        }


    }

    const handleAndroidQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.androidQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.androidQuestion().id}],
            })
        })
    }

    const chooseAndroidResponse = (response) => {
        let botMessage

        if (handleYes(response)) {
            botMessage = createChatBotMessage(AnnaBot.androidResponseAnswer1().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.androidResponseAnswer1().id}],
                    userAnswers: {...prev.userAnswers, androidControl: true}
                })
            })
        } else if (handleNo(response)) {
            botMessage = createChatBotMessage(AnnaBot.androidResponseAnswer2().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.androidResponseAnswer2().id}],
                    userAnswers: {...prev.userAnswers, androidControl: false}
                })
            })
        } else {
            handleSorry(handleAndroidQuestion)
        }


    }

    const handleIosQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.iosQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.iosQuestion().id}],
            })
        })
    }

    const chooseIosResponse = (response) => {
        let botMessage

        if (handleYes(response)) {
            botMessage = createChatBotMessage(AnnaBot.iosResponseAnswer1().message)

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.iosResponseAnswer1().id}],
                    userAnswers: {...prev.userAnswers, iosControl: true}
                })
            })
        } else if (handleNo(response)) {
            botMessage = createChatBotMessage(AnnaBot.iosResponseAnswer2().message)

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.iosResponseAnswer2().id}],
                    userAnswers: {...prev.userAnswers, iosControl: false}
                })
            })
        } else {
            handleSorry(handleIosQuestion)
        }

    }

    const handleGoogleQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.googleQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.googleQuestion().id}],
            })
        })
    }

    const chooseGoogleResponse = (response) => {
        let botMessage

        if (handleYes(response)) {
            botMessage = createChatBotMessage(AnnaBot.googleResponseAnswer1().message)

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.googleResponseAnswer1().id}],
                    userAnswers: {...prev.userAnswers, googleAssistant: true}

                })
            })
        } else if (handleNo(response)) {
            botMessage = createChatBotMessage(AnnaBot.googleResponseAnswer2().message)

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.googleResponseAnswer2().id}],
                    userAnswers: {...prev.userAnswers, googleAssistant: false}

                })
            })
        } else {
            handleSorry(handleGoogleQuestion)
        }

    }

    const handleAlexaQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.alexaQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.alexaQuestion().id}],
            })
        })
    }

    const chooseAlexaResponse = (response) => {
        let botMessage

        if (handleYes(response)) {
            botMessage = createChatBotMessage(AnnaBot.alexaResponseAnswer1().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.alexaResponseAnswer1().id}],
                    userAnswers: {...prev.userAnswers, amazonAlexa: true}

                })
            })
        } else if (handleNo(response)) {
            botMessage = createChatBotMessage(AnnaBot.alexaResponseAnswer2().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.alexaResponseAnswer2().id}],
                    userAnswers: {...prev.userAnswers, amazonAlexa: false}

                })
            })
        } else {
            handleSorry(handleAlexaQuestion)
        }


    }

    const handleWarrantyQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.warrantyQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.warrantyQuestion().id}],
            })
        })
    }

    const chooseWarrantyResponse = (response) => {
        let botMessage
        if (handleWarrantyLow(response) || handleWarrantyHigh(response)) {
            const warranty = separateNumbersAndWords(response)?.[0]

            botMessage = createChatBotMessage(AnnaBot.warrantyLowerClear(warranty).message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                    }],
                })
            })
        } else if (handleBetweenWarranty(response)) {
            handleClarifyBotQuestion('warrantyBetween')
        } else if (handleWarrantyTwelve(response)) {
            botMessage = createChatBotMessage(AnnaBot.warrantyResponseValidAnswer().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.warrantyResponseValidAnswer(12).id
                    }],
                    userAnswers: {...prev.userAnswers, warranty: 12}
                })
            })
        } else if (handleWarrantyEighteen(response)) {
            botMessage = createChatBotMessage(AnnaBot.warrantyResponseValidAnswer().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.warrantyResponseValidAnswer(18).id
                    }],
                    userAnswers: {...prev.userAnswers, warranty: 18}
                })
            })
        } else {
            botMessage = createChatBotMessage(AnnaBot.warrantyResponseNotValidAnswer().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.warrantyResponseNotValidAnswer().id
                    }],
                })
            })
        }

    }

    const handlePriceQuestion = () => {
        const botMessage = createChatBotMessage(AnnaBot.priceQuestion().message);
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.priceQuestion().id}],
            })
        })
        setTimeout(() =>
                handleClarifyBotQuestion('priceQuestion2')
            , getDelay(AnnaBot.priceQuestion().message))
        setTimeout(() => handleClarifyBotQuestion('priceQuestion'), getDelay(AnnaBot.priceQuestion2Clear) + getDelay(AnnaBot.priceQuestion().message))

    }

    const choosePriceResponse = (response) => {
        let botMessage

        let price = separateNumbersAndWords(response)?.[0]
        if (getStringIncludesWord(response, 'minimum', 1) ||
            getStringIncludesWord(response, 'low', 1) ||
            getStringIncludesWord(response, 'lowest', 1) ||
            getStringIncludesWord(response, 'lower', 1) ||
            getStringIncludesWord(response, 'cheapest', 1) ||
            getStringIncludesWord(response, 'cheap', 1) ||
            getStringIncludesWord(response, 'cheaper', 1)
        ) {
            botMessage = createChatBotMessage(AnnaBot.priceResponseValidAnswer('the cheaper the better').message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.priceResponseValidAnswer('the cheaper the better').id
                    }],
                    userAnswers: {...prev.userAnswers, price: 119}
                })
            })
        } else if (Number(price) >= 119 && Number(price) <= 375) {
            botMessage = createChatBotMessage(AnnaBot.priceResponseValidAnswer().message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.priceResponseValidAnswer().id
                    }],
                    userAnswers: {...prev.userAnswers, price}
                })
            })
        } else if (Number(price) <= 119) {

            botMessage = createChatBotMessage(AnnaBot.priceResponseValidAnswer(price).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.priceResponseValidAnswer(price).id
                    }],
                    userAnswers: {...prev.userAnswers, price: 119}
                })
            })
        } else if (Number(price) >= 375) {

            botMessage = createChatBotMessage(AnnaBot.priceResponseValidAnswer(price).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.priceResponseValidAnswer(price).id
                    }],
                    userAnswers: {...prev.userAnswers, price: 375}
                })
            })
        } else {

            botMessage = createChatBotMessage(AnnaBot.priceResponseNotValidAnswer(price).message)
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: AnnaBot.priceResponseNotValidAnswer(price).id
                    }],
                })
            })
        }
    }


    const handleFeature1Question = () => {
        const botMessage = createChatBotMessage(
            AnnaBot.mostImportantFeature1Question().message,
            {
                widget: 'feature1',
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                type: AnnaBot.mostImportantFeature1Question().type,
                name: AnnaBot.mostImportantFeature1Question().name,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.mostImportantFeature1Question().id}],
            })
        })
    }
    const chooseFeature1Response = () => {
        const
            botMessage = createChatBotMessage(AnnaBot.mostImportantFeature1ResponseAnswer().message)


        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {
                    ...botMessage,
                    botMessageId: AnnaBot.mostImportantFeature1ResponseAnswer().id
                }],
            })
        })
    }

    const handleFeature2Question = () => {
        const botMessage = createChatBotMessage(
            AnnaBot.mostImportantFeature2Question().message,
            {
                widget: 'feature2',
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                type: AnnaBot.mostImportantFeature2Question().type,
                name: AnnaBot.mostImportantFeature2Question().name,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.mostImportantFeature2Question().id}],
            })
        })
    }
    const chooseFeature2Response = () => {
        const
            botMessage = createChatBotMessage(AnnaBot.mostImportantFeature2ResponseAnswer().message)


        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {
                    ...botMessage,
                    botMessageId: AnnaBot.mostImportantFeature2ResponseAnswer().id
                }],
            })
        })
    }

    const handleThanks = () => {
        const
            botMessage = createChatBotMessage(AnnaBot.thanks().message)


        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.thanks().id}],
            })
        })
    }

    const handleRecommend = () => {

        const product = recommendHeadphone(children.props.children.props.state.userAnswers, version)
        dispatch(setRecommendedProduct(product))
        dispatch(setUserAnswers(children.props.children.props.state.userAnswers))
        const botMessage = createChatBotMessage(
            AnnaBot.foundRecommended(product).message,
            {
                widget: 'headphones',
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                type: AnnaBot.foundRecommended().type,
                name: AnnaBot.foundRecommended().name,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.foundRecommended().id}],
                productChoosedByBot: product
            })
        })

    }

    const handleJobComplete = () => {
        const
            botMessage = createChatBotMessage(AnnaBot.jobComplete().message)


        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.jobComplete().id}],
            })
        })
    }
    const handleLastMessage = () => {
        const product = products[0]
        const botMessage = createChatBotMessage(
            AnnaBot.lastMessage().message,
            {
                widget: 'lastMessage',
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                type: AnnaBot.lastMessage().type,
                name: AnnaBot.lastMessage().name,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.lastMessage().id}],
            })
        })

    }
    const handleClarifyBotQuestion = (questionIdToClarify) => {
        const botMessage = createChatBotMessage(AnnaBot[`${questionIdToClarify}Clear`])

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: `${questionIdToClarify}Clear`}],
                })

            })
        }

    }
    const handleAnswerTheQuestion = (message, lastQuestion, name = '') => {
        const answer = answerTheQuestion(message, 'human-like', lastQuestion?.botMessageId)
        if (answer) {

            if (typeof answer === 'string') {
                const botMessage = createChatBotMessage(answer)
                setState((prev) => {
                    return ({
                        ...prev,
                        messages: [...prev.messages, {...botMessage}],
                    })
                })
            } else {
                const botMessage = createChatBotMessage(answer.message)
                setState((prev) => {
                    return ({
                        ...prev,
                        messages: [...prev.messages, {...botMessage, botMessageId: answer.botMessageId}],
                    })
                })
            }
            if (answer.includes('Anna') && (!name || name === '' || name === '-')) {
                setTimeout(() => {
                    const botLastMessage = createChatBotMessage(`And yours?`)
                    setState((prev) => {
                        return ({
                            ...prev,
                            messages: [...prev.messages, {...botLastMessage, botMessageId: 'repeatNameQuestion'}],
                        })
                    })
                }, 1500)
            } else if (lastQuestion) {
                setTimeout(() => {
                    if (lastQuestion?.botMessageId.includes('Clear')) {
                        switch (lastQuestion?.botMessageId) {
                            case 'batteryLifeQuestionClear':
                                setTimeout(() => handleBatteryLifeQuestion(), getDelay(AnnaBot.batteryLifeQuestionClear));
                                break;
                            case 'weightQuestionClear' :
                                setTimeout(() => handleWeightQuestion(), getDelay(AnnaBot.weightQuestionClear));
                                break;
                            case 'bluetoothQuestionClear' :
                                setTimeout(() => handleBluetoothRangeQuestion(), getDelay(AnnaBot.bluetoothQuestionClear));
                                break;
                            case 'frequencyQuestionClear' :
                                setTimeout(() => handleFrequencyQuestion(), getDelay(AnnaBot.frequencyQuestionClear));
                                break;
                            case 'chargingTimeQuestionClear' :
                                setTimeout(() => handleChargingTimeQuestion(), getDelay(AnnaBot.chargingTimeQuestionClear));
                                break;
                            case 'priceQuestionClear' :
                                setTimeout(() => handlePriceQuestion(), getDelay(AnnaBot.priceQuestionClear));
                                break;
                        }
                    } else {
                            const botLastMessage = createChatBotMessage(lastQuestion.message)
                            setState((prev) => {
                                return ({
                                    ...prev,
                                    messages: [...prev.messages, {
                                        ...botLastMessage,
                                        botMessageId: lastQuestion.botMessageId
                                    }],
                                })
                            })

                    }

                }, getDelay(answer.message || answer))

            }


            // else if (answer.includes('fine')) {
            //     const botLastMessage = createChatBotMessage(`Let's continue choosing your best headphone`)
            //     setState((prev) => {
            //         return ({
            //             ...prev,
            //             messages: [...prev.messages, {...botLastMessage, botMessageId: 'returnToChoose'}],
            //         })
            //     })
            // }

        } else {
            handleSorry()
        }
    }

    const setUserLastMessage = (userLastMessage) => {
        setState((prev) => {
            return ({
                ...prev,
                userLastMessage,
            })
        })
    }
    const setBotLastMessage = (botLastMessage) => {
        setState((prev) => {
            return ({
                ...prev,
                botLastMessage,
            })
        })
    }
    const setMessageToRepeat = (messageToRepeat) => {
        setState((prev) => {
            return ({
                ...prev,
                messageToRepeat,
            })
        })
    }
    const handleFinishFirst = () => {
        const botMessage = createChatBotMessage(AnnaBot.finishFirst().message, {
            widget: 'finishFirstWidget',
        })

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.finishFirst().id}],
                })

            })
        }
    }
    const handleFinishSecondOne = () => {
        const botMessage = createChatBotMessage(AnnaBot.finishSecondOne().message, {})

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.finishSecondOne().id}],
                })

            })
        }
    }
    const handleFinishSecondTwo = () => {
        const botMessage = createChatBotMessage(AnnaBot.finishSecondTwo().message, {
            widget: 'finishSecondWidget'
        })

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaBot.finishSecondTwo().id}],
                })

            })
        }
    }

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleFinishFirst,
                        handleFinishSecondOne,
                        handleFinishSecondTwo,
                        handleNiceToMeetYou,
                        handleNumberOfQuestions,
                        handleBatteryLifeQuestion,
                        chooseBatteryLifeResponse,
                        handleWeightQuestion,
                        chooseWeightResponse,
                        handleNoiseCancellingQuestion,
                        chooseNoiseCancellingResponse,
                        handleBluetoothRangeQuestion,
                        chooseBluetoothRangeResponse,
                        handleFrequencyQuestion,
                        chooseFrequencyResponse,
                        handleChargingTimeQuestion,
                        chooseChargingTimeResponse,
                        handleFoldableQuestion,
                        chooseFoldableResponse,
                        handleAndroidQuestion,
                        chooseAndroidResponse,
                        handleIosQuestion,
                        chooseIosResponse,
                        handleGoogleQuestion,
                        chooseGoogleResponse,
                        handleAlexaQuestion,
                        chooseAlexaResponse,
                        handleWarrantyQuestion,
                        chooseWarrantyResponse,
                        handlePriceQuestion,
                        choosePriceResponse,
                        handleFeature1Question,
                        chooseFeature1Response,
                        handleFeature2Question,
                        chooseFeature2Response,
                        handleThanks,
                        handleRecommend,
                        handleJobComplete,
                        handleLastMessage,
                        setUserLastMessage,
                        setBotLastMessage,
                        setMessageToRepeat,
                        handleAnswerTheQuestion,
                        handleSorry,
                        handleClarifyBotQuestion,
                        handleNoiseCancellationQualityQuestion,
                        chooseNoiseCancellationQualityResponse
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;
