import React from 'react';
import './QuestionsTable.css';

const QuestionsTable = ({ questionText, questions, answers, selectedOptions, onOptionChange, textToRemove }) => {

    const formatQuestionLabel = (question, textToRemove)=>{
        if (question.includes(textToRemove)) {
            const updatedQuestion = question.replace(textToRemove, '');
            return updatedQuestion
        } else {
            return question
        }
    }
    return (
        <div className="questionnaire-container">
            {questionText ? <h3>{questionText}...</h3> : null}
            <table className="questionnaire-table">
                <thead>
                <tr>
                    <th></th>
                    {answers.map((answer, index) => (
                        <th key={index}>{answer}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {questions.map((question) => (
                    <tr key={question}>
                        <td>{formatQuestionLabel(question, textToRemove)}</td>
                        {answers.map((answer) => (
                            <td key={answer}>
                                <input
                                    type="radio"
                                    name={question}
                                    value={answer}
                                    checked={selectedOptions[question] === answer}
                                    onChange={() => onOptionChange(question, answer)}
                                />
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default QuestionsTable;
