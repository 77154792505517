import React, {useEffect, useState} from 'react';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import AnnaConfig from './Anna/config';
import AnnaMessageProvider from './Anna/MessageParser';
import AnnaActionProvider from './Anna/ActionProvider';
import AnnaTalkingConfig from './AnnaTalking/config';
import AnnaTalkingMessageProvider from './AnnaTalking/MessageParser';
import AnnaTalkingActionProvider from './AnnaTalking/ActionProvider';
import MichaelTalkingConfig from './MichaelTalking/config';
import MichaelTalkingMessageProvider from './MichaelTalking/MessageParser';
import MichaelTalkingActionProvider from './MichaelTalking/ActionProvider';
import SimpleBotConfig from './SimpleBot/config';
import SimpleBotMessageProvider from './SimpleBot/MessageParser';
import SimpleBotActionProvider from './SimpleBot/ActionProvider';
import {useSelector} from 'react-redux';
import ChatBotGreeting from './ChatBotGreeting';
import ChatBot from './ChatBot';

const ChatBotMain = () => {
    const {botType, userAnswers} = useSelector((state) => state.user);
    const [botOpened, setBotOpened] = useState(false)

    //Panel
    // useEffect(()=>{
    //     setBotOpened(false)
    // }, [window.location.pathname])
    //Panel
    const chooseTheBot = () => {
        switch (botType) {
            case 'human-like':
                return <Chatbot
                    config={AnnaConfig}
                    messageParser={AnnaMessageProvider}
                    actionProvider={AnnaActionProvider}
                    headerText='Virtual advisor'

                />
                break;
            case 'talking-head-f':
                return <Chatbot
                    config={AnnaTalkingConfig}
                    messageParser={AnnaTalkingMessageProvider}
                    actionProvider={AnnaTalkingActionProvider}
                    headerText='Virtual advisor'

                />
                break;
            case 'talking-head-m':
                return <Chatbot
                    config={MichaelTalkingConfig}
                    messageParser={MichaelTalkingMessageProvider}
                    actionProvider={MichaelTalkingActionProvider}
                    headerText='Virtual advisor'

                />
                break;
            default:
                return <Chatbot
                    config={SimpleBotConfig}
                    messageParser={SimpleBotMessageProvider}
                    actionProvider={SimpleBotActionProvider}
                    headerText='Virtual advisor'
                />
            break;
        }
    }
    return botOpened ? (
        <div className={'bot-container'}>
            <div className={botType === 'human-like' || botType === 'talking-head-f' ? 'anna-bot' : 'simple-bot'}>
                {chooseTheBot()}
            </div>
        </div>
    ) : <ChatBotGreeting setBotOpened={setBotOpened}/>
}
export default ChatBotMain



