import React, {useEffect} from 'react';
import {AnnaTalking} from './AnnaTalking';
import products from '../../../data/allProducts';
import separateNumbersAndWords from '../../../helpers/separateNumbersAndWords';
import recommendHeadphone from '../../../helpers/recommendHeadphone';
import {useDispatch, useSelector} from 'react-redux';
import {setRecommendedProduct, setUserAnswers} from '../../../store/slices/user-slice';
import {answerTheQuestion, fixTypo, getStringIncludesWord} from '../../../helpers/userQuestions';
import levenstein from 'fast-levenshtein';
import {
    getFrequencyToShowInAnswer, handleBetweenWarranty,
    handleNo,
    handleWarrantyEighteen, handleWarrantyHigh, handleWarrantyLow,
    handleWarrantyTwelve,
    handleYes
} from '../../../helpers/parseSynonyms';
import getDelay from '../../../helpers/getDelay';
import extractName from '../../../helpers/extractName';
import {detectBestUserAnswer} from '../../../helpers/userAnswers';
import getVideoDuration, {delayMessageByVideoDurationWithTimeout} from '../../../helpers/getVideoDuration';

const ActionProvider = ({createChatBotMessage, setState, children}) => {
    const AnnaTalkingBot = new AnnaTalking()
    const {version} = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const botLastMessage = children.props.children.props.state.botLastMessage;
    const createMessage = (func) => {
        return createChatBotMessage(null, {
            widget: 'simpleMessage',
            payload: func(),
            withAvatar: false
        })
    }
    const createAndSetMessage = (func, fieldName, fieldValue)=>{
        const botMessage = createMessage(func);
        setState((prev) => {
            // const answers = {
            //     ...(fieldName && fieldValue !==null  && fieldValue!==undefined ? {
            //         userAnswers: {
            //             ...prev.userAnswers,
            //             [fieldName]: fieldValue
            //         }
            //     } : {})
            // }
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, ...(func().id? {botMessageId: func().id}:{}), ...(func().payload?.id? {botMessageId: func().payload.id}:{}) }],
               ...(fieldName && fieldValue !==null && fieldValue!==undefined ? {userAnswers: {...prev.userAnswers, [fieldName]:fieldValue}}:{})
            })
        })
    }

    const handleSorry = async (questionToRepeat) => {
        createAndSetMessage(AnnaTalkingBot.sorry)
        if (questionToRepeat) {
            await delayMessageByVideoDurationWithTimeout(
                `/assets/videos/${AnnaTalkingBot.sorry().video}.mp4`,
                questionToRepeat
            )
        }
    }

    const handleNiceToMeetYou = (response) => {
        const name = extractName(response)
        createAndSetMessage(AnnaTalkingBot.niceToMeetYou, 'name', name)
    }
    const handleNumberOfQuestions = () => {
        createAndSetMessage(AnnaTalkingBot.helpToFind)
    }
    const handleBatteryLifeQuestion =   async () => {
        try{
            await createAndSetMessage(AnnaTalkingBot.batteryLifeQuestion)
            await delayMessageByVideoDurationWithTimeout(
                `/assets/videos/${AnnaTalkingBot.batteryLifeQuestion().video}.mp4`,
                () => handleClarifyBotQuestion('batteryLifeQuestion')
            )
        } catch(e) {
            console.log(e)
        }

    }



    const chooseBatteryLifeResponse = async (response) => {
        const batteryLife = separateNumbersAndWords(response)?.[0]
        if (Number(batteryLife) < 20) {
            createAndSetMessage(AnnaTalkingBot.batteryLifeResponseAnswer1, 'batteryLife', batteryLife)
        } else if (Number(batteryLife) > 60) {
            createAndSetMessage(AnnaTalkingBot.batteryLifeResponseAnswer2,'batteryLife', batteryLife)
        } else if (Number(batteryLife) >= 20 && Number(batteryLife) <= 60) {
            createAndSetMessage(AnnaTalkingBot.batteryLifeResponseAnswer3, 'batteryLife', batteryLife)
        } else {
            await handleSorry(handleBatteryLifeQuestion)
        }
    }


    const handleWeightQuestion = async () => {
        createAndSetMessage(AnnaTalkingBot.weightQuestion)
        await delayMessageByVideoDurationWithTimeout(
            `/assets/videos/${AnnaTalkingBot.weightQuestion().video}.mp4`,
            () => handleClarifyBotQuestion('weightQuestion')
        )
    }

    const chooseWeightResponse = (response) => {
        const weight = separateNumbersAndWords(response)?.[0]
        if (Number(weight) >= 0.1 && Number(weight) < 0.44) {
            createAndSetMessage(AnnaTalkingBot.weightResponseAnswer1, 'weight', weight)
        } else if (Number(weight) >= 0.44 && Number(weight) < 99) {
            createAndSetMessage(AnnaTalkingBot.weightResponseAnswer2, 'weight', weight)
        } else if (Number(weight) >= 99 && Number(weight) <= 250) {
            createAndSetMessage(AnnaTalkingBot.weightResponseAnswer3, 'weight', weight)
        } else if (Number(weight) >= 251 && Number(weight) <= 440) {
            createAndSetMessage(AnnaTalkingBot.weightResponseAnswer4, 'weight', weight)
        } else if (Number(weight) > 440) {
            createAndSetMessage(AnnaTalkingBot.weightResponseAnswer5, 'weight', weight)
        } else {
            handleSorry(handleWeightQuestion)
        }
    }

    // const handleNoiseCancellingQuestion = () => {
    //     const botMessage = createMessage(AnnaTalkingBot.noiseCancellingQuestion);
    //     setState((prev) => {
    //         return ({
    //             ...prev,
    //             messages: [...prev.messages, {
    //                 ...botMessage,
    //                 botMessageId: AnnaTalkingBot.noiseCancellingQuestion().id
    //             }],
    //         })
    //     })
    // }

    const handleNoiseCancellationQualityQuestion = () => {
        createAndSetMessage(AnnaTalkingBot.noiseCancellationQualityQuestion)
    }

    const chooseNoiseCancellationQualityResponse = (response) => {
        let botMessage
        const noiseCancellationQuality = separateNumbersAndWords(response)?.[0]
        if (Number(noiseCancellationQuality) < 3.5) {
            createAndSetMessage(AnnaTalkingBot.noiseCancellationQualityResponseAnswer1, 'noiseCancellationQuality', noiseCancellationQuality)
        } else if (Number(noiseCancellationQuality) >= 3.5 && Number(noiseCancellationQuality) <= 5) {
            createAndSetMessage(AnnaTalkingBot.noiseCancellationQualityResponseAnswer3, 'noiseCancellationQuality', noiseCancellationQuality)
        } else if (Number(noiseCancellationQuality) > 5) {
            createAndSetMessage(AnnaTalkingBot.noiseCancellationQualityResponseAnswer2, 'noiseCancellationQuality', noiseCancellationQuality)
        } else if (!Number(response) && detectBestUserAnswer(response)) {
            createAndSetMessage(AnnaTalkingBot.noiseCancellationQualityResponseAnswer4, 'noiseCancellationQuality', 5)
        } else {
            handleSorry(handleNoiseCancellationQualityQuestion)
        }
    }


//     const chooseNoiseCancellingResponse = (response) => {
//         let botMessage
//
//         const validResponses = ['active', 'passive', 'adaptive']
//         const validResponses2 = ['first', 'second', 'third']
//
//         const allValidResponses = [...validResponses, ...validResponses2]
//
//         // Join the valid responses into a regular expression pattern
//         const validResponsesPattern = allValidResponses.map(response => `\\b${response}\\b`).join('|');
//         const regex = new RegExp(validResponsesPattern, 'gi'); // Use 'gi' for case-insensitive matching
//         let fixedResponse = []
//         response.split(' ').forEach(el => {
//
//             for (let i = 0; i < allValidResponses.length; i++) {
//
//                 if (levenstein.get(allValidResponses[i], el) <= 1) {
//                     fixedResponse.push(allValidResponses[i])
//                 }
//             }
//         })
// // Extract valid responses from the response string
//         const extractedResponses = fixedResponse.join(' ').match(regex) || [];
//         const noiseCancelling = extractedResponses?.[0] || ''
//         if (validResponses.some(el => noiseCancelling.toLowerCase().includes(el))) {
//             botMessage = createMessage(AnnaTalkingBot.noiseCancellingResponseValid)
//             setState((prev) => {
//                 return ({
//                     ...prev,
//                     messages: [...prev.messages, {
//                         ...botMessage,
//                         botMessageId: AnnaTalkingBot.noiseCancellingResponseValid(noiseCancelling).id
//                     }],
//                     userAnswers: {...prev.userAnswers, noiseCancellingType: noiseCancelling}
//                 })
//             })
//         } else if (validResponses2.some(el => noiseCancelling.toLowerCase().includes(el))) {
//             if (noiseCancelling.toLowerCase().includes('first')) {
//                 botMessage = createMessage(AnnaTalkingBot.noiseCancellingResponseValid)
//                 setState((prev) => {
//                     return ({
//                         ...prev,
//                         messages: [...prev.messages, {
//                             ...botMessage,
//                             botMessageId: AnnaTalkingBot.noiseCancellingResponseValid('passive').id
//                         }],
//                         userAnswers: {...prev.userAnswers, noiseCancellingType: 'passive'}
//                     })
//                 })
//             } else if (noiseCancelling.toLowerCase().includes('second')) {
//                 botMessage = createMessage(AnnaTalkingBot.noiseCancellingResponseValid)
//                 setState((prev) => {
//                     return ({
//                         ...prev,
//                         messages: [...prev.messages, {
//                             ...botMessage,
//                             botMessageId: AnnaTalkingBot.noiseCancellingResponseValid('active').id
//                         }],
//                         userAnswers: {...prev.userAnswers, noiseCancellingType: 'active'}
//                     })
//                 })
//             } else if (noiseCancelling.toLowerCase().includes('third')) {
//                 botMessage = createMessage(AnnaTalkingBot.noiseCancellingResponseValid)
//                 setState((prev) => {
//                     return ({
//                         ...prev,
//                         messages: [...prev.messages, {
//                             ...botMessage,
//                             botMessageId: AnnaTalkingBot.noiseCancellingResponseValid('adaptive').id
//                         }],
//                         userAnswers: {...prev.userAnswers, noiseCancellingType: 'adaptive'}
//                     })
//                 })
//             }
//         } else {
//             botMessage = createMessage(AnnaTalkingBot.noiseCancellingResponseNotValid)
//             setState((prev) => {
//                 return ({
//                     ...prev,
//                     messages: [...prev.messages, {
//                         ...botMessage,
//                         botMessageId: AnnaTalkingBot.noiseCancellingResponseNotValid().id
//                     }],
//                 })
//             })
//         }
//
//
//     }
    const handleBluetoothRangeQuestion = async () => {
        createAndSetMessage(AnnaTalkingBot.bluetoothQuestion)
        await delayMessageByVideoDurationWithTimeout(
            `/assets/videos/${AnnaTalkingBot.bluetoothQuestion().video}.mp4`,
            () => handleClarifyBotQuestion('bluetoothQuestion')
        )
    }

    const chooseBluetoothRangeResponse = (response) => {
        const bluetoothRange = separateNumbersAndWords(response)?.[0]

        if (Number(bluetoothRange) <= 30) {
            createAndSetMessage(AnnaTalkingBot.bluetoothResponseAnswer1, 'bluetoothRange', bluetoothRange)
        } else if (Number(bluetoothRange) > 30) {
            createAndSetMessage(AnnaTalkingBot.bluetoothResponseAnswer2, 'bluetoothRange', bluetoothRange)
        } else {
            handleSorry(handleBluetoothRangeQuestion)
        }
    }

    // const handleFrequencyQuestion = () => {
    //     const botMessage = createChatBotMessage(
    //         null, {
    //             widget: 'frequencyResponseWidget',
    //             withAvatar: false,
    //             payload: AnnaTalkingBot.frequencyQuestion()
    //         }
    //     );
    //
    //     setState((prev) => {
    //         return ({
    //             ...prev,
    //             messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.frequencyQuestion().id}],
    //         })
    //     })
    //
    // }

    // const chooseFrequencyResponse = (response) => {
    //     let botMessage
    //     const frequency = response
    //     botMessage = createMessage(AnnaTalkingBot.frequencyResponseValidAnswer)
    //     setState((prev) => {
    //         return ({
    //             ...prev,
    //             messages: [...prev.messages, {
    //                 ...botMessage,
    //                 botMessageId: AnnaTalkingBot.frequencyResponseValidAnswer(getFrequencyToShowInAnswer(frequency)).id
    //             }],
    //             userAnswers: {
    //                 ...prev.userAnswers,
    //                 frequencyResponse: frequency
    //             }
    //
    //         })
    //     })
    //
    // }

    // const handleChargingTimeQuestion = () => {
    //     const botMessage = createMessage(AnnaTalkingBot.chargingTimeQuestion);
    //     setState((prev) => {
    //         return ({
    //             ...prev,
    //             messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.chargingTimeQuestion().id}],
    //         })
    //     })
    //     setTimeout(() => handleClarifyBotQuestion('chargingTimeQuestion'), getDelay(AnnaTalkingBot.chargingTimeQuestion().message))
    //
    // }

    // const chooseChargingTimeResponse = (response) => {
    //     let botMessage
    //     const chargingTime = separateNumbersAndWords(response)?.[0]
    //
    //     if (Number(chargingTime) >= 1.5 && Number(chargingTime) <= 7) {
    //         botMessage = createMessage(AnnaTalkingBot.chargingTimeResponseValidAnswer)
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                     botMessageId: AnnaTalkingBot.chargingTimeResponseValidAnswer(chargingTime).id
    //                 }],
    //                 userAnswers: {...prev.userAnswers, chargeTime: chargingTime}
    //
    //             })
    //         })
    //     } else {
    //         botMessage = createMessage(AnnaTalkingBot.chargingTimeResponseNotValidAnswer)
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                     botMessageId: AnnaTalkingBot.chargingTimeResponseNotValidAnswer(chargingTime).id
    //                 }],
    //
    //             })
    //         })
    //     }
    //
    //
    // }

    // const handleFoldableQuestion = () => {
    //     const botMessage = createMessage(AnnaTalkingBot.foldableQuestion);
    //     setState((prev) => {
    //         return ({
    //             ...prev,
    //             messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.foldableQuestion().id}],
    //         })
    //     })
    // }

    // const chooseFoldableResponse = (response) => {
    //     let botMessage
    //
    //     if (handleYes(response)) {
    //         botMessage = createMessage(AnnaTalkingBot.foldableResponseAnswer1)
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                     botMessageId: AnnaTalkingBot.foldableResponseAnswer1().id
    //                 }],
    //                 userAnswers: {...prev.userAnswers, foldable: true}
    //             })
    //         })
    //     } else if (handleNo(response)) {
    //         botMessage = createMessage(AnnaTalkingBot.foldableResponseAnswer2)
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                     botMessageId: AnnaTalkingBot.foldableResponseAnswer2().id
    //                 }],
    //                 userAnswers: {...prev.userAnswers, foldable: false}
    //             })
    //         })
    //     } else {
    //         handleSorry(handleFoldableQuestion)
    //     }
    //
    //
    // }

    const handleAndroidQuestion = () => {
        createAndSetMessage(AnnaTalkingBot.androidQuestion)
    }

    const chooseAndroidResponse = (response) => {
        if (handleYes(response)) {
            createAndSetMessage(AnnaTalkingBot.androidResponseAnswer1, 'androidControl', true)
        } else if (handleNo(response)) {
            createAndSetMessage(AnnaTalkingBot.androidResponseAnswer2, 'androidControl', false)
        } else {
            handleSorry(handleAndroidQuestion)
        }
    }

    const handleIosQuestion = () => {
        createAndSetMessage(AnnaTalkingBot.iosQuestion)
    }

    const chooseIosResponse = (response) => {
        if (handleYes(response)) {
            createAndSetMessage(AnnaTalkingBot.iosResponseAnswer1, 'iosControl', true)
        } else if (handleNo(response)) {
            createAndSetMessage(AnnaTalkingBot.iosResponseAnswer2, 'iosControl', false)
        } else {
            handleSorry(handleIosQuestion)
        }
    }

    // const handleGoogleQuestion = () => {
    //     const botMessage = createMessage(AnnaTalkingBot.googleQuestion);
    //     setState((prev) => {
    //         return ({
    //             ...prev,
    //             messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.googleQuestion().id}],
    //         })
    //     })
    // }

    // const chooseGoogleResponse = (response) => {
    //     let botMessage
    //
    //     if (handleYes(response)) {
    //         botMessage = createMessage(AnnaTalkingBot.googleResponseAnswer1)
    //
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                     botMessageId: AnnaTalkingBot.googleResponseAnswer1().id
    //                 }],
    //                 userAnswers: {...prev.userAnswers, googleAssistant: true}
    //
    //             })
    //         })
    //     } else if (handleNo(response)) {
    //         botMessage = createMessage(AnnaTalkingBot.googleResponseAnswer2)
    //
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                     botMessageId: AnnaTalkingBot.googleResponseAnswer2().id
    //                 }],
    //                 userAnswers: {...prev.userAnswers, googleAssistant: false}
    //
    //             })
    //         })
    //     } else {
    //         handleSorry(handleGoogleQuestion)
    //     }
    //
    // }

    // const handleAlexaQuestion = () => {
    //     const botMessage = createMessage(AnnaTalkingBot.alexaQuestion);
    //     setState((prev) => {
    //         return ({
    //             ...prev,
    //             messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.alexaQuestion().id}],
    //         })
    //     })
    // }
    //
    // const chooseAlexaResponse = (response) => {
    //     let botMessage
    //
    //     if (handleYes(response)) {
    //         botMessage = createMessage(AnnaTalkingBot.alexaResponseAnswer1)
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                     botMessageId: AnnaTalkingBot.alexaResponseAnswer1().id
    //                 }],
    //                 userAnswers: {...prev.userAnswers, amazonAlexa: true}
    //
    //             })
    //         })
    //     } else if (handleNo(response)) {
    //         botMessage = createMessage(AnnaTalkingBot.alexaResponseAnswer2)
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                     botMessageId: AnnaTalkingBot.alexaResponseAnswer2().id
    //                 }],
    //                 userAnswers: {...prev.userAnswers, amazonAlexa: false}
    //
    //             })
    //         })
    //     } else {
    //         handleSorry(handleAlexaQuestion)
    //     }
    //
    //
    // }

    // const handleWarrantyQuestion = () => {
    //     const botMessage = createMessage(AnnaTalkingBot.warrantyQuestion);
    //     setState((prev) => {
    //         return ({
    //             ...prev,
    //             messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.warrantyQuestion().id}],
    //         })
    //     })
    // }
    //
    // const chooseWarrantyResponse = (response) => {
    //     let botMessage
    //     if (handleWarrantyLow(response) || handleWarrantyHigh(response)) {
    //         const warranty = separateNumbersAndWords(response)?.[0]
    //
    //         botMessage = createMessage(AnnaTalkingBot.warrantyLowerClear)
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                 }],
    //             })
    //         })
    //     } else if (handleBetweenWarranty(response)) {
    //         handleClarifyBotQuestion('warrantyBetween')
    //     } else if (handleWarrantyTwelve(response)) {
    //         botMessage = createMessage(AnnaTalkingBot.warrantyResponseValidAnswer)
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                     botMessageId: AnnaTalkingBot.warrantyResponseValidAnswer(12).id
    //                 }],
    //                 userAnswers: {...prev.userAnswers, warranty: 12}
    //             })
    //         })
    //     } else if (handleWarrantyEighteen(response)) {
    //         botMessage = createMessage(AnnaTalkingBot.warrantyResponseValidAnswer)
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                     botMessageId: AnnaTalkingBot.warrantyResponseValidAnswer(18).id
    //                 }],
    //                 userAnswers: {...prev.userAnswers, warranty: 18}
    //             })
    //         })
    //     } else {
    //         botMessage = createMessage(AnnaTalkingBot.warrantyResponseNotValidAnswer)
    //         setState((prev) => {
    //             return ({
    //                 ...prev,
    //                 messages: [...prev.messages, {
    //                     ...botMessage,
    //                     botMessageId: AnnaTalkingBot.warrantyResponseNotValidAnswer().id
    //                 }],
    //             })
    //         })
    //     }
    //
    // }

    const handlePriceQuestion = async () => {
        createAndSetMessage(AnnaTalkingBot.priceQuestion)
        await delayMessageByVideoDurationWithTimeout(
            `/assets/videos/${AnnaTalkingBot.priceQuestion().video}.mp4`,
            () => handleClarifyBotQuestion('priceQuestion2')
        )
        const duration1 = await getVideoDuration(`/assets/videos/${AnnaTalkingBot.priceQuestion2Clear().video}.mp4`);
        const duration2 = await getVideoDuration(`/assets/videos/${AnnaTalkingBot.priceQuestion().video}.mp4`)
         setTimeout(() => handleClarifyBotQuestion('priceQuestion'), (duration1+duration2) * 1000);
    }

    const choosePriceResponse = (response) => {
        let price = separateNumbersAndWords(response)?.[0]
        if (getStringIncludesWord(response, 'minimum', 1) ||
            getStringIncludesWord(response, 'low', 1) ||
            getStringIncludesWord(response, 'lowest', 1) ||
            getStringIncludesWord(response, 'lower', 1) ||
            getStringIncludesWord(response, 'cheapest', 1) ||
            getStringIncludesWord(response, 'cheap', 1) ||
            getStringIncludesWord(response, 'cheaper', 1)
        ) {
            createAndSetMessage(AnnaTalkingBot.priceResponseValidAnswer, 'price', 119)
        } else if (Number(price) >= 119 && Number(price) <= 375) {
            createAndSetMessage(AnnaTalkingBot.priceResponseValidAnswer, 'price', price)
        } else if (Number(price) <= 119) {
            createAndSetMessage(AnnaTalkingBot.priceResponseValidAnswer, 'price', 119)
        } else if (Number(price) >= 375) {
            createAndSetMessage(AnnaTalkingBot.priceResponseValidAnswer, 'price', 375)
        } else {
            createAndSetMessage(AnnaTalkingBot.priceResponseNotValidAnswer )
        }
    }

    const handleFeature1Options = ()=>{
        const botMessage = createChatBotMessage(
            null,
            {
                widget: 'feature1',
                payload: AnnaTalkingBot.mostImportantFeature1Question(),
                withAvatar: false,
            }
        );
        setState((prev) => {
            return ({
                ...prev,
                type: AnnaTalkingBot.mostImportantFeature1Question().type,
                name: AnnaTalkingBot.mostImportantFeature1Question().name,
                messages: [...prev.messages, {
                    ...botMessage,
                    botMessageId: AnnaTalkingBot.mostImportantFeature1Question().id
                }],
            })
        })
    }

    const handleFeature1Question = async () => {
        createAndSetMessage(AnnaTalkingBot.mostImportantFeature1Question)
        await delayMessageByVideoDurationWithTimeout(
            `/assets/videos/${AnnaTalkingBot.mostImportantFeature1Question().video}.mp4`,
            () => handleFeature1Options()
        )
    }
    const chooseFeature1Response = () => {
        createAndSetMessage(AnnaTalkingBot.mostImportantFeature1ResponseAnswer)
    }

    const handleFeature2Options = () => {
        const botMessage = createChatBotMessage(null,
            {
                widget: 'feature2',
                payload: AnnaTalkingBot.mostImportantFeature2Question(),
                withAvatar: false,
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                type: AnnaTalkingBot.mostImportantFeature2Question().type,
                name: AnnaTalkingBot.mostImportantFeature2Question().name,
                messages: [...prev.messages, {
                    ...botMessage,
                    botMessageId: AnnaTalkingBot.mostImportantFeature2Question().id
                }],
            })
        })
    }
    const handleFeature2Question = async () => {
        createAndSetMessage(AnnaTalkingBot.mostImportantFeature2Question)
        await delayMessageByVideoDurationWithTimeout(
            `/assets/videos/${AnnaTalkingBot.mostImportantFeature2Question().video}.mp4`,
            () => handleFeature2Options()
        )
    }

    const chooseFeature2Response = () => {
        createAndSetMessage(AnnaTalkingBot.mostImportantFeature2ResponseAnswer)
    }

    const handleThanks = () => {
        createAndSetMessage(AnnaTalkingBot.thanks)
    }
    const handleJokeQuestion = () => {
        createAndSetMessage(AnnaTalkingBot.joke)
    }

    const chooseJokeAnswer = (response) => {
        if (handleYes(response)) {
            createAndSetMessage(AnnaTalkingBot.jokeResponseAnswerPostive, 'joke',true)
        } else if (handleNo(response)) {
            createAndSetMessage(AnnaTalkingBot.jokeResponseAnswerNegative, 'joke',false)
        } else {
            handleSorry(handleJokeQuestion)
        }
    }

    const handleAdditionalQuestionsQuestionButton = () => {
        const botMessage = createChatBotMessage(null, {
            widget: 'additionalQuestionsWidget',
            payload: AnnaTalkingBot.additionalQuestionsQuestion(),
            withAvatar: false,
        })

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.additionalQuestionsQuestion().id}],
                })

            })
        }
    }
    const handleAdditionalQuestionsQuestions = async () => {
        createAndSetMessage(AnnaTalkingBot.additionalQuestionsQuestion)
        await delayMessageByVideoDurationWithTimeout(
            `/assets/videos/${AnnaTalkingBot.additionalQuestionsQuestion().video}.mp4`,
            () => handleAdditionalQuestionsQuestionButton()
        )
    }
    const handleRecommendProductTable = () => {
        const product = recommendHeadphone(children.props.children.props.state.userAnswers, version)
        dispatch(setRecommendedProduct(product))
        dispatch(setUserAnswers(children.props.children.props.state.userAnswers))
        const botMessage = createChatBotMessage(
            null,
            {
                widget: 'headphones',
                payload: AnnaTalkingBot.foundRecommended(product),
                withAvatar: false,
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                type: AnnaTalkingBot.foundRecommended().type,
                name: AnnaTalkingBot.foundRecommended().name,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.foundRecommended().id}],
                productChoosedByBot: product
            })
        })

    }
    const handleRecommend = async () => {
        createAndSetMessage(AnnaTalkingBot.foundRecommended)
        await delayMessageByVideoDurationWithTimeout(
            `/assets/videos/${AnnaTalkingBot.foundRecommended().video}.mp4`,
            () => handleRecommendProductTable()
        )
    }


    // const handleJobComplete = () => {
    //     createAndSetMessage(AnnaTalkingBot.jobComplete)
    // }



    const handleClarifyBotQuestion = (questionIdToClarify) => {
        const botMessage = createMessage(AnnaTalkingBot[`${questionIdToClarify}Clear`])

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: `${questionIdToClarify}Clear`}],
                })

            })
        }

    }
    const findQuestionToRepeat=(lastQuestion)=>{
        switch(lastQuestion.botMessageId){
            case 'batteryLifeQuestionClear': {
                return handleBatteryLifeQuestion;
                break;
            }
            case 'weightQuestionClear': {
                return handleWeightQuestion;
                break;
            }
            case 'noiseCancellationQualityQuestion': {
                return handleNoiseCancellationQualityQuestion;
                break;
            }
            case 'bluetoothQuestionClear': {
                return handleBluetoothRangeQuestion;
                break;
            }
            case 'androidQuestion': {
                return handleAndroidQuestion;
                break;
            }
            case 'iosQuestion': {
                return handleIosQuestion;
                break;
            }
            case 'priceQuestionClear': {
                return handlePriceQuestion;
                break;
            }
            case 'jokeQuestion': {
                return handleJokeQuestion;
                break;
            }
            default: {
                return handleBatteryLifeQuestion
            };
        }
    }
    const handleAnswerTheQuestion = async (message, lastQuestion, name = '') => {
        const answer = answerTheQuestion(message, 'talking-head-f', lastQuestion?.botMessageId)
        if (answer) {

            if (typeof answer.message === 'string') {
                createAndSetMessage(()=>answer)
            }
            // else {
            //     const botMessage = createChatBotMessage(answer)
            //     setState((prev) => {
            //         return ({
            //             ...prev,
            //             messages: [...prev.messages, {...botMessage, botMessageId: answer.botMessageId}],
            //         })
            //     })
            // }
            // if (answer.message.includes('Anna') && (!name || name === '' || name === '-')) {
            //     setTimeout(() => {
            //         const botLastMessage = createChatBotMessage(`And yours?`)
            //         setState((prev) => {
            //             return ({
            //                 ...prev,
            //                 messages: [...prev.messages, {...botLastMessage, botMessageId: 'repeatNameQuestion'}],
            //             })
            //         })
            //     }, 1500)
            // } else
            if (lastQuestion) {
                const questionToRepeat = findQuestionToRepeat(lastQuestion)
                if (questionToRepeat) {
                    await delayMessageByVideoDurationWithTimeout(
                        `/assets/videos/${answer.video}.mp4`,
                        () => questionToRepeat()
                    )
                }
            }

            // else if (answer.includes('fine')) {
            //     const botLastMessage = createChatBotMessage(`Let's continue choosing your best headphone`)
            //     setState((prev) => {
            //         return ({
            //             ...prev,
            //             messages: [...prev.messages, {...botLastMessage, botMessageId: 'returnToChoose'}],
            //         })
            //     })
            // }

        } else {
           const questionToRepeat =  findQuestionToRepeat(lastQuestion)
            await handleSorry(questionToRepeat)
        }
    }

    const setUserLastMessage = (userLastMessage) => {
        setState((prev) => {
            return ({
                ...prev,
                userLastMessage,
            })
        })
    }
    const setBotLastMessage = (botLastMessage) => {
        setState((prev) => {
            return ({
                ...prev,
                botLastMessage,
            })
        })
    }
    const setMessageToRepeat = (messageToRepeat) => {
        setState((prev) => {
            return ({
                ...prev,
                messageToRepeat,
            })
        })
    }
    const handleFinishFirstButton = () => {
        const botMessage = createChatBotMessage(null, {
            widget: 'finishFirstWidget',
            payload: AnnaTalkingBot.finishFirst(),
            withAvatar: false,
        })

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.finishFirst().id}],
                })

            })
        }
    }
    const handleFinishFirst = async () => {
        createAndSetMessage(AnnaTalkingBot.finishFirst)
        await delayMessageByVideoDurationWithTimeout(
            `/assets/videos/${AnnaTalkingBot.finishFirst().video}.mp4`,
            () => handleFinishFirstButton()
        )
    }


    const handleFinishSecondOne = () => {
        const botMessage = createMessage(AnnaTalkingBot.finishSecondOne)

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.finishSecondOne().id}],
                })
            })
        }
    }
    const handleFinishSecondTwoButton = () => {
        const botMessage = createChatBotMessage(null, {
            widget: 'finishSecondWidget',
            payload: AnnaTalkingBot.finishSecondTwo(),
            withAvatar: false
        })

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.finishSecondTwo().id}],
                })

            })
        }
    }
    const handleFinishSecondTwo = async () => {
        createAndSetMessage(AnnaTalkingBot.finishSecondTwo)
        await delayMessageByVideoDurationWithTimeout(
            `/assets/videos/${AnnaTalkingBot.finishSecondTwo().video}.mp4`,
            () => handleFinishSecondTwoButton()
        )
    }


    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleFinishFirst,
                        handleFinishSecondOne,
                        handleFinishSecondTwo,
                        handleNiceToMeetYou,
                        handleNumberOfQuestions,
                        handleBatteryLifeQuestion,
                        chooseBatteryLifeResponse,
                        handleWeightQuestion,
                        chooseWeightResponse,
                        // handleNoiseCancellingQuestion,
                        // chooseNoiseCancellingResponse,
                        handleBluetoothRangeQuestion,
                        chooseBluetoothRangeResponse,
                        // handleFrequencyQuestion,
                        // chooseFrequencyResponse,
                        // handleChargingTimeQuestion,
                        // chooseChargingTimeResponse,
                        // handleFoldableQuestion,
                        // chooseFoldableResponse,
                        handleAndroidQuestion,
                        chooseAndroidResponse,
                        handleIosQuestion,
                        chooseIosResponse,
                        // handleGoogleQuestion,
                        // chooseGoogleResponse,
                        // handleAlexaQuestion,
                        // chooseAlexaResponse,
                        // handleWarrantyQuestion,
                        // chooseWarrantyResponse,
                        handlePriceQuestion,
                        choosePriceResponse,
                        handleFeature1Question,
                        chooseFeature1Response,
                        handleFeature2Question,
                        chooseFeature2Response,
                        handleThanks,
                        handleRecommend,
                        // handleJobComplete,
                        // handleLastMessage,
                        setUserLastMessage,
                        setBotLastMessage,
                        setMessageToRepeat,
                        handleAnswerTheQuestion,
                        handleSorry,
                        handleClarifyBotQuestion,
                        handleNoiseCancellationQualityQuestion,
                        chooseNoiseCancellationQualityResponse,
                        handleJokeQuestion,
                        chooseJokeAnswer,
                        handleAdditionalQuestionsQuestions
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;
