import levenstein from 'fast-levenshtein';
import detectUserUnderstanding from './detectUserUnderstanding';
import {formatString} from './parseSynonyms';

const questionKeywords = [
    'what',
    'why',
    'how',
    'who',
    'where',
    'no idea',
    `don't understand`,
    'do not understand',
    `don't remember`,
    'do not remember',
    `don't get`,
    'do not get',
    'do not know',
    `don't know`,
    'information',
    'explain',
    'clarify',
    'specify',
    'elaborate',
    'confused',
    'help',
    'tell',]
export const isQuestion = (message) => {

    const formattedMessage = message
        .toLowerCase().split(' ').map(messageWord => {
            let changedWord = messageWord;
            for (let i = 0; i < questionKeywords.length; i++) {
                if (levenstein.get(questionKeywords[i], messageWord) < 2) {
                    changedWord = questionKeywords[i];
                    break;
                }
            }
            return changedWord;
        });

    if (message.includes('?') || questionKeywords.some(el => message.includes(el))) {
        return true
    } else return formattedMessage.some(word => questionKeywords.includes(word));

}
const findAnswer = (questionType) => {
    return randomUserQuestions.find((el) => el.type === questionType)
}
export const fixTypo = (word, element, accuracy) => {
    if (levenstein.get(word, element) < accuracy) {
        return word
    } else return element
}
export const getStringIncludesWord = (string, word, accuracy = 0) => {
    const formattedString = string.toLowerCase().split(' ').map(el => fixTypo(word, el, accuracy))
    return formattedString.includes(word)
}


const randomUserQuestions = [
    {
        type: 'whatWarranty',
        answer: `Warranty is a written guarantee, issued to the purchaser of an article by its manufacturer, promising to repair or replace it if necessary within a specified period of time`,
        questions: ['what is a warranty?',
            'What does warranty mean?',
            'Tell me about warranty?',
            'I don’t know what warranty is.',
            'I do not know what warranty is.']
    },
    {
        type: 'whatBatteryLife',
        questions: [
            'what is battery life?',
            'what does battery life mean?',
            'what is the meaning of battery life?'
        ],
        answer: `Battery life is the length of  time a pair of headphones work on one single charge.`,

    },
    {
        type: 'whyBatteryLifeImportant',
        questions: [
            'why does battery life matter?',
            'what is the important of battery life?',
            'why is battery life important?'
        ],
        answer: `Longer battery life of a headphone means that the battery has a higher capacity, can hold more change, and hence lets you listen to music longer without needing to recharge it.`,
    },
    {
        type: 'whatChargeTime',
        questions: [
            'what is battery charge time?',
            'what does battery charge time mean?',
            'what is the meaning of battery charge time?'],
        answer: `Battery charge time is the duration that it takes to fully recharge the headphones.`
    },
    {
        type: 'whyChargeTimeImportant',
        questions: [
            'why does battery charge time matter?',
            'what is the important of battery charge time?',
            'why is battery charge time important?'
        ],
        answer: `Shorter battery charge time lets you charge your headphone faster.`
    },
    {
        type: 'whatType',
        questions: [
            'what are the types of noise cancellation?',
        ],
        answer: `- Noise-cancelling headphones can be “Passive”, “Active“, or “Adaptive”
- Passive: relies on well-designed ear cups to block out noise.
- Active: uses a processor inside each cup that detects outside sound and produces a signal that cancels it out.
Adaptive: similar to active noise cancellation with one difference: it changes the level of noise cancelling to adapt to the surroundings in terms of loudness.`
    },
    {
        type: 'whatPassiveType',
        questions: [
            'what is a passive noise-cancelling headphone?',
            'how does a passive noise-cancelling headphone work?'
        ],
        answer: `A passive noise-cancelling headphone relies on well-designed ear cups to block out noise.`
    },
    {
        type: 'whatActiveType',
        questions: [
            'what is an active noise-cancelling headphone?',
            'how does an active noise-cancelling headphone work?'
        ],
        answer: `An active noise-cancelling headphone uses a processor inside each cup that detects outside sound and produces a signal that cancels it out.`
    },
    {
        type: 'whatAdaptiveType',
        questions: [
            'what is an adaptive noise-cancelling headphone?',
            'how does an adaptive noise-cancelling headphone work?'
        ],
        answer: `An adaptive noise-cancelling uses a processor inside each cup that detects outside sound and produces a signal that cancels it out. The headphone changes the level of noise cancelling to adapt to the surroundings in terms of loudness.`
    },
    {
        type: 'differencePassiveActive',
        questions: [
            'what is the difference between passive and active headphones?',
        ],
        answer: `A passive noise-cancelling headphone relies on well-designed ear cups to block out noise. An active noise-cancelling headphone uses a processor inside each cup that detects outside sound and produces a signal that cancels it out.`
    },
    {
        type: 'differencePassiveAdaptive',
        questions: [
            'what is the difference between passive and adaptive headphones?',
        ],
        answer: `A passive noise-cancelling headphone relies on well-designed ear cups to block out noise. An adaptive noise-cancelling uses a processor inside each cup that detects outside sound and produces a signal that cancels it out. The headphone changes the level of noise cancelling to adapt to the surroundings in terms of loudness.`
    },
    {
        type: 'differenceActiveAdaptive',
        questions: [
            'what is the difference between active and adaptive headphones?',
        ],
        answer: `Active and adaptive noise-cancelling headphone both a processor inside each cup that detects outside sound and produces a signal that cancels it out. However, unlike active noise-cancelling headphones, the adaptive ones change the level of noise cancelling to adapt to the surroundings in terms of loudness.`
    },
    {
        type: 'whatBluetoothRange',
        questions: [
            'what does Bluetooth range mean?',
            'what is the meaning of Bluetooth range?'
        ],
        answer: `How far a pair of headphones hold connection to other devices (e.g., phone, computer, tablet).`
    },
    {
        type: 'whatFrequencyResponse',
        questions: [
            'what is frequency response?',
            'what does frequency response mean?',
            'what is the meaning of frequency response?'
        ],
        answer: `The range of frequencies or musical tones a headphone can reproduce.`
    },
    {
        type: 'whyFrequencyResponseImportant',
        questions: [
            'why does frequency response matter?',
            'what is the importance of frequency response?',
            'why is frequency response important?'
        ],
        answer: `An audio device with good frequency response is able to play all the low, middle, and high tones correctly, and in the proper proportion to each other.`
    },
    {
        type: 'whatHz',
        questions: [
            'what does Hz stand for?',
        ],
        answer: `Hertz. Hertz (Hz) is the standard unit of frequency in the International System of Units (SI)`
    },
    {
        type: 'whyFoldabilityImportant',
        questions: [
            'why does foldability matter?',
            'what is the importance of foldability?',
            'why is foldability important?',
            'what is foldable?',
            'what is foldability?',
            'what does foldability means',
            'what does foldable means'
        ],
        answer: `Foldability helps you easily fold your headphone and carry it in your bag.`
    },
    {
        type: 'whatAndroid',
        questions: [
            'what does android control mean?',
            'what is android control?',
            'what is the meaning of android control?',
        ],
        answer: `With Android control you can make and take calls, control music, etc. without holding your Android-based phone.`
    },
    {
        type: 'whatIos',
        questions: [
            'what does iOS control mean?',
            'what is iOS control?',
            'what is the meaning of iOS control?',
        ],
        answer: `With iOS control you can make and take calls, control music, etc. without holding your iPhone or iPad.`
    },
    {
        type: 'whatGoogle',
        questions: [
            'what does working with Google Assistant mean?',
            'what is workiwg with Google Assistant?',
            'what is the meaning of working with Google Assistant?',
        ],
        answer: `Built-in compatibility with Google Assistant allows you to use voice commands to cue music, make calls, listen to weather updates, etc.`
    },
    {
        type: 'whatAlexa',
        questions: [
            'what does working with Amazon Alexa mean?',
            'what is working with Amazon Alexa?',
            'what is the meaning of working with Amazon Alexa?',
        ],
        answer: `Built-in compatibility Amazon Alexa allows you to use voice commands to cue music, make calls, listen to weather updates, etc.`
    },
    {
        type: 'whatNoiseCancellationQuality',
        questions: [
            'what is noise cancellation quality?',
            'what does noise cancellation quality mean?',
            'what is the meaning of noise cancellation quality?',
            'why does noise cancellation quality matter?',
            'what is the importance of noise cancellation quality?',
            'why is noise cancellation quality important?'
        ],
        answer: `Noise cancellation quality is determined based on experts' tests. It shows the extent that a headphone can block out noises of different frequencies.
Better noise cancellation quality helps reduce distractions and increases focus and also improves sound quality providing a better listening experience.`

    },
    // {
    //     questions: ['and you?', 'how about you?', 'how are you?', `how're you?`,`how's it going?`, 'how is it going?', 'what about you?'],
    //     type: 'great',
    //     answer: {message:`I'm ok, thank you`, botMessageId: 'great'}
    // },
    {
        type: 'nameAnna',
        questions: [
            'what is your name?',
            `what's your name?`,
        ],
        answer: `My name is Anna. I am your personal virtual advisor at Headphone Tech`
    },
    {
        type: 'nameSimple',
        questions: [
            'what is your name?',
            `what's your name?`,
        ],
        answer: `Actually, I don't have a name. I'm the headphone virtual advisor at Headphone Tech.`
    },

    // {
    //     type: 'fineAnna',
    //     questions: [
    //         'how are you?',
    //         `how're you?`,
    //         `how are you doing?`,
    //         `how're you doing?`,
    //         `how's it going?`,
    //         'how is it going?',
    //         `how you doing?`,
    //         `how do you do?`,
    //         `and you?`,
    //         `what about you?`,
    //         `about you?`,
    //     ],
    //     answer: `I am fine, thank you`,
    //     botType: 'human-like'
    // },
    // {
    //     type: 'fineSimple',
    //     questions: [
    //         'how are you?',
    //         `how're you?`,
    //         `how are you doing?`,
    //         `how're you doing?`,
    //         `how's it going?`,
    //         'how is it going?',
    //         `how you doing?`,
    //         `how do you do?`,
    //         `and you?`,
    //         `what about you?`,
    //         `about you?`,
    //     ],
    //     answer: `I am a machine and therefore, do not have feelings`,
    //     botType: 'machine-like'
    //
    // },
]

const detectQuestionType = (questionMessage, botType) => {
    const question = formatString(questionMessage.replace('?', ''))
    if (

        (getStringIncludesWord(question, 'name', 0) || getStringIncludesWord(question, 'your name', 1))
    ) {
        if (botType === 'human-like' || botType === 'talking-head-f') {
            return 'nameAnna'
        } else {
            return 'nameSimple'
        }
    }

    if (
        getStringIncludesWord(question, 'warranty', 1)
    ) {
        return 'whatWarranty'
    }
    if (
        !(getStringIncludesWord(question, 'important', 3)
            || getStringIncludesWord(question, 'importance', 3)
            || getStringIncludesWord(question, 'significance', 3)
            || getStringIncludesWord(question, 'significant', 3)
            || getStringIncludesWord(question, 'matter', 2)
        )
        &&
        (getStringIncludesWord(question, 'battery', 3) && getStringIncludesWord(question, 'life', 1))
    ) {
        return 'whatBatteryLife'
    }
    if (
        (getStringIncludesWord(question, 'important', 3)
            || getStringIncludesWord(question, 'importance', 3)
            || getStringIncludesWord(question, 'significance', 3)
            || getStringIncludesWord(question, 'significant', 3)
            || getStringIncludesWord(question, 'matter', 1)
        )
        &&
        (getStringIncludesWord(question, 'battery', 3) && getStringIncludesWord(question, 'life', 1))
    ) {
        return 'whyBatteryLifeImportant'
    }

    if (
        !(getStringIncludesWord(question, 'important', 3)
            || getStringIncludesWord(question, 'importance', 3)
            || getStringIncludesWord(question, 'significance', 3)
            || getStringIncludesWord(question, 'significant', 3)
            || getStringIncludesWord(question, 'matter', 1)
        )
        &&
        ((getStringIncludesWord(question, 'charge', 1) || getStringIncludesWord(question, 'charging', 1))
            && (getStringIncludesWord(question, 'time', 1) || getStringIncludesWord(question, 'duration', 3)))
    ) {
        return 'whatChargeTime'
    }

    if (
        (getStringIncludesWord(question, 'important', 3)
            || getStringIncludesWord(question, 'importance', 3)
            || getStringIncludesWord(question, 'significance', 3)
            || getStringIncludesWord(question, 'significant', 3)
            || getStringIncludesWord(question, 'matter', 1)
        )
        &&
        ((getStringIncludesWord(question, 'charge', 1) || getStringIncludesWord(question, 'charging', 1))
            && (getStringIncludesWord(question, 'time', 1) || getStringIncludesWord(question, 'duration', 1)))
    ) {
        return 'whyChargeTimeImportant'
    }

    if (
        !(getStringIncludesWord(question, 'passive', 3)
            || getStringIncludesWord(question, 'active', 3)
            || getStringIncludesWord(question, 'adaptive', 3)
            || getStringIncludesWord(question, 'difference', 3)
            || getStringIncludesWord(question, 'different', 3)
            || getStringIncludesWord(question, 'distinguish', 3)
            || getStringIncludesWord(question, 'distinction', 3))
        &&
        (getStringIncludesWord(question, 'type', 1)
            || getStringIncludesWord(question, 'types', 1))

    ) {
        return 'whatType'
    }

    if (!(
            getStringIncludesWord(question, 'active', 3)
            || getStringIncludesWord(question, 'adaptive', 3)
            || getStringIncludesWord(question, 'difference', 3)
            || getStringIncludesWord(question, 'different', 3)
            || getStringIncludesWord(question, 'distinguish', 3)
            || getStringIncludesWord(question, 'distinction', 3)) &&
        getStringIncludesWord(question, 'passive', 3)) {
        return 'whatPassiveType'
    }
    if (!(
            getStringIncludesWord(question, 'passive', 3)
            || getStringIncludesWord(question, 'adaptive', 3)
            || getStringIncludesWord(question, 'difference', 3)
            || getStringIncludesWord(question, 'different', 3)
            || getStringIncludesWord(question, 'distinguish', 3)
            || getStringIncludesWord(question, 'distinction', 3)) &&
        getStringIncludesWord(question, 'active', 3)) {
        return 'whatActiveType'
    }
    if (!(
            getStringIncludesWord(question, 'passive', 3)
            || getStringIncludesWord(question, 'active', 3)) &&
        getStringIncludesWord(question, 'adaptive', 3)) {
        return 'whatAdaptiveType'
    }


    if ((
            getStringIncludesWord(question, 'difference', 3)
            || getStringIncludesWord(question, 'different', 3)
            || getStringIncludesWord(question, 'distinguish', 3)
            || getStringIncludesWord(question, 'distinction', 3)
        ) &&
        (getStringIncludesWord(question, 'passive', 3) && getStringIncludesWord(question, 'active', 3))
    ) {
        return 'differencePassiveActive'
    }

    if ((
            getStringIncludesWord(question, 'difference', 3)
            || getStringIncludesWord(question, 'different', 3)
            || getStringIncludesWord(question, 'distinguish', 3)
            || getStringIncludesWord(question, 'distinction', 3)
        ) &&
        (getStringIncludesWord(question, 'passive', 3) && getStringIncludesWord(question, 'adaptive', 3))
    ) {
        return 'differencePassiveAdaptive'
    }


    if ((
            getStringIncludesWord(question, 'difference', 3)
            || getStringIncludesWord(question, 'different', 3)
            || getStringIncludesWord(question, 'distinguish', 3)
            || getStringIncludesWord(question, 'distinction', 3)
        ) &&
        (getStringIncludesWord(question, 'active', 3) && getStringIncludesWord(question, 'adaptive', 3))
    ) {
        return 'differenceActiveAdaptive'
    }

    if ((
            getStringIncludesWord(question, 'bluetooth', 3)
            || getStringIncludesWord(question, 'blutooth', 3)
            || getStringIncludesWord(question, 'bluetoot', 3)
            || getStringIncludesWord(question, 'bluetoth', 3)
        ) &&
        (getStringIncludesWord(question, 'range', 1)
            || getStringIncludesWord(question, 'distance', 3)
            || getStringIncludesWord(question, 'far', 0)
            || getStringIncludesWord(question, 'meter', 1)
            || getStringIncludesWord(question, 'metr', 1)
        )
    ) {
        return 'whatBluetoothRange'
    }


    if (
        !(getStringIncludesWord(question, 'important', 3)
            || getStringIncludesWord(question, 'importance', 3)
            || getStringIncludesWord(question, 'significance', 3)
            || getStringIncludesWord(question, 'significant', 3)
            || getStringIncludesWord(question, 'matter', 2)

        ) &&
        (
            getStringIncludesWord(question, 'frequency', 3)
            || getStringIncludesWord(question, 'freq', 0)
        ) &&
        (getStringIncludesWord(question, 'response', 3)
            || getStringIncludesWord(question, 'respons', 3)
            || getStringIncludesWord(question, 'rate', 1)
        )
    ) {
        return 'whatFrequencyResponse'
    }


    if (
        (getStringIncludesWord(question, 'important', 3)
            || getStringIncludesWord(question, 'importance', 3)
            || getStringIncludesWord(question, 'significance', 3)
            || getStringIncludesWord(question, 'significant', 3)
            || getStringIncludesWord(question, 'matter', 2)

        ) &&
        (
            getStringIncludesWord(question, 'frequency', 3)
            || getStringIncludesWord(question, 'freq', 0)
        ) &&
        (getStringIncludesWord(question, 'response', 3)
            || getStringIncludesWord(question, 'respons', 3)
            || getStringIncludesWord(question, 'rate', 1)
        )
    ) {
        return 'whyFrequencyResponseImportant'
    }


    if (
        (getStringIncludesWord(question, 'stand', 1)
            || getStringIncludesWord(question, 'mean', 1)

        ) &&
        (
            getStringIncludesWord(question, 'hz', 0)
        )
    ) {
        return 'whatHz'
    }


    if (
        (getStringIncludesWord(question, 'important', 3)
            || getStringIncludesWord(question, 'importance', 3)
            || getStringIncludesWord(question, 'significance', 3)
            || getStringIncludesWord(question, 'significant', 3)
            || getStringIncludesWord(question, 'matter', 2)

        ) &&
        (
            getStringIncludesWord(question, 'foldability', 3)
            || getStringIncludesWord(question, 'fold', 0)
            || getStringIncludesWord(question, 'foldable', 1)
        )
    ) {
        return 'whyFoldabilityImportant'
    }

    if (
        getStringIncludesWord(question, 'android', 3)
    ) {
        return 'whatAndroid'
    }

    if (
        getStringIncludesWord(question, 'ios', 0)
        || getStringIncludesWord(question, 'iphone', 0)
        || getStringIncludesWord(question, 'ipad', 0)
        || getStringIncludesWord(question, 'mac', 0)
        || getStringIncludesWord(question, 'apple', 0)
    ) {
        return 'whatIos'
    }

    if (
        getStringIncludesWord(question, 'google', 3)
    ) {
        return 'whatGoogle'
    }

    if (
        getStringIncludesWord(question, 'alexa', 3) || getStringIncludesWord(question, 'amazon', 3)
    ) {
        return 'whatAlexa'
    }

    if (
        !(getStringIncludesWord(question, 'important', 3)
            || getStringIncludesWord(question, 'importance', 3)
            || getStringIncludesWord(question, 'significance', 3)
            || getStringIncludesWord(question, 'significant', 3)
            || getStringIncludesWord(question, 'matter', 2)

        ) &&
        (
            getStringIncludesWord(question, 'noise', 3)
        ) &&
        (getStringIncludesWord(question, 'cancellation', 3)
            || getStringIncludesWord(question, 'cancelling', 3)
            || getStringIncludesWord(question, 'cancelation', 3)
        ) &&
        (
            (getStringIncludesWord(question, 'quality', 3)
        )
        )
    ) {
        return 'whatNoiseCancellationQuality'
    }

}

const getExplainingMessageId = (question, botQuestionId,) => {
    const formattedBotQuestionId = formatString(botQuestionId)
    if (formattedBotQuestionId.includes('batterylife')) {
        return 'whatBatteryLife'
    }

    if (formattedBotQuestionId.includes('chargingtime')) {
        return 'whatChargeTime'
    }
    if (formattedBotQuestionId.includes('noisecancelling')) {
        return 'whatType'
    }
    if (formattedBotQuestionId.includes('bluetooth')) {
        return 'whatBluetoothRange'
    }
    if (formattedBotQuestionId.includes('frequency')) {
        return 'whatFrequencyResponse'
    }
    if (formattedBotQuestionId.includes('foldable')) {
        return 'whyFoldabilityImportant'
    }
    if (formattedBotQuestionId.includes('android')) {
        return 'whatAndroid'
    }
    if (formattedBotQuestionId.includes('ios')) {
        return 'whatIos'
    }
    if (formattedBotQuestionId.includes('google')) {
        return 'whatGoogle'
    }
    if (formattedBotQuestionId.includes('alexa')) {
        return 'whatAlexa'
    }
    if (formattedBotQuestionId.includes('noisecancellationquality')) {
        return 'whatNoiseCancellationQuality'
    }
}
const returnTalkingHeadAnswer=(botType, answerObject)=>{
    if(botType === 'talking-head-f') {
        return ({
            message: answerObject.answer,
            video: answerObject.type,
        })
    }
    if(botType === 'talking-head-m') {
        return ({
            message: answerObject.answer,
            video: answerObject.type,
        })
    }
}
export const answerTheQuestion = (question, botType, botQuestionId) => {
    const isTalkingBot = botType==='talking-head-f' || botType ==='talking-head-m'
    const answerForExactQuestion = randomUserQuestions.find(el => {
        return el?.questions?.includes(formatString(question))
    })
    //
    // const answersForExactQuestionDependingOnBotType = randomUserQuestions.filter(el => {
    //     return el?.questions?.includes(formatString(question)) && el.botType === botType
    // })?.[0]

    // if (answersForExactQuestionDependingOnBotType?.answer) {
    //     console.log('answersForExactQuestionDependingOnBotType')
    //     if(isTalkingBot) {
    //         return returnTalkingHeadAnswer(botType, answersForExactQuestionDependingOnBotType)
    //     } else return answersForExactQuestionDependingOnBotType?.answer
    // } else
        if (answerForExactQuestion?.answer
        && !question.includes('name')
    ) {
        if(isTalkingBot) {
            return returnTalkingHeadAnswer(botType, answerForExactQuestion)
        }else return answerForExactQuestion?.answer
    } else {
        //if user don't understand
        if (!detectUserUnderstanding(question)) {
            const answerByQuestionType = findAnswer(detectQuestionType(formatString(question), botType))
            if (answerByQuestionType?.answer) {
                if(isTalkingBot) {
                    return returnTalkingHeadAnswer(botType, answerByQuestionType)
                } else return answerByQuestionType?.answer

            } else if (botQuestionId) {
                const answerByBotQuestionID= findAnswer(getExplainingMessageId(formatString(question), botQuestionId))
                if(isTalkingBot) {
                    return returnTalkingHeadAnswer(botType, answerByBotQuestionID)
                } else return answerByBotQuestionID?.answer
            }

        } else {
        }
    }
}
