import React, {useEffect, useRef, useState} from 'react';
import {store} from '../../../store/store';
import {setUserAnswers} from '../../../store/slices/user-slice';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import getDelay from '../../../helpers/getDelay';
import {getSurveyLink} from '../../../helpers/getSurveyLink';
import Questionnaire from './Questionnaire/Questionnaire';
import MessageWidgetAnna from '../AnnaTalking/MessageWidget';
import MessageWidgetMichael from '../MichaelTalking/MessageWidget';


const AdditionalQuestionsQuestion = (props) => {
    const {message, state, setState,scrollIntoView} = props
    const {botType} = useSelector((state) => state.user);

    const buttonDisabled = state.questionnaireFinished

    const [modalIsOpened, setModalIsOpened] = useState(false)

    const [show, setShow] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, botType==='machine-like' ? 100:getDelay(state.botLastMessage.message ||state.botLastMessage.payload.message))
    }, []);
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "start", inline: "nearest"
            });

        }
    }, [ref.current, show]);
    const [optionsShown, setOptionsShown] = useState(false)
    useEffect(()=>{
        scrollIntoView()
    }, [optionsShown])
    const renderMessageWidget = ()=>{
        if(botType === 'talking-head-f') {
            return  null
        } else
        if(botType === 'talking-head-m') {
            return  null
        }
    }
    useEffect(() => {
        // if(botType==='machine-like') {
            setOptionsShown(true)
        // }
    }, []);

    return (
       show ? <div className={'d-flex flex-column w-100'} ref={ref}>
           {message? message : renderMessageWidget()}
           {optionsShown ? <button
               className={`mt-10 button border border-1 border-opacity-25 border-dark rounded-1 button-white-bg mb-10`}
               style={{
                   width: 'fit-content',
                   marginLeft: '50px',
                   padding: '10px',
                   ...(buttonDisabled ? {
                       color: 'white',
                       backgroundColor: '#eee',
                       borderColor: '#eee',
                       borderOpacity: 0
                   } : {})
               }}
               disabled={buttonDisabled}
               onClick={() => setModalIsOpened(true)}
           >
               Go to questions
           </button> : null}
           <Questionnaire botType={botType} isOpened={modalIsOpened} setIsOpened={setModalIsOpened} setBotState={setState} botState={state}/>
       </div> : <></>
    );
};

export default AdditionalQuestionsQuestion;
