import React from 'react';
import Buttons from '../../wrappers/research-guide/Buttons';

const StepEight = ({setCurrentStep}) => {
    return (
        <div className={'pt-20 d-flex flex-column'}>
            <div>


            <h3 className={'mb-5'}>What should you consider when purchasing noise-cancelling headphones?</h3>

            <div className={'d-flex justify-content-between align-items-center'}>
                <div>
                    <p><span className={'text-decoration-underline'}><b>6. Foldability:</b></span></p>
                    <p><span className={'text-decoration-underline'}>Are the headphones foldable?</span></p>
                </div>
                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/foldable.jpeg'} className={'foldable-image'} />
            </div>
            <div className={'mt-30'}>
                <p><span className={'text-decoration-underline'}><b>7. Connectivity to other devices:</b></span></p>
                <p>Do the headphones have <span className={'text-decoration-underline'}>Android control</span> and/or <span className={'text-decoration-underline'}>iOS control</span> or both?
                    Do the headphones work with <span className={'text-decoration-underline'}>Google Assistant</span> and/or <span className={'text-decoration-underline'}>Amazon Alexa</span>?</p>
            </div>
            <div className={'d-flex flex-wrap align-items-center gap-2 w-100 justify-content-end mt-50'}>
                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/android.png'} className={'connectivity-image'} />
                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/ios.png'} className={'connectivity-image'} />
                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/google.png'} className={'connectivity-image'} />
                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/alexa.png'} className={'connectivity-image'} />

            </div>
            </div>
            <Buttons currentStep={8} setStep={setCurrentStep} link={'/chat-bot'} />
        </div>
    );
};

export default StepEight;
