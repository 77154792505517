import React, {useEffect, useRef, useState} from 'react';
import {store} from '../../../store/store';
import {setUserAnswers} from '../../../store/slices/user-slice';
import getDelay from '../../../helpers/getDelay';
import {useSelector} from 'react-redux';
import MessageWidgetAnna from '../AnnaTalking/MessageWidget';
import MessageWidgetMichael from '../MichaelTalking/MessageWidget';


const LastMessage = (props) => {
const {message, state, setState,payload} = props
    console.log('rendering message radio buttons')

    const options3 = [`I am happy with the recommended headphone and 
do NOT want to see other headphones before making
a selection and responding to the survey.`,
        `I would like to see other headphone models before 
making a selection and responding to the survey.`]
    const [show, setShow] = useState(false)
    const {botType} = useSelector((state) => state.user);

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, botType === 'machine-like' ? 100 : getDelay(state.botLastMessage.message ||state.botLastMessage.payload.message))
    }, []);
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "start", inline: "nearest"
            });

        }
    }, [ref.current, show]);
    const renderMessageWidget = ()=>{
        if(botType === 'talking-head-f') {
            return  null
        } else
        if(botType === 'talking-head-m') {
            return  null
        }
    }
    return (
        <div className={'d-flex flex-column w-100'}>
            {message? message : null}
            <div onChange={(e) => {
                store.dispatch(setUserAnswers({
                    ...state.userAnswers,
                    wantToSeeOtherHeadphones: e.target.value === options3[1]
                }));
                setState({
                    ...state,
                    userAnswers: {...state.userAnswers, wantToSeeOtherHeadphones: e.target.value === options3[1]}
                })

            }} className={'ml-50'} ref={ref}>
                {options3?.map((option, index) => (
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name={'lastMessage'} id={'lastMessage' + index}
                               value={option}/>
                        <label className="form-check-label" htmlFor={'lastMessage' + index}>
                            {option}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LastMessage;
