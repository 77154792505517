import React, {useEffect, useRef, useState} from 'react';
import ProductBot from '../../../components/product/ProductBot';
import getDelay from '../../../helpers/getDelay';
import {useSelector} from 'react-redux';
import MessageWidgetAnna from '../AnnaTalking/MessageWidget';
import MessageWidgetMichael from '../MichaelTalking/MessageWidget';
import {delayMessageByVideoDurationWithTimeout} from '../../../helpers/getVideoDuration';
import {createChatBotMessage} from 'react-chatbot-kit';
import {AnnaTalking} from '../AnnaTalking/AnnaTalking';

const Headphones = (props) => {
const {message, state, setState, payload, scrollIntoView} = props
    const [imageIsLoaded, setImageIsLoaded]=useState(false)

    const {productChoosedByBot: product} = state
    const [show, setShow] = useState(false)
    const {botType} = useSelector((state) => state.user);
    const AnnaTalkingBot = new AnnaTalking()
    const createMessage = (func) => {
        return createChatBotMessage(null, {
            widget: 'simpleMessage',
            payload: func(),
            withAvatar: false
        })
    }
    const createAndSetMessage = (func, fieldName, fieldValue)=>{
        console.log('id',func().id)
        const botMessage = createMessage(func);
        console.log(botMessage)
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, ...(func().id? {botMessageId: func().id}:{}) }],
                ...(fieldName && fieldValue !==null && fieldValue!==undefined ? {userAnswers: {...prev.userAnswers, [fieldName]:fieldValue}}:{})
            })
        })
    }
    const handleLastMessageOptions = () => {
        console.log('handleLastMessageOptions')
        const botMessage = createChatBotMessage(null,
            {
                widget: 'lastMessage',
                payload: AnnaTalkingBot.lastMessage(),
                withAvatar: false,

            }
        );

        setState((prev) => {
            return ({
                ...prev,
                type: AnnaTalkingBot.lastMessage().type,
                name: AnnaTalkingBot.lastMessage().name,
                messages: [...prev.messages, {...botMessage, botMessageId: AnnaTalkingBot.lastMessage().id}],
            })
        })

    }
    const waitForElement = (selector, callback) => {
        const interval = setInterval(() => {
            const element = document.querySelector(selector);
            if (element) {
                clearInterval(interval);
                callback();
            }
        }, 100); // Check every 100ms
    };
    const handleLastMessage = async () => {
        try {
            createAndSetMessage(AnnaTalkingBot.lastMessage)
                await delayMessageByVideoDurationWithTimeout(
                    `/assets/videos/${AnnaTalkingBot.lastMessage().video}.mp4`,
                    () => handleLastMessageOptions())

        }catch (e) {
console.log(e)
        }
    }



    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, botType==='machine-like' ? 100:getDelay(state.botLastMessage.message ||state.botLastMessage.payload.message))
    }, []);
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "start", inline: "nearest"
            });

        }
    }, [ref.current, show]);

    const [optionsShown, setOptionsShown] = useState(false)
    useEffect(()=>{
        scrollIntoView()
    }, [optionsShown])
    const renderMessageWidget = ()=>{
        if(botType === 'talking-head-f') {
            return  null
        } else
        if(botType === 'talking-head-m') {
            return  null
        }
    }
    useEffect(() => {
        // if(botType==='machine-like') {
            setOptionsShown(true)
        // }
    }, []);

    useEffect(() => {
        if(botType==='talking-head-f' && imageIsLoaded) {
            handleLastMessage()
        }
    }, [optionsShown, imageIsLoaded]);
    return (
        show ? <div className={'d-flex flex-column w-100 gap-5'} ref={ref}>
            {message? message : renderMessageWidget()}
            {optionsShown ? <ProductBot product={product} onLoad={()=>setImageIsLoaded(true)}/> : null}
        </div> : <></>
    );
};

export default Headphones;
