import React from 'react';
import ProductDescriptionInfo from './ProductDescriptionInfo';
import { Rating } from 'react-simple-star-rating'
// import Rating from './sub-components/ProductRating';
import {useSelector} from 'react-redux';

const ProductBot = ({product, onLoad}) => {
    const {group} = useSelector((state) => state.user);
    return (
        <div className="modal-body">
            <div className="row">
                <div className="col-md-1 col-sm-1 col-xs-2">
                    <div className="product-small-image-wrapper mt-15">
                        <img src={product.image} className='product-model-image bot-image' onLoad={()=> {
                            if(onLoad){
                                onLoad(true)
                        }
                        }}/>
                    </div>
                </div>
                <div className="col-md-9 col-sm-7 col-xs-6">
                    <div className="product-details-content quickview-content">
                        <div className="product-details-content ml-20">
                            <h2>{product.model[group]}</h2>
                            <div className="product-details-price">
                                <span>{'$' + product.price} </span>
                            </div>
                            <div className="pro-details-list product-description-wrapper product-bot">
                                <div className="product-anotherinfo-wrapper inBot">
                                    <ul>
                                        <li>
                                            <span>Weight:</span> {product.weight} g
                                        </li>
                                        <li>
                                            <span>Battery life:</span> {product.batteryLife[group]} h
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center gap-2">
                                                <div>Noise cancellation quality: </div>
                                                <Rating initialValue={Number(product.noiseCancellationQuality[group])} readonly={true} allowFraction={true} showTooltip={false} size={25} />
                                                <div>{product.noiseCancellationQuality[group]}</div>
                                            </div>
                                            {/* <span>Noise cancelling type:</span> {product.noiseCancellingType}{" "} */}
                                        </li>
                                        <li>
                                            <span>Bluetooth range:</span> {product.bluetoothRange} m
                                        </li>
                                        {/* <li>
                                            <span>Frequency Response:</span> {product.frequencyResponseStart}-{product.frequencyResponseEnd} Hz
                                        </li> */}
                                        {/* <li>
                                            <span>Charge time:</span> {product.chargeTime} h
                                        </li> */}
                                        {/* <li>
                                            <span>Foldable:</span> {product.foldable ? 'yes' : 'no'}
                                        </li> */}
                                        <li>
                                            <span>Android control:</span> {product.androidControl ? 'yes' : 'no'}
                                        </li>
                                        <li>
                                            <span>iOS control:</span> {product.iosControl ? 'yes' : 'no'}
                                        </li>
                                        {/* <li>
                                            <span>Google Assistant:</span> {product.googleAssistant ? 'yes' : 'no'}
                                        </li>
                                        <li>
                                            <span>Amazon Alexa:</span> {product.amazonAlexa ? 'yes' : 'no'}
                                        </li> */}
                                        {/* <li>
                                            <span>Warranty:</span> {product.warranty} months
                                        </li> */}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductBot;
