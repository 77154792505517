import React from 'react';
import {useNavigate} from 'react-router-dom';

const Buttons = ({currentStep, setStep, link, onNextClick}) => {
    const navigate = useNavigate()
    return (
        <div className={`buttons-wrapper container pt-4 pb-4 ${currentStep===8 ? 'margin' : ''}`}>
            {currentStep !== 0 &&
                <button className={'button-back'} onClick={() => setStep(currentStep - 1)}>Back</button>
            }
            <button className={`button-next ${currentStep===8 ? 'long-button':''}`} onClick={() => {
                if(onNextClick || link) {
                    if(onNextClick) {
                        onNextClick()
                    }
                    if(link) {
                        navigate(link)
                    }
                } else {
                    setStep(currentStep + 1)
                }

            }}>{currentStep===8 ? 'I’m ready to provide my headphone preferences' : 'Next'}</button>
        </div>
    );
};

export default Buttons;
