function extractName(inputString) {
    // Define the regular expression pattern
    const pattern = /(?:I am|I'm|my name is|my name's|call me|can call me|you can call me)\s+(\w+)/i;

    // Match the pattern in the input string
    const match = inputString.match(pattern);

    // Extract the name from the matched result
    const name = match ? match[1] : inputString;

    return name;
}

export default extractName
