import { getStringIncludesWord } from "./userQuestions";

export const detectBestUserAnswer = (answer) => {
  if (
    !(
      getStringIncludesWord(answer, "don't", 3) ||
      getStringIncludesWord(answer, "do not", 3) ||
      getStringIncludesWord(answer, "doesn't", 4) ||
      getStringIncludesWord(answer, "no matter", 5)
    ) &&
    (getStringIncludesWord(answer, "best", 3) ||
      getStringIncludesWord(answer, "high", 3) ||
      getStringIncludesWord(answer, "good", 3) ||
      getStringIncludesWord(answer, "great", 4) ||
      getStringIncludesWord(answer, "highest", 4))
  )
    return true;
};
