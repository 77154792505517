import { createChatBotMessage } from 'react-chatbot-kit';
import {MichaelTalking} from './MichaelTalking';
import FeatureFirst from '../widgets/FeatureFirst';
import FeatureSecond from '../widgets/FeatureSecond';
import Headphones from '../widgets/Headphones';
import LastMessage from '../widgets/LastMessage';
import MichaelTalkingAvatar from './MichaelTalkingAvatar';
import Frequency from '../widgets/Frequency';
import FinishFirst from '../widgets/FinishFirst';
import FinishSecond from '../widgets/FinishSecond';
import CustomBotMessage from './CustomBotMesage';
import MessageWidget from './MessageWidget';

const AnnaTalkingBot = new MichaelTalking()
const botMessage = createChatBotMessage('', {
    delay: 200,
    withAvatar: false,
    payload: AnnaTalkingBot.hello(),
    widget: 'simpleMessage'
})
const config = {
    initialMessages: [{...botMessage, botMessageId: 'hello'}],
    botName: 'Anna',
    customComponents: {
        botAvatar: (props, ...rest) => null,
        botChatMessage: (props, ...rest) => null,
    },

    widgets: [
        {widgetName: 'simpleMessage',
        widgetFunc:(props)=><MessageWidget {...props}/>,
            mapStateToProps: ['messages'],
        },


        {
            widgetName: 'feature1',
            widgetFunc: (props) => <FeatureFirst {...props} />,
        },
                {
            widgetName: 'feature2',
            widgetFunc: (props) => <FeatureSecond {...props} />,
            // mapStateToProps: ['messages'],
        },
        {
            widgetName: 'headphones',
            widgetFunc: (props) => <Headphones {...props} />,
            // mapStateToProps: ['messages'],
        },
        {
            widgetName: 'lastMessage',
            widgetFunc: (props) => <LastMessage {...props} />,
            // mapStateToProps: ['messages'],
        },
        {
            widgetName: 'frequencyResponseWidget',
            widgetFunc: (props) => <Frequency {...props} />,
        },
        {
            widgetName: 'finishFirstWidget',
            widgetFunc: (props) => <FinishFirst {...props} />,
        },
        {
            widgetName: 'finishSecondWidget',
            widgetFunc: (props) => <FinishSecond {...props} />,
        },


    ],
    state: {
        productChoosedByBot: null,
        userLastMessage: null,
        botLastMessage: null,
        messageToRepeat: null,
        type: '',
        name: '',
        disableInput: false,
        userAnswers: {
            name:null,
            batteryLife:null,
            // chargeTime: null,
            weight: null,
            // noiseCancellingType: null,
            noiseCancellationQuality: null,
            // foldable: null,
            bluetoothRange: null,
            // frequencyResponse: null,
            androidControl:null,
            // googleAssistant: null,
            iosControl: null,
            // amazonAlexa: null,
            // warranty: null,
            price: null,
            feature1: null,
            feature2: null,
            wantToSeeOtherHeadphones: null,
            joke: null
        },

    },

};

export default config;
