import products from '../data/products.json'
import features from '../pages/chatbot/features';
import defaultAllProducts from '../data/allProducts'
import camelCase from 'lodash.camelcase';

const maxBatteryLife = 60
const minBatteryLife = 22
const maxWeight = 440
const minWeight = 99
const maxBluetoothRange = 30
const minBluetoothRange = 9
// const maxChargeTime = 7
// const minChargeTime = 1.5
// const maxWarranty = 18
// const minWarranty = 12
const maxPrice = 375
const minPrice = 119
const minNoiseCancellationQuality = 0
const maxNoiseCancellationQuality = 5


const getFeatureWeight = (value, key, mostImportantFeatureFirst, mostImportantFeatureSecond) => {

    const featureKey = key === 'scoreFrequency' ? 'frequencyResponseEnd' : camelCase(key.replace('score', ''))
    if (featureKey === mostImportantFeatureFirst || featureKey === 'price') {
        return value * 3
    } else if (featureKey === mostImportantFeatureSecond) {
        return value * 2
    } else {
        return value
    }
}

export const setScore = (userPreferences, unbiasedGroup) => {
    const mostImportantFeatureFirst = features.find(el => el.value === userPreferences.feature1)?.name
    const mostImportantFeatureSecond = features.find(el => el.value === userPreferences.feature2)?.name
    const maxUserNoiseCancellationQuality =
      Number(userPreferences.noiseCancellationQuality) > 5
        ? 5
        : Number(userPreferences.noiseCancellationQuality);
    const allProducts = unbiasedGroup ? products.map((el) => {
        return ({
            ...el,
            model: el.model[unbiasedGroup],
            batteryLife: el.batteryLife[unbiasedGroup],
            weight: el.weight,
            noiseCancellationQuality: el.noiseCancellationQuality[unbiasedGroup],
        })
    }) : defaultAllProducts
    const productsWithFinalScore = allProducts.map((el) => {
        const newEl = {...el}

        if (userPreferences.batteryLife <= el.batteryLife) {
            newEl.scoreBatteryLife = 1;
        } else {
            newEl.scoreBatteryLife = 1 - ((Number(userPreferences.batteryLife) - el.batteryLife) / (maxBatteryLife - minBatteryLife));
        }
        if (Number(userPreferences.weight) >= el.weight) {
            newEl.scoreWeight = 1;
        } else {
            newEl.scoreWeight = 1 - ((el.weight - Number(userPreferences.weight)) / (maxWeight - minWeight));
        }

        // if (userPreferences.noiseCancellingType === el.noiseCancellingType) {
        //     newEl.scoreNoiseCancellingType = 1;
        // } else {
        //     newEl.scoreNoiseCancellingType = 0;
        // }

        if (userPreferences.bluetoothRange <= el.bluetoothRange) {
            newEl.scoreBluetoothRange = 1;
        } else {
            newEl.scoreBluetoothRange = 1 - ((userPreferences.bluetoothRange - el.bluetoothRange) / (maxBluetoothRange - minBluetoothRange));
        }

        // if (el.frequencyResponseEnd === 40000) {
        //     newEl.scoreFrequency = 1;
        // } else {
        //     if (userPreferences.frequencyResponse <= 20000) {
        //         newEl.scoreFrequency = 1;
        //     } else {
        //         newEl.scoreFrequency = 0;
        //     }
        // }


        // if (userPreferences.chargeTime >= el.chargeTime) {
        //     newEl.scoreChargeTime = 1;
        // } else {
        //     newEl.scoreChargeTime = 1 - ((el.chargeTime - userPreferences.chargeTime) / (maxChargeTime - minChargeTime));
        // }

        // if (userPreferences.foldable === el.foldable) {
        //     newEl.scoreFoldable = 1;
        // } else {
        //     newEl.scoreFoldable = 0;
        // }

        if (el.androidControl) {
            newEl.scoreAndroidControl = 1;
        } else {
            if (!userPreferences.androidControl) {
                newEl.scoreAndroidControl = 1;
            }
            if (userPreferences.androidControl) {
                newEl.scoreAndroidControl = 0;
            }
        }


        if (el.iosControl) {
            newEl.scoreIosControl = 1;
        } else {
            if (!userPreferences.iosControl) {
                newEl.scoreIosControl = 1;
            }
            if (userPreferences.iosControl) {
                newEl.scoreIosControl = 0;
            }
        }


        // if (el.googleAssistant)
        //     newEl.scoreGoogleAssistant = 1;
        // else {
        //     if (!userPreferences.googleAssistant) {
        //         newEl.scoreGoogleAssistant = 1;
        //     }
        //     if (userPreferences.googleAssistant) {
        //         newEl.scoreGoogleAssistant = 0;
        //     }
        // }


        // if (el.amazonAlexa) {
        //     newEl.scoreAmazonAlexa = 1;
        // } else {
        //     if (!userPreferences.amazonAlexa) {
        //         newEl.scoreAmazonAlexa = 1;
        //     }
        //     if (userPreferences.amazonAlexa) {
        //         newEl.scoreAmazonAlexa = 0;
        //     }
        // }


        // if (userPreferences.warranty <= el.warranty) {
        //     newEl.scoreWarranty = 1;
        // } else {
        //     newEl.scoreWarranty = 1 - ((userPreferences.warranty - el.warranty) / (maxWarranty - minWarranty));
        // }

        if (userPreferences.price >= el.price) {
            newEl.scorePrice = 1;
        } else {
            newEl.scorePrice = 1 - ((el.price - userPreferences.price) / (maxPrice - minPrice));
        }

        if (maxUserNoiseCancellationQuality <= el.noiseCancellationQuality) {
            newEl.scoreNoiseCancellationQuality = 1;
          } else {
            newEl.scoreNoiseCancellationQuality =
              1 - (maxUserNoiseCancellationQuality - el.noiseCancellationQuality) /
                (maxNoiseCancellationQuality - minNoiseCancellationQuality);
          }

        let finalScore = 0
        for (let key in newEl) {
            if (
                key === 'scoreBatteryLife' ||
                key === 'scoreWeight' ||
                key === 'scoreNoiseCancellationQuality' ||
                key === 'scoreBluetoothRange' ||
                // key === 'scoreFrequency' ||
                // key === 'scoreChargeTime' ||
                // key === 'scoreFoldable' ||
                key === 'scoreAndroidControl' ||
                key === 'scoreIosControl' ||
                // key === 'scoreGoogleAssistant' ||
                // key === 'scoreAmazonAlexa' ||
                // key === 'scoreWarranty' ||
                key === 'scorePrice'
            ) {

                finalScore += getFeatureWeight(newEl[key], key, mostImportantFeatureFirst, mostImportantFeatureSecond)
            }
        }


        return ({...newEl, finalScore, recommended: false})
    })
    const productsSortedByFinalScore = productsWithFinalScore?.sort(function (a, b) {
        let x = a.finalScore;
        let y = b.finalScore;
        let result;
        if (x > y) {

            result = -1;
        } else if (x < y) {
            result = 1;
        } else {
            result = 0;
        }

        return result;
    })
    // const bestProductByScoreAndPrice = productsSortedByFinalScore.find(el=>el.price-userPreferences.price<=5)
    // const productsToReturn = [bestProductByScoreAndPrice, ...productsSortedByFinalScore.filter(el => el.id !== bestProductByScoreAndPrice.id)]
    const productsToReturn = productsSortedByFinalScore
    return productsToReturn
}

const recommendHeadphone = (userPreferences, version,) => {
    const bestFromUnbiased = setScore(userPreferences, 'b0')[0];
    return {...products.find(el => el.id === bestFromUnbiased.id), finalScore: bestFromUnbiased.finalScore}
}


export default recommendHeadphone


