import React, {useEffect, useState} from 'react';
import Buttons from './Buttons';
import StepOne from '../../components/research-guide/StepOne';
import StepTwo from '../../components/research-guide/StepTwo';
import StepThree from '../../components/research-guide/StepThree';
import StepFour from '../../components/research-guide/StepFour';
import StepFive from '../../components/research-guide/StepFive';
import StepSeven from '../../components/research-guide/StepSeven';
import StepEight from '../../components/research-guide/StepEight';
import StepSix from '../../components/research-guide/StepSix';
import StepAgree from '../../components/research-guide/StepAgree';

const ResearchGuideWrapper = () => {
    const [currentStep, setCurrentStep] = useState(0)
    const [agree, setAgree] = useState(null)

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <StepAgree setCurrentStep={setCurrentStep} agree={agree} setAgree={setAgree}/>
            case 1:
                return <StepOne setCurrentStep={setCurrentStep} agree={agree} setAgree={setAgree}/>
            case 2:
                return <StepTwo/>
            case 3:
                return <StepThree/>
            case 4:
                return <StepFour/>
            case 5:
                return <StepFive/>
            case 6:
                return <StepSix/>
            case 7:
                return <StepSeven setCurrentStep={setCurrentStep}/>
            case 8:
                return <StepEight setCurrentStep={setCurrentStep}/>
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [currentStep])

    return (
        <div className={'research-wrapper'}>

            <div className={'d-flex flex-grow-1 '}>
                <div
                    className={`container d-flex flex-grow-1 ${currentStep !== 0 ? 'step-container' : ''} flex-column`}>


                    {renderStep()}
                    <div>
                        {currentStep !== 1 && currentStep !== 0 && currentStep !== 7 &&
                            <Buttons currentStep={currentStep} setStep={setCurrentStep}/>
                        }
                    </div>

                </div>
            </div>

        </div>
    );
};

export default ResearchGuideWrapper;
