import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import LayoutThree from '../../layouts/LayoutThree';

const ChatBotGreeting = ({setBotOpened}) => {
    const {botType} = useSelector((state) => state.user);

    const navigate = useNavigate()
    return (
        <div style={{maxHeight: '100vh'}}>
            <LayoutThree headerTop="visible">
                <div className={'d-flex justify-content-center flex-column align-items-center mt-4'}>
                    <h5 className={'ct-heading w-75 text-center'} style={{lineHeight: 1.5}}>{
                        botType === 'human-like' || botType === 'talking-head-f' || botType === 'talking-head-m' ?
                            `Hey, I'm ${botType === 'talking-head-f'|| botType==='human-like' ? 'Anna' : 'Michael'}. I'm Headphone-Tech's expert for 
noise-cancelling headphones, and I'm here to find you the 
product that best fits your needs. Ready to start?`
                            :
                            `Hello, this is Headphone-Tech's virtual advisor on 
noise-cancelling headphones. Based on your answers to a 
few questions, my algorithm will find you the product that 
best fits your needs`
                    }</h5>
                    <button className={`button-next long-button mt-2`} onClick={() => {
                        setBotOpened(true)
                    }}>
                        {botType === 'human-like' || botType === 'talking-head-f' || botType === 'talking-head-m' ? `I'm ready` : 'Start questions'}
                    </button>
                </div>


                <div className={'d-flex justify-content-center w-100 p-5'}>
                    {
                        botType === 'talking-head-f' ?
                            <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/Human_Like_f.jpg'}
                                 className={'bot-type-image'}/>
                            : botType === 'human-like' ?
                                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/Human_Like.jpg'}
                                     className={'headphones-image'} style={ {maxWidth:'80vw'}}/>:
                                // <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/Human_Like_m.jpg'}
                                //      className={'bot-type-image'}/>
                                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/Machine_Like.jpg'}
                                     className={'bot-type-image'} />

                    }
                </div>
            </LayoutThree>
        </div>
    );
};

export default ChatBotGreeting;
