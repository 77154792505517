import React, {useEffect} from 'react';
import {SimpleBot} from './SimpleBot';
const botConfig = new SimpleBot()
const messagesForInputDisabling = [
    botConfig.mostImportantFeature1Question().message,
    botConfig.mostImportantFeature1ResponseAnswer().message,
    botConfig.mostImportantFeature2Question().message,
    botConfig.mostImportantFeature2ResponseAnswer().message,
    botConfig.additionalQuestionsQuestion().message,
    botConfig.foundRecommended().message,
    botConfig.lastMessage().message,
    botConfig.finishFirst().message,
    botConfig.finishSecondOne().message,
    botConfig.finishSecondTwo().message,
]

const CustomBotMessage = (props) => {
    const inputElement = document.querySelector('.react-chatbot-kit-chat-input');
    const buttonElement = document.querySelector('.react-chatbot-kit-chat-btn-send');
    const disableInputElements = (shouldDisable) => {


        if (inputElement) {
            inputElement.disabled = shouldDisable;
            if(!shouldDisable) {
                inputElement.focus()
            }
        }

        if (buttonElement) {
            buttonElement.disabled = shouldDisable;
        }
    };


    useEffect(() => {
       if(messagesForInputDisabling.includes(props.message) ) {
           disableInputElements(true)
       } else {
           disableInputElements(false)
       }
    }, []);



    return (
        <div className="react-chatbot-kit-chat-bot-message-container">
            <div className="react-chatbot-kit-chat-bot-avatar-container">
                <img alt="simple-avatar"
                     src="/assets/img/research-guide/bot.png"
                     className="react-chatbot-kit-chat-bot-avatar-image"/>
            </div>
            <div bis_skin_checked="1" className="react-chatbot-kit-chat-bot-message">
                <span>{props.message}</span>
                <div className="react-chatbot-kit-chat-bot-message-arrow"></div>
            </div>
        </div>
    )
}


export default CustomBotMessage;
