import React, {lazy, Suspense, useEffect, useRef} from "react";
import ScrollToTop from "./helpers/scroll-top";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ResearchGuide from './pages/research-guide/ResearchGuide';
import {useDispatch, useSelector} from 'react-redux';
import {clearStore} from './store/slices/user-slice';
import PrivateRoute from './pages/PrivateRoute';
import ChatBotGreeting from './pages/chatbot/ChatBotGreeting';
// import ChatBot from './pages/chatbot/ChatBot';
// import AnnaTalkingBot from './pages/chatbot/AnnaTalking/AnnaTalkingBot';
// import SimpleBotPage from './pages/chatbot/SimpleBot/SimpleBotPage';
import ChatBotMain from './pages/chatbot/ChatBotMain';
import ChatBot from './pages/chatbot/ChatBot';

const HomeElectronics = lazy(() => import("./pages/home/HomeElectronics"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ProductFixedImage = lazy(() =>
    import("./pages/shop-product/ProductFixedImage")
);

const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));

const NotFound = lazy(() => import("./pages/other/NotFound"));


const App = () => {

    const userStore = useSelector((state) => state.user);
    const userStoreRef = useRef(userStore); // Create a ref for userStore to receive only the last updated user store
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(clearStore())

    }, []);

    const saveTheData = () => {

        const currentUserStore = userStoreRef.current;
        // console.log('Im saving the data', currentUserStore)
        if (currentUserStore) {

            if (navigator.sendBeacon) {
                if(currentUserStore?.profilicId && currentUserStore?.profilicId!=='') {
                    const jsonDataUser = JSON.stringify(currentUserStore);
                    const blobUserData = new Blob([jsonDataUser], {type: 'application/json'});
                    navigator.sendBeacon(`${process.env.REACT_APP_BASE_URL || ''}/create-user-data-row`, blobUserData);
                }

                console.log(JSON.stringify(currentUserStore))
                // console.log(currentUserStore?.profilicId, 'User data saved successfully before unloading (Beacon API)');

                if (currentUserStore?.messages?.length > 0) {
                    const jsonDataMessages = JSON.stringify({
                        messages: currentUserStore?.messages,
                        pageName: currentUserStore?.profilicId,

                        mostImportantFeature1: currentUserStore?.userAnswers?.feature1,
                        mostImportantFeature2: currentUserStore?.userAnswers?.feature2,
                        recommendedModel: currentUserStore?.product?.model?.[currentUserStore?.group || 'b0']
                    });
                    const blobMessages = new Blob([jsonDataMessages], {type: 'application/json'});
                    navigator.sendBeacon(`${process.env.REACT_APP_BASE_URL || ''}/create-page`, blobMessages);

                }


                // createDialogPage('First Dialog page', 'hello content', userStore?.messages)

            }
        }
    }

    useEffect(() => {
        // Update the ref value whenever userStore changes
        userStoreRef.current = userStore;
    }, [userStore]);
    useEffect(() => {
        window.addEventListener('beforeunload', saveTheData);
        return () => {
            window.removeEventListener('beforeunload', saveTheData)
        }
    }, []);

    return (<>
        <Router>
            <ScrollToTop>
                <Suspense
                    fallback={
                        <div className="flone-preloader-wrapper">
                            <div className="flone-preloader">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    }
                >
                    <Routes>
                        <Route element={<PrivateRoute/>}>
                            <Route
                                path={process.env.PUBLIC_URL + "/"}
                                element={<ResearchGuide/>}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/website"}
                                element={<HomeElectronics/>}
                            />
                            {/*Uncomment when panel not needed*/}
                            <Route
                                path={process.env.PUBLIC_URL + "/chat-bot"}
                                element={<ChatBotMain/>}
                            />
                            {/*Uncomment when panel not needed*/}


                            <Route
                                path={process.env.PUBLIC_URL + "/chat-bot-greeting"}
                                element={<ChatBotGreeting/>}
                            />

                            {/*Panel*/}
                           {/*<Route*/}
                           {/*     path={process.env.PUBLIC_URL + "/chat-bot"}*/}
                           {/*     element={<ChatBot/>}*/}
                           {/* >*/}
                           {/*     <Route*/}
                           {/*         path={"h"}*/}
                           {/*         element={<ChatBotMain/>}*/}
                           {/*     />*/}
                           {/*     <Route*/}
                           {/*         path={"m"}*/}
                           {/*         element={<ChatBotMain/>}*/}
                           {/*     />*/}

                           {/* </Route>*/}
                            {/*Panel*/}
                            <Route
                                path={process.env.PUBLIC_URL + "/shop-grid-standard"}
                                element={<ShopGridStandard/>}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/product/:id"}
                                element={<ProductFixedImage/>}
                            />

                            <Route
                                path={process.env.PUBLIC_URL + "/wishlist"}
                                element={<Wishlist/>}
                            />
                            <Route
                                path={process.env.PUBLIC_URL + "/compare"}
                                element={<Compare/>}
                            />


                            <Route path="*" element={<NotFound/>}/>
                        </Route>
                    </Routes>
                </Suspense>
            </ScrollToTop>
        </Router>
    </>);
};

export default App;
