import React from 'react'
import {Navigate, Outlet, useLocation} from 'react-router-dom'

import {useSelector} from 'react-redux';

const PrivateRoute = () => {
  const location = useLocation()

  const user = useSelector((state) => state.user);

  if((user?.profilicId==='' || !user?.profilicId) && location.pathname!=='/') {
    return <Navigate to="/" />
  }

  return <Outlet />
}

export default PrivateRoute
