import React, {useEffect, useRef, useState} from 'react';
import features from '../features';
import frequencies from '../frequencies';
import getDelay from '../../../helpers/getDelay';
import {useSelector} from 'react-redux';
import MessageWidgetAnna from '../AnnaTalking/MessageWidget';
import MessageWidgetMichael from '../MichaelTalking/MessageWidget';

const FeatureFirst = (props) => {
    const {message, state, setState, payload} = props
    const [show, setShow] = useState(false)
    const {botType} = useSelector((state) => state.user);

    useEffect(() => {
        setTimeout(() => setShow(true), botType==='machine-like' ? 100:getDelay(state.botLastMessage.message ||state.botLastMessage.payload.message))
    }, []);
    const options = frequencies.map(el => el.value)
    const handleCheck = (e) => {
        if (e.target.value === '20-20,000Hz') {
            setState({...state, userAnswers: {...state.userAnswers, frequencyResponse: 20000}})
        } else {
            setState({...state, userAnswers: {...state.userAnswers, frequencyResponse: 40000}})
        }
    }
    const ref = useRef(null)
    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "start", inline: "nearest"
            });

        }
    }, [ref.current, show]);
    const [optionsShown, setOptionsShown] = useState(false)
    const renderMessageWidget = ()=>{
        if(botType === 'talking-head-f') {
            return  <MessageWidgetAnna {...props} setOptionsShown={setOptionsShown}/>
        } else
        if(botType === 'talking-head-m') {
            return  <MessageWidgetMichael {...props} setOptionsShown={setOptionsShown}/>
        }
    }
    return (show ?
            <div className={'d-flex flex-column w-100'}>
                {message? message : renderMessageWidget()}
                <div onChange={handleCheck} className={'ml-50'} ref={ref}>
                    {optionsShown ? options?.map((option, index) => (
                        <div className="form-check" >
                            <input className="form-check-input" type="radio" name={'frequencyResponse'}
                                   id={'frequencyResponse' + index}
                                   value={option} disabled={!!state.userAnswers.frequencyResponse}/>
                            <label className="form-check-label" htmlFor={'frequencyResponse' + index}>
                                {option}
                            </label>
                        </div>
                    )):null}
                </div>
            </div> : <></>
    );
};

export default FeatureFirst;
