import React from 'react';
import {useSelector} from 'react-redux';

const StepFive = () => {
    return (
        <div className={'pt-20'}>
            <h3 className={'mb-5'}>What should you consider when purchasing noise-cancelling headphones?</h3>
            <div>
                <div className={'d-flex justify-content-between align-items-center'}>
                    <div>
                        <p><span className={'text-decoration-underline'}><b>1. Battery:</b></span></p>
                        <p><span className={'text-decoration-underline'}>Battery life:</span> The length of time a pair of headphones work on one single charge.</p>
                        {/* <p><span className={'text-decoration-underline'}>Battery charge time:</span> The duration that it takes to fully recharge the headphones.</p> */}
                    </div>
                    <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/battery.jpeg'} className={'battery-image'} />
                </div>

                <div className={'d-flex justify-content-between align-items-center mt-40'}>
                    <div className={'h-100'}>
                        <p className={''}><span className={'text-decoration-underline'}><b>2. Weight:</b></span></p>
                        <p>The weight of a pair of headphones.</p>
                    </div>
                    <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/weight.jpeg'} className={'weight-image'} />

                </div>

            </div>
        </div>
    );
};

export default StepFive;
