import PropTypes from "prop-types";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// import Rating from "./sub-components/ProductRating";
import { Rating } from 'react-simple-star-rating'
import {addToCart} from "../../store/slices/cart-slice";
import {addToWishlist} from "../../store/slices/wishlist-slice";
import {addToCompare} from "../../store/slices/compare-slice";
import {store} from '../../store/store';
import {setChosenProduct} from '../../store/slices/user-slice';
import {createDialogPage, createTheWholeUserRow} from '../../helpers/notion/createBasicUser';
import {Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {getSurveyLink} from '../../helpers/getSurveyLink';

const ProductDescriptionInfo = ({
                                    product,
                                    wishlistItem,
                                    compareItem,
                                    isInBot = true
                                }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [quantityCount, setQuantityCount] = useState(1);
    const userStore = useSelector((state) => state.user);

    const [modalShown, setModalShown] = useState(false)
    const handleChooseClick = async () => {
        setModalShown(true)

        store.dispatch(setChosenProduct(product));
    }
    const saveAnswersToDB = async () => {
        // await createTheWholeUserRow(userStore)
        // await createDialogPage(userStore.profilicId,
        //     userStore.messages,
        //     userStore?.userAnswers?.feature1,
        //     userStore?.userAnswers?.feature2,
        //     userStore?.product?.model?.[userStore?.group || 'b0'])

        // if(userStore.botType==='human-like') {
        //     // window.location.replace('https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_1YxqgrPLuCxrKxE')
        //     window.location.replace('https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_bmbhm8zwvEzUOXA')
        // } else {
        //     window.location.replace('https://uwaterloo.ca1.qualtrics.com/jfe/form/SV_6lMeTxcHgB0KTRQ')
        // }
        window.location.replace(getSurveyLink(userStore.botType))
    }
    return (
        <>
            <div className="product-details-content ml-70">
                <h2>{product?.model}</h2>
                <div className="product-details-price">
                    <span>{'$' + product.price} </span>
                </div>
                {
                    <div className="pro-details-rating-wrap">
                        <div className="pro-details-rating d-flex justify-content-between align-items-center gap-2">
                        <div>Noise cancellation quality: </div>
                            <Rating initialValue={Number(product.noiseCancellationQuality)} showTooltip={false} size={25} readonly={true} allowFraction={true} />
                            <div>{product.noiseCancellationQuality}</div>
                        </div>
                    </div>}
                <div className="pro-details-list product-description-wrapper">
                    <div className={`product-anotherinfo-wrapper ${isInBot ? 'inBot' : ''}`}>
                        <ul>
                            <li>
                                <span>Weight:</span> {product.weight} g
                            </li>
                            <li>
                                <span>Battery life:</span> {product.batteryLife} h
                            </li>
                            <li>
                                {/* <span>Noise cancelling type:</span> {product.noiseCancellingType}{" "} */}
                                <span>Noise cancellation quality:</span> {product.noiseCancellationQuality}
                            </li>
                            <li>
                                <span>Bluetooth range:</span> {product.bluetoothRange} m
                            </li>
                            {/* <li>
                                <span>Frequency Response:</span> {product.frequencyResponseStart}-{product.frequencyResponseEnd} Hz
                            </li> */}
                            {/* <li>
                                <span>Charge time:</span> {product.chargeTime} h
                            </li> */}
                            {/* <li>
                                <span>Foldable:</span> {product.foldable ? 'yes' : 'no'}
                            </li> */}
                            <li>
                                <span>Android control:</span> {product.androidControl ? 'yes' : 'no'}
                            </li>
                            <li>
                                <span>iOS control:</span> {product.iosControl ? 'yes' : 'no'}
                            </li>
                            {/* <li>
                                <span>Google Assistant:</span> {product.googleAssistant ? 'yes' : 'no'}
                            </li>
                            <li>
                                <span>Amazon Alexa:</span> {product.amazonAlexa ? 'yes' : 'no'}
                            </li>
                            <li>
                                <span>Warranty:</span> {product.warranty} months
                            </li> */}
                        </ul>
                    </div>
                    {/*<p>{product.shortDescription}</p>*/}
                </div>
                <div className="pro-details-quality">
                    <div className="cart-plus-minus">
                        <button
                            onClick={() =>
                                setQuantityCount(quantityCount > 1 ? quantityCount - 1 : 1)
                            }
                            className="dec qtybutton"
                        >
                            -
                        </button>
                        <input
                            className="cart-plus-minus-box"
                            type="text"
                            value={quantityCount}

                        />
                        <button
                            onClick={() =>
                                setQuantityCount(
                                    quantityCount + 1
                                )
                            }
                            className="inc qtybutton"
                        >
                            +
                        </button>
                    </div>
                    <div className="pro-details-cart btn-hover">

                        <button
                            onClick={handleChooseClick}
                        >
                            {userStore?.chosenProduct?.model === product.model ? 'Chosen' : 'Choose'}
                        </button>
                    </div>

                </div>
            </div>
            <Modal show={modalShown} className="modal-choose"
                   onBackdropClick={() => setModalShown(false)}>
                <Modal.Header closeButton onHide={() => setModalShown(false)}></Modal.Header>

                <div className="modal-body">
                    <div>Have you found your favorite headphone?
                        <div>If you click “Yes”, you will be forwarded to a survey to answer a
                            number of questions about your experience at our website.
                        </div>
                        <div>If you click “No”, you will be sent back to the list of all products.</div>
                    </div>
                    <div className={'d-flex justify-content-end gap-2 mt-50'}>
                        <Link to={'/shop-grid-standard'}>
                            <button className={'button-back'} onClick={() => {
                                setModalShown(false)
                            }}>No
                            </button>
                        </Link>
                        <button className={'button-next'} onClick={() => saveAnswersToDB()}>Yes</button>
                    </div>
                </div>

            </Modal></>
    );
};

ProductDescriptionInfo.propTypes = {
    compareItem: PropTypes.shape({}),
    product: PropTypes.shape({}),
    wishlistItem: PropTypes.shape({})
};

export default ProductDescriptionInfo;
