import PropTypes from "prop-types";
import clsx from "clsx";
import {Link} from "react-router-dom";

const FooterCopyright = ({footerLogo, spaceBottomClass, colorClass}) => {
    return (
        <div className={clsx("copyright", spaceBottomClass, colorClass)}>
            <div className="footer-logo logo-hm-10">

                <img alt="" src={process.env.PUBLIC_URL + footerLogo}/>

            </div>
            <p>
                &copy; {new Date().getFullYear()}{" "}
                <span
                >
          Headphone Tech
        </span>
                .<br/> All Rights Reserved
            </p>
        </div>
    );
};

FooterCopyright.propTypes = {
    footerLogo: PropTypes.string,
    spaceBottomClass: PropTypes.string,
    colorClass: PropTypes.string
};

export default FooterCopyright;
