import React, { useState } from 'react';
import QuestionsTable from './QuestionsTable';
import {useDispatch} from 'react-redux';
import {setAdditionalAnswers} from '../../../../store/slices/user-slice'; // Ensure you import the updated component
import './QuestionsTable.css'
import {Modal} from 'react-bootstrap';
const Questionnaire = ({ botType, isOpened, setIsOpened, setBotState, botState }) => {
    const dispatch = useDispatch()

    const botName = botType === 'machine-like' ? 'the virtual advisor' : 'Anna';

    const questions1 = [
        `I found interacting with ${botName} to be enjoyable`,
        `The actual process of interacting with ${botName} is pleasant`,
        `I had fun interacting with ${botName}`,
    ];

    const questionText2 = `I found ${botName}`;
    const questions2 = [
        `${questionText2} believable`,
        `${questionText2} fair`,
        `${questionText2} accurate`,
        `${questionText2} reliable`];

    const answers = [
        "Strongly disagree",
        "Disagree",
        "Somewhat disagree",
        "Neither agree nor disagree",
        "Somewhat agree",
        "Agree",
        "Strongly agree"
    ];

    // Global state to manage answers for all questions
    const [selectedOptions, setSelectedOptions] = useState({
        ...questions1.reduce((acc, question) => {
            acc[question] = null;
            return acc;
        }, {}),
        ...questions2.reduce((acc, question) => {
            acc[question] = null;
            return acc;
        }, {}),
    });

    const handleOptionChange = (question, answer) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [question]: answer,
        }));
    };

    const finishQuestionnaire = ()=>{
        setBotState({...botState, questionnaireFinished: true,
            userAnswers: {
            ...botState.userAnswers,
                interactionEnjoyable: selectedOptions[questions1[0]],
                processPleasant: selectedOptions[questions1[1]],
                interactionFun:selectedOptions[questions1[2]],
                botBelievable: selectedOptions[questions2[0]],
                botFair: selectedOptions[questions2[1]],
                botAccurate: selectedOptions[questions2[2]],
                botReliable:selectedOptions[questions2[3]]
            }

        })
        dispatch(setAdditionalAnswers({
            interactionEnjoyable: selectedOptions[questions1[0]],
            processPleasant: selectedOptions[questions1[1]],
            interactionFun:selectedOptions[questions1[2]],
            botBelievable: selectedOptions[questions2[0]],
            botFair: selectedOptions[questions2[1]],
            botAccurate: selectedOptions[questions2[2]],
            botReliable:selectedOptions[questions2[3]]
        }))
        setIsOpened(false)
    }
    const buttonDisabled = !Object.values(selectedOptions).every(el=>!!el)
    return (
        <Modal show={isOpened} className={`${isOpened ? 'openedQuestions' : 'closedQuestions'}`}
        >
        <div className="d-flex flex-column w-100">
            <QuestionsTable
                questionText={null}
                questions={questions1}
                answers={answers}
                selectedOptions={selectedOptions}
                onOptionChange={handleOptionChange}
            />
            <QuestionsTable
                textToRemove={questionText2}
                questionText={questionText2}
                questions={questions2}
                answers={answers}
                selectedOptions={selectedOptions}
                onOptionChange={handleOptionChange}
            />
            <button className={`button border border-1 border-opacity-25 border-dark rounded-1 button-white-bg mb-10`}
                    style={{width:  'calc(30% - 20px)',marginLeft: '20px', padding: '10px', backgroundColor: `${buttonDisabled ? '#ccc' : '#3D85C7'}` , color:'white' }}
                    disabled={buttonDisabled}
                    onClick={finishQuestionnaire}
            >
                Take me back to the conversation page
            </button>
        </div>
        </Modal>
    );
};

export default Questionnaire;
