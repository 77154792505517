import React, {useEffect, useRef, useState} from 'react';
import getDelay from '../../../helpers/getDelay';
const messagesVideoNames=['hello',
    'sorry',
    "batteryLifeQuestionClear",
    "weightQuestionClear",
    "noiseCancellationQualityQuestion",
    "bluetoothQuestionClear",
    "androidQuestion",
    "iosQuestion",
    "priceQuestionClear",
    "jokeQuestion",
    undefined]
const MichaelTalkingAvatar = ({video,  isFirstVideo, setVideoLoaded, setDisableInput, setOptionsShown}) => {

    const [shouldAutoplay, setShouldAutoplay] = useState(!!isFirstVideo)

    const videoRef = useRef(null);
    const inputElement = document.querySelector('.react-chatbot-kit-chat-input');
    const buttonElement = document.querySelector('.react-chatbot-kit-chat-btn-send');
    const disableInputElements = (shouldDisable) => {


        if (inputElement) {
            inputElement.disabled = shouldDisable;
            if(!shouldDisable) {
                inputElement.focus()
            }
        }

        if (buttonElement) {
            buttonElement.disabled = shouldDisable;
        }
    };

    useEffect(() => {
        disableInputElements(true)

        setDisableInput(true)
    }, []);

    useEffect(() => {
        const videoElement = videoRef.current;

        if (videoElement) {
            const handleEnded = () => {
                if(messagesVideoNames.includes(video)) {
                    disableInputElements(false)
                    setDisableInput(false);
                }
                if(setOptionsShown) {
                    setOptionsShown(true)
                }
            };

            videoElement.addEventListener('ended', handleEnded);

            return () => {
                videoElement.removeEventListener('ended', handleEnded);
            };
        }
    }, [setDisableInput]);

    useEffect(() => {
        if(!video) {
            setVideoLoaded(true)
        }
    }, []);

    return (
        <div className="react-chatbot-kit-chat-bot-avatar-container" bis_skin_checked="1">
            {video ? <video src={ `/assets/videos2/${video}.mp4`}
                            muted={false}
                            autoPlay={true}
                            className={'bot-video-avatar'}
                            ref={videoRef}
                            preload={'auto'}
                            playsInline={true}
                            onLoadedData={()=>{
                                setVideoLoaded(true)
                            }}
                /> :
                <img alt={'anna-avatar'}
                  src={process.env.PUBLIC_URL + '/assets/img/research-guide/anna.jpeg'}
                  className="react-chatbot-kit-chat-bot-avatar-image"
                onLoadedData={()=>{
                    setVideoLoaded(true)
                }}
                />}

        </div>
    );
};

export default MichaelTalkingAvatar;
