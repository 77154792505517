import {getStringIncludesWord} from './userQuestions';
import {formatString} from './parseSynonyms';

function detectUserUnderstanding(response) {
    const formattedResponse = formatString(response)
    if(formattedResponse.includes('name')) {
        return true
    }
    const keywords = ['confused',
        'information',
        'explain',
        'clarify',
        'no idea',
        `don't understand`,
        'do not understand',
        'do not know',
        `don't know`,
        'specify',
        'elaborate',
        'confused',
        'help',
    'tell', ];


    for (const keyword of keywords) {
        if ((formattedResponse === keyword) || formattedResponse.includes(keyword) || getStringIncludesWord(formattedResponse, keyword, 2) ) {
            return false;
            //user don't understand
            break;
        }
    }
    if ((getStringIncludesWord(formattedResponse, 'what', 1) ||
        getStringIncludesWord(formattedResponse, 'what is it', 1) ||
        getStringIncludesWord(formattedResponse, 'what\'s it', 1) ||
        getStringIncludesWord(formattedResponse, 'what do', 1) ||
        getStringIncludesWord(formattedResponse, 'what does', 1) ||
        getStringIncludesWord(formattedResponse, 'what does', 1) ||
        (getStringIncludesWord(formattedResponse, 'what', 1)
            && getStringIncludesWord(formattedResponse, 'mean', 1)
        ) ||
        getStringIncludesWord(formattedResponse, 'help', 1) ||
        getStringIncludesWord(formattedResponse, 'tell', 1) ||
        ((getStringIncludesWord(formattedResponse, 'could', 1) ||
                getStringIncludesWord(formattedResponse, 'can', 1)) &&
            getStringIncludesWord(formattedResponse, 'more', 1))
    )
    ) {
        //user don't understand

        return false
    }
    return true;
}

export default detectUserUnderstanding
