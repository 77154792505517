import React from 'react';
import {useSelector} from 'react-redux';

const StepThree = () => {
    const {botType} = useSelector((state) => state.user);
    return (
        <div className={'pt-20'}>
            <h3 className={'mb-5'}>What is in it for you?</h3>
            <div>
                <p>By participating in this study, you will receive the indicated compensation from Prolific.</p>
                <p>In addition, since we are highly interested in your feedback
                    on {botType === 'human-like' || botType === 'talking-head-f' || botType === 'talking-head-m' ? `${botType === 'talking-head-f' || botType === 'human-like' ? 'Anna':'Michael'}’s performance` : 'virtual advisor’s performance'}, you can make
                    an additional <b>USD 10!</b></p>
                <p>
                    Participants who provide clear and logical answers in terms of whether and how the virtual advisor’s
                    recommendation does or doesn’t fit their requirements will enter a draw to win one of our <b>thirty
                    $10
                    bonus prizes.</b>
                </p>
                <p>So please make sure you carefully read the instructions and <span
                    className={'text-decoration-underline'}>assess {botType === 'machine-like' ? 'virtual advisor’s' : `${botType === 'talking-head-f' ||botType === 'human-like' ? 'Anna':'Michael'}’s`} recommendation.</span>
                </p>

            </div>
        </div>
    );
};

export default StepThree;
