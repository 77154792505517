import axios from 'axios';

const { Client } = require('@notionhq/client');
const notion = new Client({ auth: process.env.REACT_APP_SECRET }); // Replace with your Notion integration secret





export const createTheWholeUserRow = async (userStore)=>{
    try {

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL||''}/create-user-data-row`, {...userStore});
    } catch (error) {
        console.error('Error creating Notion entry:', error);
    }
}
export const createDialogPage = async (pageName,  messages, mostImportantFeature1, mostImportantFeature2, recommendedModel)=>{


    try {

        const response = await axios.post(`${process.env.REACT_APP_BASE_URL||''}/create-page`, {
            pageName, messages,
            mostImportantFeature1,
            mostImportantFeature2,
            recommendedModel
        });
    } catch (error) {
        console.error('Error creating Notion entry:', error);
    }
}


