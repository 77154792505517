import React from 'react';
import {SimpleBot} from './SimpleBot'
import separateNumbersAndWords from '../../../helpers/separateNumbersAndWords';
import recommendHeadphone from '../../../helpers/recommendHeadphone';
import {useDispatch, useSelector} from 'react-redux';
import {store} from '../../../store/store';
import {setRecommendedProduct, setUserAnswers} from '../../../store/slices/user-slice';
import {answerTheQuestion, getStringIncludesWord} from '../../../helpers/userQuestions';
import levenstein from 'fast-levenshtein';
import {
    getFrequencyToShowInAnswer, handleBetweenWarranty,
    handleNo,
    handleWarrantyEighteen, handleWarrantyHigh, handleWarrantyLow,
    handleWarrantyTwelve,
    handleYes
} from '../../../helpers/parseSynonyms';
import { detectBestUserAnswer } from '../../../helpers/userAnswers';

const ActionProvider = ({createChatBotMessage, setState, children, state}) => {
    const Bot = new SimpleBot()
    const {version} = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const handleSorry = (questionToRepeat) => {
        const botMessage = createChatBotMessage(Bot.sorry().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage}],
            })
        })
        if (questionToRepeat) {
            setTimeout(() => questionToRepeat(), 300)
        }
    }

    const handleBatteryLifeQuestion = () => {
        const botMessage = createChatBotMessage(Bot.batteryLifeQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.batteryLifeQuestion().id}],
                delay: 0
            })
        })
        setTimeout(() => handleClarifyBotQuestion('batteryLifeQuestion'), 300)

    }
    const handleNumberOfQuestions = () => {
        const botMessage = createChatBotMessage(Bot.helpToFind().message, {withAvatar: false});

        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.helpToFind().id}],
            })
        })
    }

    const chooseBatteryLifeResponse = (response) => {
        let botMessage
        const batteryLife = separateNumbersAndWords(response)?.[0]
        if (Number(batteryLife) < 20) {
            botMessage = createChatBotMessage(Bot.batteryLifeResponseAnswer1().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.batteryLifeResponseAnswer1(batteryLife).id
                    }],
                    userAnswers: {...prev.userAnswers, batteryLife}
                })
            })
        } else if (Number(batteryLife) > 60) {
            botMessage = createChatBotMessage(Bot.batteryLifeResponseAnswer2().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.batteryLifeResponseAnswer2(batteryLife).id
                    }],
                    userAnswers: {...prev.userAnswers, batteryLife}
                })
            })
        } else if (Number(batteryLife) >= 20 && Number(batteryLife) <= 60) {
            botMessage = createChatBotMessage(Bot.batteryLifeResponseAnswer3().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.batteryLifeResponseAnswer3(batteryLife).id
                    }],
                    userAnswers: {...prev.userAnswers, batteryLife}
                })
            })

        } else {
            handleSorry(handleBatteryLifeQuestion)
        }


    }


    const handleWeightQuestion = () => {
        const botMessage = createChatBotMessage(Bot.weightQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.weightQuestion().id}],
            })
        })
        setTimeout(() => handleClarifyBotQuestion('weightQuestion'), 300)

    }

    const chooseWeightResponse = (response) => {
        let botMessage
        const weight = separateNumbersAndWords(response)?.[0]

        if (Number(weight) >= 0.1 && Number(weight) < 0.44) {
            botMessage = createChatBotMessage(Bot.weightResponseAnswer1(weight).message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.weightResponseAnswer1(weight).id
                    }],
                    userAnswers: {...prev.userAnswers, weight}
                })
            })
        } else if (Number(weight) >= 0.44 && Number(weight) < 99) {
            botMessage = createChatBotMessage(Bot.weightResponseAnswer2(weight).message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.weightResponseAnswer2(weight).id
                    }],
                    userAnswers: {...prev.userAnswers, weight}
                })
            })
        } else if (Number(weight) >= 99 && Number(weight) <= 440) {
            botMessage = createChatBotMessage(Bot.weightResponseAnswer3(weight).message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.weightResponseAnswer3(weight).id
                    }],
                    userAnswers: {...prev.userAnswers, weight}
                })
            })
        } else if (Number(weight) > 440) {
            botMessage = createChatBotMessage(Bot.weightResponseAnswer4(weight).message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.weightResponseAnswer4(weight).id
                    }],
                    userAnswers: {...prev.userAnswers, weight}
                })
            })
        } else {
            handleSorry(handleWeightQuestion)
        }


    }

    const handleNoiseCancellationQualityQuestion = () => {
        const botMessage = createChatBotMessage(Bot.noiseCancellationQualityQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.noiseCancellationQualityQuestion().id}],
            })
        })
    }

    const chooseNoiseCancellationQualityResponse = (response) => {
        let botMessage
        const noiseCancellationQuality = separateNumbersAndWords(response)?.[0]
        if (Number(noiseCancellationQuality) < 3.5) {
            botMessage = createChatBotMessage(Bot.noiseCancellationQualityResponseAnswer1().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.noiseCancellationQualityResponseAnswer1().id
                    }],
                    userAnswers: {...prev.userAnswers, noiseCancellationQuality}
                })
            })
        } else if (Number(noiseCancellationQuality) >= 3.5 && Number(noiseCancellationQuality) <= 5) {
            botMessage = createChatBotMessage(Bot.noiseCancellationQualityResponseAnswer3().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.noiseCancellationQualityResponseAnswer3().id
                    }],
                    userAnswers: {...prev.userAnswers, noiseCancellationQuality}
                })
            })
        } else if (Number(noiseCancellationQuality) > 5) {
            botMessage = createChatBotMessage(Bot.noiseCancellationQualityResponseAnswer2().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.noiseCancellationQualityResponseAnswer2().id
                    }],
                    userAnswers: {...prev.userAnswers, noiseCancellationQuality}
                })
            })
        } else if (!Number(response) && detectBestUserAnswer(response)) {
            botMessage = createChatBotMessage(Bot.noiseCancellationQualityResponseAnswer4().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.noiseCancellationQualityResponseAnswer4().id
                    }],
                    userAnswers: {...prev.userAnswers, noiseCancellationQuality: 5}
                })
            })
        } else {
            handleSorry(handleNoiseCancellationQualityQuestion)
        }


    }

    const handleNoiseCancellingQuestion = () => {
        const botMessage = createChatBotMessage(Bot.noiseCancellingQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.noiseCancellingQuestion().id}],
            })
        })
    }

    const chooseNoiseCancellingResponse = (response) => {
        let botMessage

        const validResponses = ['active', 'passive', 'adaptive']
        const validResponses2 = ['first', 'second', 'third']

        const allValidResponses = [...validResponses, ...validResponses2]

        // Join the valid responses into a regular expression pattern
        const validResponsesPattern = allValidResponses.map(response => `\\b${response}\\b`).join('|');
        const regex = new RegExp(validResponsesPattern, 'gi'); // Use 'gi' for case-insensitive matching
        let fixedResponse = []
        response.split(' ').forEach(el => {

            for (let i = 0; i < allValidResponses.length; i++) {

                if (levenstein.get(allValidResponses[i], el) <= 1) {
                    fixedResponse.push(allValidResponses[i])
                }
            }
        })
// Extract valid responses from the response string
        const extractedResponses = fixedResponse.join(' ').match(regex) || [];
        const noiseCancelling = extractedResponses?.[0] || ''
        if (validResponses.some(el => noiseCancelling.toLowerCase().includes(el))) {
            botMessage = createChatBotMessage(Bot.noiseCancellingResponseValid().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.noiseCancellingResponseValid(noiseCancelling).id
                    }],
                    userAnswers: {...prev.userAnswers, noiseCancellingType: noiseCancelling}
                })
            })
        } else if (validResponses2.some(el => noiseCancelling.toLowerCase().includes(el))) {
            if (noiseCancelling.toLowerCase().includes('first')) {
                botMessage = createChatBotMessage(Bot.noiseCancellingResponseValid().message, {withAvatar: false})
                setState((prev) => {
                    return ({
                        ...prev,
                        messages: [...prev.messages, {
                            ...botMessage,
                            botMessageId: Bot.noiseCancellingResponseValid('passive').id
                        }],
                        userAnswers: {...prev.userAnswers, noiseCancellingType: 'passive'}
                    })
                })
            } else if (noiseCancelling.toLowerCase().includes('second')) {
                botMessage = createChatBotMessage(Bot.noiseCancellingResponseValid().message, {withAvatar: false})
                setState((prev) => {
                    return ({
                        ...prev,
                        messages: [...prev.messages, {
                            ...botMessage,
                            botMessageId: Bot.noiseCancellingResponseValid('active').id
                        }],
                        userAnswers: {...prev.userAnswers, noiseCancellingType: 'active'}
                    })
                })
            } else if (noiseCancelling.toLowerCase().includes('third')) {
                botMessage = createChatBotMessage(Bot.noiseCancellingResponseValid().message, {withAvatar: false})
                setState((prev) => {
                    return ({
                        ...prev,
                        messages: [...prev.messages, {
                            ...botMessage,
                            botMessageId: Bot.noiseCancellingResponseValid('adaptive').id
                        }],
                        userAnswers: {...prev.userAnswers, noiseCancellingType: 'adaptive'}
                    })
                })
            }
        } else {
            botMessage = createChatBotMessage(Bot.noiseCancellingResponseNotValid().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.noiseCancellingResponseNotValid().id
                    }],
                })
            })
        }


    }
    const handleBluetoothRangeQuestion = () => {
        const botMessage = createChatBotMessage(Bot.bluetoothQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.bluetoothQuestion().id}],
            })
        })
        setTimeout(() => handleClarifyBotQuestion('bluetoothQuestion'), 300)

    }

    const chooseBluetoothRangeResponse = (response) => {
        let botMessage
        const bluetoothRange = separateNumbersAndWords(response)?.[0]

        if (Number(bluetoothRange) <= 30) {
            botMessage = createChatBotMessage(Bot.bluetoothResponseAnswer1(bluetoothRange).message, {withAvatar: false})

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.bluetoothResponseAnswer1(bluetoothRange).id
                    }],
                    userAnswers: {...prev.userAnswers, bluetoothRange}

                })
            })

        } else if (Number(bluetoothRange) > 30) {
            botMessage = createChatBotMessage(Bot.bluetoothResponseAnswer2(bluetoothRange).message, {withAvatar: false})

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.bluetoothResponseAnswer2(bluetoothRange).id
                    }],
                    userAnswers: {...prev.userAnswers, bluetoothRange}

                })
            })
        } else {
            handleSorry(handleBluetoothRangeQuestion)
        }


    }

    const handleFrequencyQuestion = () => {
        const botMessage = createChatBotMessage(
            Bot.frequencyQuestion().message,
            {
                widget: 'frequencyResponseWidget',
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.frequencyQuestion().id}],
            })
        })

    }

    const chooseFrequencyResponse = (response) => {
        let botMessage
        const frequency = response
        botMessage = createChatBotMessage(Bot.frequencyResponseValidAnswer(getFrequencyToShowInAnswer(frequency
        )).message, {withAvatar: false})
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {
                    ...botMessage,
                    botMessageId: Bot.frequencyResponseValidAnswer(getFrequencyToShowInAnswer(frequency)).id
                }],
                userAnswers: {
                    ...prev.userAnswers,
                    frequencyResponse: frequency
                }

            })
        })

    }

    const handleChargingTimeQuestion = () => {
        const botMessage = createChatBotMessage(Bot.chargingTimeQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.chargingTimeQuestion().id}],
            })
        })
        setTimeout(() => handleClarifyBotQuestion('chargingTimeQuestion'), 300)

    }

    const chooseChargingTimeResponse = (response) => {
        let botMessage
        const chargingTime = separateNumbersAndWords(response)?.[0]

        if (Number(chargingTime) >= 1.5 && Number(chargingTime) <= 7) {
            botMessage = createChatBotMessage(Bot.chargingTimeResponseValidAnswer(chargingTime).message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.chargingTimeResponseValidAnswer(chargingTime).id
                    }],
                    userAnswers: {...prev.userAnswers, chargeTime: chargingTime}

                })
            })
        } else {
            botMessage = createChatBotMessage(Bot.chargingTimeResponseNotValidAnswer(chargingTime).message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.chargingTimeResponseNotValidAnswer(chargingTime).id
                    }],

                })
            })
        }


    }

    const handleFoldableQuestion = () => {
        const botMessage = createChatBotMessage(Bot.foldableQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.foldableQuestion().id}],
            })
        })
    }

    const chooseFoldableResponse = (response) => {
        let botMessage

        if (handleYes(response)) {
            botMessage = createChatBotMessage(Bot.foldableResponseAnswer1().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.foldableResponseAnswer1().id}],
                    userAnswers: {...prev.userAnswers, foldable: true}
                })
            })
        } else if (handleNo(response)) {
            botMessage = createChatBotMessage(Bot.foldableResponseAnswer2().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.foldableResponseAnswer2().id}],
                    userAnswers: {...prev.userAnswers, foldable: false}
                })
            })
        } else {
            handleSorry(handleFoldableQuestion)
        }


    }

    const handleAndroidQuestion = () => {
        const botMessage = createChatBotMessage(Bot.androidQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.androidQuestion().id}],
            })
        })
    }

    const chooseAndroidResponse = (response) => {
        let botMessage

        if (handleYes(response)) {
            botMessage = createChatBotMessage(Bot.androidResponseAnswer1().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.androidResponseAnswer1().id}],
                    userAnswers: {...prev.userAnswers, androidControl: true}
                })
            })
        } else if (handleNo(response)) {
            botMessage = createChatBotMessage(Bot.androidResponseAnswer2().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.androidResponseAnswer2().id}],
                    userAnswers: {...prev.userAnswers, androidControl: false}
                })
            })
        } else {
            handleSorry(handleAndroidQuestion)
        }


    }

    const handleIosQuestion = () => {
        const botMessage = createChatBotMessage(Bot.iosQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.iosQuestion().id}],
            })
        })
    }

    const chooseIosResponse = (response) => {
        let botMessage

        if (handleYes(response)) {
            botMessage = createChatBotMessage(Bot.iosResponseAnswer1().message, {withAvatar: false})

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.iosResponseAnswer1().id}],
                    userAnswers: {...prev.userAnswers, iosControl: true}
                })
            })
        } else if (handleNo(response)) {
            botMessage = createChatBotMessage(Bot.iosResponseAnswer2().message, {withAvatar: false})

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.iosResponseAnswer2().id}],
                    userAnswers: {...prev.userAnswers, iosControl: false}
                })
            })
        } else {
            handleSorry(handleIosQuestion)
        }

    }

    const handleGoogleQuestion = () => {
        const botMessage = createChatBotMessage(Bot.googleQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.googleQuestion().id}],
            })
        })
    }

    const chooseGoogleResponse = (response) => {
        let botMessage

        if (handleYes(response)) {
            botMessage = createChatBotMessage(Bot.googleResponseAnswer1().message, {withAvatar: false})

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.googleResponseAnswer1().id}],
                    userAnswers: {...prev.userAnswers, googleAssistant: true}

                })
            })
        } else if (handleNo(response)) {
            botMessage = createChatBotMessage(Bot.googleResponseAnswer2().message, {withAvatar: false})

            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.googleResponseAnswer2().id}],
                    userAnswers: {...prev.userAnswers, googleAssistant: false}

                })
            })
        } else {
            handleSorry(handleGoogleQuestion)
        }

    }

    const handleAlexaQuestion = () => {
        const botMessage = createChatBotMessage(Bot.alexaQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.alexaQuestion().id}],
            })
        })
    }

    const chooseAlexaResponse = (response) => {
        let botMessage

        if (handleYes(response)) {
            botMessage = createChatBotMessage(Bot.alexaResponseAnswer1().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.alexaResponseAnswer1().id}],
                    userAnswers: {...prev.userAnswers, amazonAlexa: true}

                })
            })
        } else if (handleNo(response)) {
            botMessage = createChatBotMessage(Bot.alexaResponseAnswer2().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.alexaResponseAnswer2().id}],
                    userAnswers: {...prev.userAnswers, amazonAlexa: false}

                })
            })
        } else {
            handleSorry(handleAlexaQuestion)
        }


    }

    const handleWarrantyQuestion = () => {
        const botMessage = createChatBotMessage(Bot.warrantyQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.warrantyQuestion().id}],
            })
        })
    }

    const chooseWarrantyResponse = (response) => {
        let botMessage
        if (handleWarrantyLow(response) || handleWarrantyHigh(response)) {
            botMessage = createChatBotMessage(Bot.warrantyLowerClear().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                    }],
                })
            })
        }
        else if (handleBetweenWarranty(response)) {
            handleClarifyBotQuestion('warrantyBetween')
        }
        else if (handleWarrantyTwelve(response)) {
            botMessage = createChatBotMessage(Bot.warrantyResponseValidAnswer().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.warrantyResponseValidAnswer(12).id
                    }],
                    userAnswers: {...prev.userAnswers, warranty: 12}
                })
            })
        } else if (handleWarrantyEighteen(response)) {
            botMessage = createChatBotMessage(Bot.warrantyResponseValidAnswer().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.warrantyResponseValidAnswer(18).id
                    }],
                    userAnswers: {...prev.userAnswers, warranty: 18}
                })
            })
        } else {
            botMessage = createChatBotMessage(Bot.warrantyResponseNotValidAnswer().message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.warrantyResponseNotValidAnswer().id
                    }],
                })
            })
        }

    }

    const handlePriceQuestion = () => {
        const botMessage = createChatBotMessage(Bot.priceQuestion().message, {withAvatar: false});
        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.priceQuestion().id}],
            })
        })
        setTimeout(() => {
            handleClarifyBotQuestion('priceQuestion2')
            setTimeout(() => handleClarifyBotQuestion('priceQuestion'), 300)

        }, 300)


    }

    const choosePriceResponse = (response) => {
        let botMessage

        let price = separateNumbersAndWords(response)?.[0]
        if (getStringIncludesWord(response, 'minimum', 1) ||
            getStringIncludesWord(response, 'low', 1) ||
            getStringIncludesWord(response, 'lowest', 1) ||
            getStringIncludesWord(response, 'lower', 1) ||
            getStringIncludesWord(response, 'cheapest', 1) ||
            getStringIncludesWord(response, 'cheap', 1) ||
            getStringIncludesWord(response, 'cheaper', 1)
        ) {
            botMessage = createChatBotMessage(Bot.priceResponseValidAnswer('the cheaper the better').message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.priceResponseValidAnswer('the cheaper the better').id
                    }],
                    userAnswers: {...prev.userAnswers, price: 119}
                })
            })
        } else if (Number(price) >= 119 && Number(price) <= 375) {
            botMessage = createChatBotMessage(Bot.priceResponseValidAnswer(price).message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.priceResponseValidAnswer(price).id
                    }],
                    userAnswers: {...prev.userAnswers, price}
                })
            })
        } else if (Number(price) <= 119) {

            botMessage = createChatBotMessage(Bot.priceResponseValidAnswer(price).message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.priceResponseValidAnswer(price).id
                    }],
                    userAnswers: {...prev.userAnswers, price: 119}
                })
            })
        } else if (Number(price) >= 375) {

            botMessage = createChatBotMessage(Bot.priceResponseValidAnswer(price).message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.priceResponseValidAnswer(price).id
                    }],
                    userAnswers: {...prev.userAnswers, price: 375}
                })
            })
        } else {

            botMessage = createChatBotMessage(Bot.priceResponseNotValidAnswer(price).message, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {
                        ...botMessage,
                        botMessageId: Bot.priceResponseNotValidAnswer(price).id
                    }],
                })
            })
        }
    }


    const handleFeature1Question = () => {
        const botMessage = createChatBotMessage(
            Bot.mostImportantFeature1Question().message,
            {
                widget: 'feature1',
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                type: Bot.mostImportantFeature1Question().type,
                name: Bot.mostImportantFeature1Question().name,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.mostImportantFeature1Question().id}],
            })
        })
    }
    const chooseFeature1Response = () => {
        const
            botMessage = createChatBotMessage(Bot.mostImportantFeature1ResponseAnswer().message, {withAvatar: false})


        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {
                    ...botMessage,
                    botMessageId: Bot.mostImportantFeature1ResponseAnswer().id
                }],
            })
        })
    }

    const handleFeature2Question = () => {
        const botMessage = createChatBotMessage(
            Bot.mostImportantFeature2Question().message,
            {
                widget: 'feature2',
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                type: Bot.mostImportantFeature2Question().type,
                name: Bot.mostImportantFeature2Question().name,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.mostImportantFeature2Question().id}],
            })
        })
    }
    const chooseFeature2Response = () => {
        const
            botMessage = createChatBotMessage(Bot.mostImportantFeature2ResponseAnswer().message, {withAvatar: false})


        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {
                    ...botMessage,
                    botMessageId: Bot.mostImportantFeature2ResponseAnswer().id
                }],
            })
        })
    }

    const handleThanks = () => {
        const
            botMessage = createChatBotMessage(Bot.thanks().message, {withAvatar: false})


        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.thanks().id}],
            })
        })
    }


    const handleAdditionalQuestionsQuestions = () => {
        const botMessage = createChatBotMessage(Bot.additionalQuestionsQuestion().message, {
            widget: 'additionalQuestionsWidget', withAvatar: false
        })

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.additionalQuestionsQuestion().id}],
                })

            })
        }
    }

    const handleRecommend = () => {

        const product = recommendHeadphone(children.props.children.props.state.userAnswers, version)

        dispatch(setRecommendedProduct(product))
        dispatch(setUserAnswers(children.props.children.props.state.userAnswers))
        const botMessage = createChatBotMessage(
            Bot.foundRecommended(product).message,
            {
                widget: 'headphones',
                withAvatar:false
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                type: Bot.foundRecommended().type,
                name: Bot.foundRecommended().name,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.foundRecommended().id}],
                productChoosedByBot: product
            })
        })


    }

    const handleJobComplete = () => {
        const
            botMessage = createChatBotMessage(Bot.jobComplete().message, {withAvatar: false})


        setState((prev) => {
            return ({
                ...prev,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.jobComplete().id}],
            })
        })
        dispatch(setUserAnswers(children.props.children.props.state.userAnswers));

    }
    const handleLastMessage = () => {
        const botMessage = createChatBotMessage(
            Bot.lastMessage().message,
            {
                widget: 'lastMessage',
                withAvatar: false
            }
        );

        setState((prev) => {
            return ({
                ...prev,
                type: Bot.lastMessage().type,
                name: Bot.lastMessage().name,
                messages: [...prev.messages, {...botMessage, botMessageId: Bot.lastMessage().id}],
            })
        })

    }

    const handleAnswerTheQuestion = (message, lastQuestion) => {
        const answer = answerTheQuestion(message, 'machine-like', lastQuestion?.botMessageId)

        if (answer) {
            const botMessage = createChatBotMessage(answer, {withAvatar: false})
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage}],
                })
            })
            if (lastQuestion) {
                setTimeout(() => {
                    if (lastQuestion?.botMessageId.includes('Clear')) {
                        switch (lastQuestion?.botMessageId) {
                            case 'batteryLifeQuestionClear':
                                setTimeout(() => handleBatteryLifeQuestion(), 300);
                                break;
                            case 'weightQuestionClear' :
                                setTimeout(() => handleWeightQuestion(), 300);
                                break;
                            case 'bluetoothQuestionClear' :
                                setTimeout(() => handleBluetoothRangeQuestion(), 300);
                                break;
                            case 'frequencyQuestionClear' :
                                setTimeout(() => handleFrequencyQuestion(), 300);
                                break;
                            case 'chargingTimeQuestionClear' :
                                setTimeout(() => handleChargingTimeQuestion(), 300);
                                break;
                            case 'priceQuestionClear' :
                                setTimeout(() => handlePriceQuestion(), 300);
                                break;
                        }
                    } else {
                        const botLastMessage = createChatBotMessage(lastQuestion.message)
                        setState((prev) => {
                            return ({
                                ...prev,
                                messages: [...prev.messages, {
                                    ...botLastMessage,
                                    botMessageId: lastQuestion.botMessageId
                                }],
                            })
                        })
                    }

                }, 600)

            }
        } else {
            handleSorry()
        }

    }
    const handleClarifyBotQuestion = (questionIdToClarify) => {
        const botMessage = createChatBotMessage(Bot[`${questionIdToClarify}Clear`], {withAvatar: false})

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: `${questionIdToClarify}Clear`}],
                })

            })
        }

    }

    const setUserLastMessage = (userLastMessage) => {
        setState((prev) => {
            return ({
                ...prev,
                userLastMessage,
            })
        })
    }

    const setBotLastMessage = (botLastMessage) => {
        setState((prev) => {
            return ({
                ...prev,
                botLastMessage,
            })
        })
    }
    const setMessageToRepeat = (messageToRepeat) => {
        setState((prev) => {
            return ({
                ...prev,
                messageToRepeat,
            })
        })
    }
    const handleFinishFirst = () => {
        const botMessage = createChatBotMessage(Bot.finishFirst().message, {
            widget: 'finishFirstWidget', withAvatar: false
        })

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.finishFirst().id}],
                })

            })
        }
    }
    const handleFinishSecondOne = () => {
        const botMessage = createChatBotMessage(Bot.finishSecondOne().message, {
            withAvatar: false
        })

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.finishSecondOne().id}],
                })

            })
        }
    }
    const handleFinishSecondTwo = () => {
        const botMessage = createChatBotMessage(Bot.finishSecondTwo().message, {
            widget: 'finishSecondWidget', withAvatar: false
        })

        if (botMessage) {
            setState((prev) => {
                return ({
                    ...prev,
                    messages: [...prev.messages, {...botMessage, botMessageId: Bot.finishSecondTwo().id}],
                })

            })
        }
    }

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handleFinishFirst,
                        handleFinishSecondOne,
                        handleFinishSecondTwo,
                        handleNumberOfQuestions,
                        handleBatteryLifeQuestion,
                        chooseBatteryLifeResponse,
                        handleWeightQuestion,
                        chooseWeightResponse,
                        handleNoiseCancellingQuestion,
                        chooseNoiseCancellingResponse,
                        handleBluetoothRangeQuestion,
                        chooseBluetoothRangeResponse,
                        handleFrequencyQuestion,
                        chooseFrequencyResponse,
                        handleChargingTimeQuestion,
                        chooseChargingTimeResponse,
                        handleFoldableQuestion,
                        chooseFoldableResponse,
                        handleAndroidQuestion,
                        chooseAndroidResponse,
                        handleIosQuestion,
                        chooseIosResponse,
                        handleGoogleQuestion,
                        chooseGoogleResponse,
                        handleAlexaQuestion,
                        chooseAlexaResponse,
                        handleWarrantyQuestion,
                        chooseWarrantyResponse,
                        handlePriceQuestion,
                        choosePriceResponse,
                        handleFeature1Question,
                        chooseFeature1Response,
                        handleFeature2Question,
                        chooseFeature2Response,
                        handleThanks,
                        handleAdditionalQuestionsQuestions,
                        handleRecommend,
                        handleJobComplete,
                        handleLastMessage,
                        setUserLastMessage,
                        setBotLastMessage,
                        setMessageToRepeat,
                        handleAnswerTheQuestion,
                        handleSorry,
                        handleNoiseCancellationQualityQuestion,
                        chooseNoiseCancellationQualityResponse
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;
