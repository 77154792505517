import React, {useState} from 'react';
import Buttons from '../../wrappers/research-guide/Buttons';
import {setUser} from '../../store/slices/user-slice';
import {useDispatch} from 'react-redux';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const StepAgree = ({setCurrentStep, agree, setAgree}) => {
    const [showError, setShowError] = useState(false)

    const onChangeAgree = (event) => {
        if (event.target.value === 'I agree to participate') {
            setAgree(true)
            setShowError(false)
        } else {
            setAgree(false)
            setShowError(false)


        }

    }
    const onNextClick = () => {

        if (agree !== null) {
            if (agree) {

                setCurrentStep(1)
            } else {
                window.location.replace('https://app.prolific.com/submissions/complete?cc=C9D82383')

            }
        } else {
            setShowError(true)
        }
    }
    const getLinkForAgree = () => {
        if (agree !== null) {
            if (agree) {
                return null
            } else {
                return '/not-agree'
            }
        } else {
            return '/not-agree'
        }

    }
    return (
        <div className={'flex-grow-1 d-flex flex-column mt-40'}>
            <div className={'form'}>
                <div className={'consent'}>
                    <p><b>Study Title:</b> The Use of Virtual Advisors by Online Shopping Websites</p>
                    <p><b>Faculty Researchers:</b> Sepideh Ebrahimi, PhD, School of Accounting and Finance,
                        University of Waterloo, ON, Canada. Phone: <a href={'callto:1-519-888-4567'}>1-519-888-4567</a>,
                        Email:
                        <a href={'mailto:sepideh.ebrahimi@uwaterloo.ca'}> sepideh.ebrahimi@uwaterloo.ca</a></p>
                    <p>Christian Matt, PhD, Institute of Information Systems, University of Bern, Bern,
                        Switzerland. Phone: <a href={'callto:41-31-684-4967'}>41-31-684-4967</a>, Email: <a
                            href={'mailto:christian.matt@unibe.ch'}>christian.matt@unibe.ch</a></p>
                    <p>This letter explains what the study is about, possible risks and benefits, and your
                        rights as a research participant. You may print/save a copy for your records. If you
                        do not understand something in the letter, please ask one of the investigators
                        before consenting to participate.</p>
                    <h5>What is the study about?</h5>
                    <p>You are invited to participate in a research study about the use of virtual advisors
                        by online shopping websites. We are conducting this study as a part of a research
                        study that aims to find out how users interact with virtual advisors on online
                        shopping websites when purchasing or researching products. This research will
                        result in guidelines for design of virtual advisors.</p>
                    <h5>What does participation involve?</h5>
                    <p>You will first receive a brief tutorial on noise-cancelling headphones and their
                        important characteristics. Subsequently, you will use a virtual advisor that will ask
                        you about your preferences for noise-cancelling headphones should you decide to
                        purchase one. It is worth noting that these questions intend to study how individuals
                        interact with the virtual advisor and the extent to which it fulfills their requirements.</p>
                    <p>Subsequently, the virtual advisor will provide you with a recommendation according
                        to your solicited preferences. Then, you will be asked to complete an anonymous
                        questionnaire. In the survey, you will be asked to respond to closed-ended
                        questions about your experience of using the virtual advisor as well as a few open-
                        ended questions about your experience. You will also be asked some demographic
                        information about your age, gender, and education as these characteristics have
                        been shown to impact individuals’ interactions with computers. We expect this
                        process to take approximately 15 minutes.</p>
                    <h5>Who may participate in the study?</h5>
                    <p>To participate in the study, you need to be at least 18 years of age. In addition,
                        experience with and a minimum of once-per-month frequency of online shopping is
                        required for participating in this study.</p>
                    <h5>Is participation in the study voluntary?</h5>
                    <p>Your participation in this study is voluntary. You may decline to answer any
                        question(s) you prefer not to answer by skipping to the next question and may stop
                        participating at any time by not submitting your responses and closing your web
                        browser.</p>
                    <h5>Will I receive anything for participating in the study?</h5>
                    <p>If you participate in this study, you will receive 5 USD from prolific in appreciation
                        for your time spent on the study.</p>
                    <h5>What are the possible benefits of the study?</h5>
                    <p>Participation in this study will not provide any personal benefit to you. Data from
                        this study may contribute to discovering ways to improve virtual advisors and their
                        recommendations.</p>
                    <h5>What are the risks associated with the study?</h5>
                    <p>There are no known or anticipated risks associated with participation in this study. If
                        a question or the topic makes you uncomfortable, you can choose not to provide a
                        response.</p>
                    <h5>How is data collected, stored, and protected?</h5>
                    <p>This anonymous questionnaire will not ask for your name or other identifying
                        information. Removing your responses later is not possible because the researchers
                        will have no way of identifying which responses are yours. This survey is hosted by
                        Qualtrics, which has multiple safeguards in place, although no Internet transmission
                        is ever fully secure. Collected data will be securely stored on password-protected
                        computers for one year after publishing results to be used as a reference for any
                        prospective inquiries.</p>
                    <h5>Has the study received ethics clearance?</h5>
                    <p>This study has been reviewed and received ethics clearance through a University of
                        Waterloo Research Ethics Board (REB 12345). If you have questions for the Board,
                        contact the Office of Research Ethics, toll-free at <a
                            href={'callto:1-833-643-2379'}>1-833-643-2379</a> (Canada and
                        USA), <a href={'callto:1-519-888-4440'}>1-519-888-4440</a>, or <a
                            href={'mailto:reb@uwaterloo.ca'}>reb@uwaterloo.ca</a>.</p>
                    <h5>Who should I contact if I have questions regarding my participation in the
                        study?</h5>
                    <p>If you have any questions about this study or need more information before you
                        decide whether to participate, please contact one of the researchers named above.
                        By agreeing to participate in the study you are not waiving your legal rights or
                        releasing the investigator(s) or involved institution(s) from their legal and
                        professional responsibilities.</p>
                    <h5>Consent:</h5>
                    <p>I consent to participate in “The Use of Virtual Advisors by Electronic Commerce
                        Websites” conducted by Dr. Sepideh Ebrahimi and Dr. Christian Matt. I have
                        understood the nature of this project and wish to participate. I am not waiving any
                        of my legal rights clicking on the “Yes” button below and by doing so I agree to
                        participate in the study.</p>
                </div>
                <p>
                    I consent to participate in "The Use of Virtual Advisors by Electronic Commerce Websites" conducted
                    by Dr. Sepiden Ebrahimi and Dr. Christian Matt. I have understood the nature of this project and
                    wish to participate. I am not waiving any of my legal rights clicking on the "I agree to
                    participate" button below and by doing so I agree to participate in the study.
                </p>

                <div onChange={onChangeAgree} className={'mt-3'}>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="agree" id="agree1"
                               value={'I agree to participate'}/>
                        <label className="form-check-label" htmlFor="agree1">
                            I agree to participate
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="agree" id="agree2"
                               value={'I do NOT agree to participate'}
                        />
                        <label className="form-check-label" htmlFor="agree2">
                            I do NOT agree to participate
                        </label>
                    </div>
                </div>
            </div>
            {showError && <div className={'error mt-3 text-danger'}>Please choose on of the options</div>}

            {/*ToDo: Chage to the real link*/}
            <Buttons currentStep={0} setStep={setCurrentStep} onNextClick={onNextClick}/>
        </div>
    );
};

export default StepAgree;
