/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import getDelay from '../../../helpers/getDelay';
import { Anna } from './Anna';
const botConfig = new Anna();

const messagesForInputDisabling = [
    botConfig.batteryLifeQuestion().message,
    botConfig.weightQuestion().message,
    botConfig.bluetoothQuestion().message,
    botConfig.priceQuestion2Clear,
    botConfig.priceQuestion().message,
    botConfig.sorry().message,
    botConfig.mostImportantFeature1Question().message,
    botConfig.mostImportantFeature1ResponseAnswer().message,
    botConfig.mostImportantFeature2Question().message,
    botConfig.mostImportantFeature2ResponseAnswer().message,
    botConfig.foundRecommended().message,
    botConfig.lastMessage().message,
    botConfig.finishFirst().message,
    botConfig.finishSecondOne().message,
    botConfig.finishSecondTwo().message,
];

const CustomBotMessage = (props) => {
    const [showMessage, setShowMessage] = useState(false);
    const inputRef = useRef(null);
    const buttonRef = useRef(null);

    const forceRepaint = () => {
        // Safari-specific trick to force a repaint/reflow
        if (inputRef.current) {
            inputRef.current.style.display = 'none';
            inputRef.current.offsetHeight; // Trigger a reflow
            inputRef.current.style.display = 'block';
        }
    };
    const disableInputElements = (shouldDisable) => {
        if (inputRef.current) {
            inputRef.current.disabled = shouldDisable;
            if (!shouldDisable) {
                inputRef.current.focus();
            }
        }

        if (buttonRef.current) {
            buttonRef.current.disabled = shouldDisable;
        }
        if (shouldDisable) {
            forceRepaint();
        }
    };
    useEffect(() => {
        // For typing animation
        setTimeout(() => {
            setShowMessage(true);
        }, getDelay(props.message));
    }, [props.message]);

    useEffect(() => {
        const inputElement = document.querySelector('.react-chatbot-kit-chat-input');
        const buttonElement = document.querySelector('.react-chatbot-kit-chat-btn-send');
        // Assign to refs
        inputRef.current = inputElement;
        buttonRef.current = buttonElement;
        if (!showMessage) {
            disableInputElements(true);
        } else if (messagesForInputDisabling.includes(props.message)) {
            disableInputElements(true);
        } else {
            disableInputElements(false);
        }
    }, [showMessage, props.message]);



    return (
        <div className="react-chatbot-kit-chat-bot-message">
            {showMessage ? <span>{props.message}</span> : props.loader}
            <div className="react-chatbot-kit-chat-bot-message-arrow"></div>
        </div>
    );
};

export default CustomBotMessage;
