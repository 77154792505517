import React, {useEffect, useRef} from 'react';
import last from 'lodash.last'
import {useNavigate} from 'react-router-dom';
import {store} from '../../../store/store';
import {setMessages, setUserAnswers} from '../../../store/slices/user-slice';
import {isQuestion} from '../../../helpers/userQuestions';
import {useSelector} from 'react-redux';
import finishFirst from '../widgets/FinishFirst';


const MessageParser = ({children, actions}) => {
    const navigate = useNavigate()
    const messagesStoreRef = useRef(children.props.state.messages); // Create a ref for userStore to receive only the last updated user store
    const userStore = useSelector((state) => state.user);

    const saveTheData = () => {
        const currentUserStore = messagesStoreRef.current;
        if (currentUserStore) {

            if (navigator.sendBeacon) {

                const jsonDataMessages = JSON.stringify({
                    messages: currentUserStore,
                    pageName: userStore?.profilicId,
                    mostImportantFeature1: children?.props?.state?.userAnswers?.feature1,
                    mostImportantFeature2: children?.props?.state?.userAnswers?.feature2,
                    recommendedModel: children?.props?.state?.productChoosedByBot?.model?.[userStore?.group || 'b0']
                });
                const blobMessages = new Blob([jsonDataMessages], {type: 'application/json'});
                navigator.sendBeacon(`${process.env.REACT_APP_BASE_URL || ''}/create-page`, blobMessages);

            }
        }
    }

    useEffect(() => {
        // Update the ref value whenever userStore changes
        messagesStoreRef.current = children.props.state.messages;
    }, [children.props.state.messages]);
    useEffect(() => {
        window.addEventListener('beforeunload', saveTheData);
        return () => {
            window.removeEventListener('beforeunload', saveTheData)
        }
    }, []);
    useEffect(() => {
        actions.setBotLastMessage(last(children.props?.state?.messages?.filter(el => el?.type === 'bot' && !!el?.botMessageId)))
        actions.setUserLastMessage(last(children.props?.state?.messages?.filter(el => el?.type === 'user')))
    }, [children.props.state.messages.length])
    useEffect(() => {
        if (children.props.state.userAnswers) {
            store.dispatch(setUserAnswers({...children.props.state.userAnswers}));
        }

    }, [children.props.state.userAnswers]);
    const timeoutFunc = async (func, timeout = 0) => {
        setTimeout(func, timeout)
    }
    const {botLastMessage} = children.props.state;
    useEffect(() => {
        const handleBotMessages = async () => {
            if (children.props.state.messages.length === 1) {
                actions.handleNumberOfQuestions()
            }

            if (botLastMessage?.botMessageId) {

                switch (botLastMessage.botMessageId) {

                    case 'helpToFind':
                        await timeoutFunc(() => actions.handleBatteryLifeQuestion());
                        break;
                    case 'batteryLifeResponseAnswer1':
                    case 'batteryLifeResponseAnswer2':
                    case 'batteryLifeResponseAnswer3':
                        await timeoutFunc(() => actions.handleWeightQuestion());
                        break;
                    case 'weightResponseAnswer1':
                    case 'weightResponseAnswer2':
                    case 'weightResponseAnswer3':
                    case 'weightResponseAnswer4':
                        await timeoutFunc(() => actions.handleNoiseCancellationQualityQuestion());
                        // await timeoutFunc(() => actions.handleNoiseCancellingQuestion());
                        break;
                    case 'noiseCancellationQualityResponseAnswer1':
                    case 'noiseCancellationQualityResponseAnswer2':
                    case 'noiseCancellationQualityResponseAnswer3':
                    case 'noiseCancellationQualityResponseAnswer4':
                        await timeoutFunc(() => actions.handleBluetoothRangeQuestion());
                        break;
                    // case 'noiseCancellingResponseValid':
                    //     await timeoutFunc(() => actions.handleBluetoothRangeQuestion());
                    //     break;
                    case 'bluetoothResponseAnswer1':
                    case 'bluetoothResponseAnswer2':
                        //     await timeoutFunc(() => actions.handleFrequencyQuestion());
                        //     break;
                        // case 'frequencyResponseValidAnswer':
                        //     await timeoutFunc(() => actions.handleChargingTimeQuestion());
                        //     break;

                        // case 'chargingTimeResponseValidAnswer':
                        //     await timeoutFunc(() => actions.handleFoldableQuestion());
                        //     break;

                        // case 'foldableResponseAnswer1':
                        // case 'foldableResponseAnswer2':
                        await timeoutFunc(() => actions.handleAndroidQuestion());
                        break;
                    case 'androidResponseAnswer1':
                    case 'androidResponseAnswer2':
                        await timeoutFunc(() => actions.handleIosQuestion());
                        break;
                    case 'iosResponseAnswer1':
                    case 'iosResponseAnswer2':
                        //     await timeoutFunc(() => actions.handleGoogleQuestion());
                        //     break;
                        // case 'googleResponseAnswer1':
                        // case 'googleResponseAnswer2':
                        //     await timeoutFunc(() => actions.handleAlexaQuestion());
                        //     break;
                        // case 'alexaResponseAnswer1':
                        // case 'alexaResponseAnswer2':
                        //     await timeoutFunc(() => actions.handleWarrantyQuestion());
                        //     break;

                        // case 'warrantyResponseValidAnswer':
                        await timeoutFunc(() => actions.handlePriceQuestion());
                        break;
                    case 'priceResponseValidAnswer':
                        await timeoutFunc(() => actions.handleFeature1Question());
                        break;
                    case 'mostImportantFeature1ResponseAnswer':
                        await timeoutFunc(() => actions.handleFeature2Question());
                        break;
                    case 'mostImportantFeature2ResponseAnswer':
                        //uncomment this if additionalQuestions needed
                        // await timeoutFunc(() => actions.handleAdditionalQuestionsQuestions());
                        //comment this VV if additionalQuestions needed
                        await timeoutFunc(() => actions.handleRecommend());
                        break;
                    //comment this ^^ if additionalQuestions needed

                    // case 'thanks':
                    //     await timeoutFunc(() => actions.handleRecommend());
                    //     break;
                    case 'foundRecommended':
                        await timeoutFunc(() => actions.handleLastMessage());
                        break;

                    default:
                        break;
                }
            }
        };

        handleBotMessages();

        // Other useEffect dependencies
    }, [botLastMessage]);


    useEffect(() => {
        if (!!children.props.state.userAnswers.feature1) {
            actions.chooseFeature1Response()
        }

    }, [children.props.state.userAnswers.feature1])

    useEffect(() => {
        if (!!children.props.state.userAnswers.feature2) {
            actions.chooseFeature2Response()
        }

    }, [children.props.state.userAnswers.feature2])

    useEffect(() => {
        if (!!children.props.state.userAnswers.frequencyResponse) {
            actions.chooseFrequencyResponse(children.props.state.userAnswers.frequencyResponse)
        }

    }, [children.props.state.userAnswers.frequencyResponse])
    useEffect(() => {
        if (children.props.state.questionnaireFinished) {
            timeoutFunc(() => actions.handleRecommend());
        }

    }, [children.props.state.questionnaireFinished])


    useEffect(() => {
        store.dispatch(setUserAnswers({
            ...children.props.state.userAnswers,
            wantToSeeOtherHeadphones: children.props.state.userAnswers.wantToSeeOtherHeadphones
        }));
        if (children.props.state.userAnswers.wantToSeeOtherHeadphones !== null) {

            if (children.props.state.messages.length !== 1 || children.props.state.messages.length !== 0) {
                store.dispatch(setMessages(children.props.state.messages))

            }

            if (children.props.state.userAnswers.wantToSeeOtherHeadphones) {
                actions.handleFinishSecondOne()
                setTimeout(actions.handleFinishSecondTwo, 500)
            } else {
                actions.handleFinishFirst()
            }
        }

    }, [children.props.state.userAnswers.wantToSeeOtherHeadphones])

    const parse = (message) => {
        if (isQuestion(message)) {
            return actions.handleAnswerTheQuestion(message, botLastMessage)
        } else {

            switch (botLastMessage.botMessageId) {
                case 'batteryLifeQuestion':
                case 'batteryLifeQuestionClear': {
                    return actions.chooseBatteryLifeResponse(message)
                    break;
                }
                case 'weightQuestion':
                case 'weightQuestionClear': {
                    return actions.chooseWeightResponse(message)
                    break;
                }
                case "noiseCancellationQualityQuestion":
                    return actions.chooseNoiseCancellationQualityResponse(message)
                    break;
                // case 'noiseCancellingQuestion':
                // case 'noiseCancellingResponseNotValid': {
                //     return actions.chooseNoiseCancellingResponse(message)
                //     break;
                // }
                case 'bluetoothQuestion':
                case 'bluetoothQuestionClear': {
                    return actions.chooseBluetoothRangeResponse(message)
                    break;
                }
                case 'frequencyQuestion':
                case 'frequencyQuestionClear':
                case 'frequencyResponseNotValidAnswer': {
                    return actions.chooseFrequencyResponse(message)
                    break;
                }
                case 'chargingTimeQuestion':
                case 'chargingTimeQuestionClear':
                case 'chargingTimeResponseNotValidAnswer': {
                    return actions.chooseChargingTimeResponse(message)
                    break;
                }

                case 'foldableQuestion': {
                    return actions.chooseFoldableResponse(message)
                    break;
                }

                case 'androidQuestion': {
                    return actions.chooseAndroidResponse(message)
                    break;
                }
                case 'iosQuestion': {
                    return actions.chooseIosResponse(message)
                    break;
                }
                case 'googleQuestion': {
                    return actions.chooseGoogleResponse(message)
                    break;
                }
                case 'alexaQuestion': {
                    return actions.chooseAlexaResponse(message)
                    break;
                }
                case 'warrantyQuestion':
                case 'warrantyResponseNotValidAnswer':
                case 'warrantyBetweenClear': {
                    return actions.chooseWarrantyResponse(message)
                    break;
                }
                case 'priceQuestion':
                case 'priceQuestionClear':
                case 'priceResponseNotValidAnswer': {
                    return actions.choosePriceResponse(message)
                    break;
                }
                default: {
                    return actions.handleSorry()
                    break;
                }

            }
        }
    };
    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions
                });
            })}
        </div>
    );
};

export default MessageParser;
