import PropTypes from "prop-types";
import clsx from "clsx";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import {useLocation} from 'react-router-dom';

const HeaderThree = ({
                         layout,
                         headerPaddingClass,
                         headerPositionClass,
                         headerBgClass
                     }) => {
    const location = useLocation()
    return (
        <>

            <header
                className={clsx("header-area border border-opacity-50 border-end", headerBgClass, headerPositionClass)}>

                <div
                    className={clsx(
                        headerPaddingClass,
                    )}
                >
                    <div className={layout === "container-fluid" ? layout : "container"}>
                        <div className="row mt-sm-3 mt-3 mt-md-3 mt-lg-0 mb-3 mb-lg-0">
                            <div
                                className="col-12 text-center align-items-center justify-content-center flex-row d-flex">
                                {/* header logo */}
                                <Logo imageUrl="/assets/img/logo/logo-white.png" logoClass="logo-center mt-1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {location.pathname === '/shop-grid-standard' && <div className={'bg-gray-3 '}>
                <div className={'container text-center pt-3 pb-3'}>
                    Please choose the headphone that best matches your
                    requirements.
                </div>
            </div>}
        </>
    );
};

HeaderThree.propTypes = {
    headerPaddingClass: PropTypes.string,
    headerPositionClass: PropTypes.string,
    layout: PropTypes.string,
};

export default HeaderThree;
