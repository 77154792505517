const getVideoDuration = async (videoSrc) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = videoSrc;

        video.addEventListener('loadedmetadata', () => {
            resolve(video.duration);
        });

        video.addEventListener('error', (event) => {
            reject(event);
        });
    });
};
export const delayMessageByVideoDurationWithTimeout = async (videoSrc, callback) => {
    try {
        const duration = await getVideoDuration(videoSrc);
        setTimeout(()=>callback(), duration * 1000); // duration is in seconds, convert to milliseconds
    } catch (error) {
        console.error('Error getting video duration:', videoSrc, error);
    }
}
export default getVideoDuration
