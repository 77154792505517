import React, {useEffect, useRef, useState} from 'react';
import features from '../features';
import getDelay from '../../../helpers/getDelay';
import {useSelector} from 'react-redux';
import MessageWidgetAnna from '../AnnaTalking/MessageWidget';
import MessageWidgetMichael from '../MichaelTalking/MessageWidget';


const FeatureSecond = (props) => {
    const {message, state, setState, payload, scrollIntoView} = props
    const options1 = features.map(el => el.value)
    const [show, setShow] = useState(false)
    const {botType} = useSelector((state) => state.user);

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, botType==='machine-like' ? 100: getDelay(state.botLastMessage.message ||state.botLastMessage.payload.message))
    }, []);
    const ref = useRef(null)

    useEffect(() => {
     if (ref.current) {
         ref.current.scrollIntoView({
             behavior: "smooth",
             block: "start", inline: "nearest"
         });

     }
    }, [ref.current, show]);
    const [optionsShown, setOptionsShown] = useState(false)
    useEffect(()=>{
        scrollIntoView()
    }, [optionsShown])
    const renderMessageWidget = ()=>{
        if(botType === 'talking-head-f') {
            return  null
        } else
        if(botType === 'talking-head-m') {
            return  null
        }
    }
    useEffect(() => {
        // if(botType==='machine-like') {
            setOptionsShown(true)
        // }
    }, []);
    return (

        show ?
            <div className={'d-flex flex-column w-100'}>
                {message? message : renderMessageWidget()}
                <div onChange={(e) => setState({
                    ...state,
                    userAnswers: {...state.userAnswers, feature2: e.target.value}
                })}
                     className={'ml-50'}  ref={ref}>
                    {optionsShown ? options1.filter(el => el !== state.userAnswers.feature1)?.map((option, index) => (
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name={'feature2'}
                                   id={'feature2' + index}
                                   value={option} disabled={!!state.userAnswers.feature2}/>
                            <label className="form-check-label" htmlFor={'feature2' + index}>
                                {option}
                            </label>
                        </div>
                    )) : null}
                </div>
            </div> : <></>

    );
};

export default FeatureSecond;
