import separateNumbersAndWords from './separateNumbersAndWords';

export const formatString = (string) => {
    return string.toLowerCase().trim().replace(/\s+/g, ' ')
}
export const handleNo = (answer) => {
    const input = formatString(answer)
    return input.includes('no')
        || input === 'n'
        || input.includes('nah')
        || input.includes(`don't think so`)
        || input.includes(`never`)
        || input.includes(`i don't`)
        || input.includes(`nope`)
        || input.includes(`negative`)
        || input.includes(`never`)
        || input.includes(`nay`)
}
export const handleYes = (answer) => {
    const input = formatString(answer)
    return input.includes('yes')
        || input === 'y'
        || (input.includes('sure') && !input.includes('not sure'))
        || input.includes(`i'd like that`)
        || input.includes('I would like that')
        || input.includes('why not')
        || input.includes('sounds good')
        || input.includes('that would be good')
        || input.includes('yep')
        || input.includes('yea')
        || input.includes('yup')
        || input.includes('yeah')
        || input.includes('positive')
        || input.includes('of course')
        || input.includes('sure')
        || input.includes('ok')
        || input.includes('okay')
}
export const handleFirst = () => {

}
export const handleSecond = () => {

}
export const handleThird = () => {

}
export const handleWarrantyLow=(response)=>{
    const warranty = separateNumbersAndWords(response)?.[0]
    return warranty<12
}
export const handleWarrantyHigh=(response)=>{
    const warranty = separateNumbersAndWords(response)?.[0]
    return warranty>18
}
export const handleWarrantyTwelve = (response) => {
    const warranty = separateNumbersAndWords(response)?.[0]

    return (
        (response.includes('one year')
            || response.includes('1 year')
            || response.includes('a year')
        )
        && !response.includes('half')
    ) || warranty === 12 ||
        response.includes('first')

}
export const handleBetweenWarranty = (response)=>{
    const warranty = separateNumbersAndWords(response)?.[0]
    return warranty>12&&warranty<18
}
export const handleWarrantyEighteen = (response) => {
    const warranty = separateNumbersAndWords(response)?.[0]

    return warranty === 18
        || response.includes('second')
        || response.includes('1.5 year')
        || response.includes('1,5 year')
        ||
        (
            (response.includes('1 year')
                || response.includes('one year')
            ) && response.includes('half')
        )
}


export const getFrequencyToShowInAnswer = (frequency,
                                           // fourtyResponses, twentyResponses
) => {

    if(frequency===20000){
        return '20Hz-20,000Hz'
    } else {
        return '4Hz-40,000Hz'
    }


}
