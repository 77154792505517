import PropTypes from "prop-types";
import clsx from "clsx";
import {Link} from "react-router-dom";

const Logo = ({imageUrl, logoClass}) => {
    return (
        <div className={clsx(logoClass)}>

            <img alt="" src={process.env.PUBLIC_URL + imageUrl}/>

        </div>
    );
};

Logo.propTypes = {
    imageUrl: PropTypes.string,
    logoClass: PropTypes.string
};

export default Logo;
