import wordsToNumbers from 'words-to-numbers';

function separateNumbersAndWords(inputString) {
    const numbers = inputString
        .replace(/,/g, '') // Remove commas for easy parsing
        .match(/[\d.]+|[a-zA-Z]+/g) // Match sequences of digits or words
        .map(token => {
            if (/^\d+(\.\d+)?$/.test(token)) {
                return parseFloat(token);
            } else {
                const num = wordsToNumbers(token);
                return isNaN(num) ? token : num;
            }
        })
        .filter(token => typeof token === 'number');

    return numbers;
}
export default separateNumbersAndWords
