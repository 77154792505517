import React from 'react';
import Buttons from '../../wrappers/research-guide/Buttons';

const StepSeven = ({setCurrentStep}) => {
    return (
        <div>
        <div className={'pt-20'}>
            <h3 className={'mb-5'}>What should you consider when purchasing noise-cancelling headphones?</h3>
                <div className={'d-flex justify-content-between align-items-center'}>
                    <div>
                <p><span className={'text-decoration-underline'}><b>4. Bluetooth range:</b></span></p>
                <p>How far a pair of headphones hold connection to other devices (e.g., phone, computer, tablet).</p>
                    </div>
                    <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/bluetooth-range.jpeg'} className={'bluetooth-image'} />

                </div>
                <div className={'d-flex flex-wrap align-items-center justify-content-between mt-30'}>
                <div>
                <p><span className={'text-decoration-underline'}><b>5. Connectivity to other devices:</b></span></p>
                <p>
                    Do the headphones have <span className={'text-decoration-underline'}>Android control</span> and/or <span className={'text-decoration-underline'}>iOS control</span> or both?
                    {/* Do the headphones work with <span className={'text-decoration-underline'}>Google Assistant</span> and/or <span className={'text-decoration-underline'}>Amazon Alexa</span>? */}
                    </p>
            </div>
            <div className={'d-flex flex-wrap align-items-baseline gap-4 justify-content-end'}>
                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/android.png'} className={'connectivity-image'} />
                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/ios.png'} className={'connectivity-image'} />
                {/* <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/google.png'} className={'connectivity-image'} />
                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/alexa.png'} className={'connectivity-image'} /> */}

            </div>
            </div>
            {/* <div className={'d-flex justify-content-between mt-40'}>
                <div>
                <p><span className={'text-decoration-underline'}><b>5. Frequency response:</b></span></p>
                <p>The range of frequencies or musical tones a headphone can reproduce.</p>
                </div>
                <img loading={'eager'} alt="" src={process.env.PUBLIC_URL + '/assets/img/research-guide/frequency-response.jpeg'} className={'frequency-image'} />

            </div> */}
            </div>
            <Buttons currentStep={8} setStep={setCurrentStep} link={'/chat-bot'} />
            </div>
    );
};

export default StepSeven;
