const features = [
    {value: 'Battery life',name:'batteryLife' },
    {value: 'Weight of headphone',name: 'weight'},
    {value: 'Noise cancellation quality', name: "noiseCancellationQuality"},
    // {value: 'Type of noise cancellation',name: 'noiseCancellingType'},
    {value: 'Bluetooth range',name: 'bluetoothRange'},
    // {value: 'Warranty',name: 'warranty'},
    // {value: 'Frequency response range',name: 'frequencyResponseEnd'},
    // {value: 'Time to fully recharge',name: 'chargeTime'},
    // {value: 'Foldability',name: 'foldable'},
    {value: 'Android control',name: 'androidControl'},
    {value: 'iOS control',name: 'iosControl'},
    // {value: 'Working with Google Assistant',name: 'googleAssistant'},
    // {value: 'Working with Amazon Alexa',name: 'amazonAlexa'},
    // {value: 'Price',name: 'price'},
]

export default features
