import React, {useEffect, useRef} from 'react';
import last from 'lodash.last'
import {store} from '../../../store/store';
import {setMessages, setUserAnswers} from '../../../store/slices/user-slice';
import {isQuestion} from '../../../helpers/userQuestions';
import {useSelector} from 'react-redux';
import getDelay from '../../../helpers/getDelay';
import getVideoDuration, {delayMessageByVideoDurationWithTimeout} from '../../../helpers/getVideoDuration';


const MessageParser = (props) => {
    const {children, actions} = props
    const messagesStoreRef = useRef(children.props.state.messages); // Create a ref for userStore to receive only the last updated user store
    const userStore = useSelector((state) => state.user);

    const saveTheData = () => {
        const currentUserStore = messagesStoreRef.current;
        if (currentUserStore) {

            if (navigator.sendBeacon) {
                const jsonDataMessages = JSON.stringify({
                    messages: currentUserStore,
                    pageName: userStore?.profilicId,
                    mostImportantFeature1: children?.props?.state?.userAnswers?.feature1,
                    mostImportantFeature2: children?.props?.state?.userAnswers?.feature2,
                    recommendedModel: children?.props?.state?.productChoosedByBot?.model?.[userStore?.group || 'b0']
                });
                const blobMessages = new Blob([jsonDataMessages], {type: 'application/json'});
                navigator.sendBeacon(`${process.env.REACT_APP_BASE_URL||''}/create-page`, blobMessages);


                // createDialogPage('First Dialog page', 'hello content', userStore?.messages)

            }
        }
    }

    useEffect(() => {
        // Update the ref value whenever userStore changes
        messagesStoreRef.current = children.props.state.messages;
    }, [children.props.state.messages]);
    useEffect(() => {
        window.addEventListener('beforeunload', saveTheData);
        return () => {
            window.removeEventListener('beforeunload', saveTheData)
        }
    }, []);
    useEffect(() => {
        actions.setBotLastMessage(last(children.props?.state?.messages?.filter(el => el?.type === 'bot' && !!el?.botMessageId)))
        actions.setUserLastMessage(last(children.props?.state?.messages?.filter(el => el?.type === 'user')))

    }, [children.props.state.messages.length])

    useEffect(() => {
        if(children.props.state.userAnswers) {
            store.dispatch(setUserAnswers( {...children.props.state.userAnswers }));
        }

    }, [children.props.state.userAnswers]);

    const {botLastMessage} = children.props.state;

    // const timeoutFunc = async (func, timeout = 1000) => {
    //     setTimeout(func, timeout)
    // }
    useEffect(() => {
        const handleBotMessages = async () => {

            if (botLastMessage?.botMessageId && !children.props.state.stopTalking) {

                switch (botLastMessage.botMessageId) {
                    // case 'great': {
                    //     await timeoutFunc(() =>actions.handleNumberOfQuestions())
                    //     break;
                    // }
                    case 'niceToMeetYou': {

                         await delayMessageByVideoDurationWithTimeout(
                             `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                             () =>actions.handleNumberOfQuestions() )
                        break;
                    }
                    case 'helpToFind': {
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () => actions.handleBatteryLifeQuestion())
                        break
                    };
                    case 'batteryLifeResponseAnswer1':
                    case 'batteryLifeResponseAnswer2':
                    case 'batteryLifeResponseAnswer3':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handleWeightQuestion() )
                        break;
                    case 'weightResponseAnswer1':
                    case 'weightResponseAnswer2':
                    case 'weightResponseAnswer3':
                    case 'weightResponseAnswer4':
                    case 'weightResponseAnswer5':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handleNoiseCancellationQualityQuestion() )
                        // await timeoutFunc(() => actions.handleNoiseCancellingQuestion(), getDelay(botLastMessage.payload.message));
                        break;
                    case 'noiseCancellationQualityResponseAnswer1':
                    case 'noiseCancellationQualityResponseAnswer2':
                    case 'noiseCancellationQualityResponseAnswer3':
                    case 'noiseCancellationQualityResponseAnswer4':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handleBluetoothRangeQuestion() )
                        break;
                    // case 'noiseCancellingResponseValid':
                    //     await timeoutFunc(() => actions.handleBluetoothRangeQuestion(), getDelay(botLastMessage.payload.message));
                    //     break;
                    case 'bluetoothResponseAnswer1':
                    case 'bluetoothResponseAnswer2':
                    //     await timeoutFunc(() => actions.handleFrequencyQuestion(), getDelay(botLastMessage.payload.message));
                    //     break;
                    // case 'frequencyResponseValidAnswer':
                    //     await timeoutFunc(() => actions.handleChargingTimeQuestion(), getDelay(botLastMessage.payload.message));
                    //     break;

                    // case 'chargingTimeResponseValidAnswer':
                    //     await timeoutFunc(() => actions.handleFoldableQuestion(), getDelay(botLastMessage.payload.message));
                    //     break;

                    // case 'foldableResponseAnswer1':
                    // case 'foldableResponseAnswer2':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handleAndroidQuestion() )
                        break;
                    case 'androidResponseAnswer1':
                    case 'androidResponseAnswer2':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handleIosQuestion() )
                        break;
                    case 'iosResponseAnswer1':
                    case 'iosResponseAnswer2':
                    //     await timeoutFunc(() => actions.handleGoogleQuestion(), getDelay(botLastMessage.payload.message));
                    //     break;
                    // case 'googleResponseAnswer1':
                    // case 'googleResponseAnswer2':
                    //     await timeoutFunc(() => actions.handleAlexaQuestion(), getDelay(botLastMessage.payload.message));
                    //     break;
                    // case 'alexaResponseAnswer1':
                    // case 'alexaResponseAnswer2':
                    //     await timeoutFunc(() => actions.handleWarrantyQuestion(), getDelay(botLastMessage.payload.message));
                    //     break;

                    // case 'warrantyResponseValidAnswer':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handlePriceQuestion() )
                        break;
                    case 'priceResponseValidAnswer':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handleFeature1Question() )
                        break;
                    case 'mostImportantFeature1ResponseAnswer':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handleFeature2Question() )
                        break;
                    case 'mostImportantFeature2ResponseAnswer':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handleThanks() )
                        break;
                    // case 'thanks':
                    //     await timeoutFunc(() => actions.handleRecommend(), getDelay(botLastMessage.payload.message));
                    //     break;
                    case 'thanks':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handleJokeQuestion() )
                        break;

                    case 'jokePositive':
                    case 'jokeNegative':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handleRecommend() )
                        break;
                    case 'foundRecommended':
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>null )
                        await delayMessageByVideoDurationWithTimeout(
                            `/assets/videos2/${botLastMessage.payload.video}.mp4`,
                            () =>actions.handleLastMessage() )
                        break;
                    default:
                        break;
                }
            }
        };

        handleBotMessages();

        // Other useEffect dependencies
    }, [botLastMessage]);


    useEffect(() => {
        if (!!children.props.state.userAnswers.feature1) {

            actions.chooseFeature1Response()
        }

    }, [children.props.state.userAnswers.feature1])

    useEffect(() => {
        if (!!children.props.state.userAnswers.feature2) {
            actions.chooseFeature2Response()
        }

    }, [children.props.state.userAnswers.feature2])

    useEffect(() => {
        if (!!children.props.state.userAnswers.frequencyResponse) {
            actions.chooseFrequencyResponse(children.props.state.userAnswers.frequencyResponse)
        }

    }, [children.props.state.userAnswers.frequencyResponse])

    useEffect(() => {
        if(children.props.state.userAnswers.wantToSeeOtherHeadphones !== null) {
            store.dispatch(setUserAnswers({
                ...children.props.state.userAnswers,
                wantToSeeOtherHeadphones: children.props.state.userAnswers.wantToSeeOtherHeadphones
            }));
            if (children.props.state.userAnswers.wantToSeeOtherHeadphones) {
                actions.handleFinishSecondOne()
                setTimeout(actions.handleFinishSecondTwo, getDelay(botLastMessage.payload.message))
            } else {
                actions.handleFinishFirst()
            }
            if (!!children.props.state.userAnswers.wantToSeeOtherHeadphones) {
                if(children.props.state.messages.length!==1||children.props.state.messages.length!==0){
                    store.dispatch(setMessages(children.props.state.messages))

                }

            }
        }


    }, [children.props.state.userAnswers.wantToSeeOtherHeadphones])

    const parse = (message) => {
        if ((children.props.state.messages.length === 1 && !isQuestion(message) )
            // || botLastMessage.botMessageId==='hello'
        ) {
            return actions.handleNiceToMeetYou(message)
        }

        if (isQuestion(message)) {
            return actions.handleAnswerTheQuestion(message, botLastMessage,children.props.state.userAnswers.name )
        } else {
            switch (botLastMessage.botMessageId) {
                case 'repeatNameQuestion': {
                    return actions.handleNiceToMeetYou(message)
                }

                // case 'niceToMeetYou': {
                //     return actions.handleNumberOfQuestions()
                //     break;
                // }
                case 'batteryLifeQuestion':
                case 'batteryLifeQuestionClear':{
                    return actions.chooseBatteryLifeResponse(message)
                    break;
                }
                case 'weightQuestion':
                case 'weightQuestionClear':{
                    return actions.chooseWeightResponse(message)
                    break;
                }
                case "noiseCancellationQualityQuestion":
                    return actions.chooseNoiseCancellationQualityResponse(message)
                    break;
                // case 'noiseCancellingQuestion':
                // case 'noiseCancellingResponseNotValid': {
                //     return actions.chooseNoiseCancellingResponse(message)
                //     break;
                // }
                case 'bluetoothQuestion':
                case 'bluetoothQuestionClear':{
                    return actions.chooseBluetoothRangeResponse(message)
                    break;
                }
                // case 'frequencyQuestion':
                // case 'frequencyResponseNotValidAnswer':
                // case 'frequencyQuestionClear':{
                //     return actions.chooseFrequencyResponse(message)
                //     break;
                // }
                // case 'chargingTimeQuestion':
                // case 'chargingTimeQuestionClear':
                // case 'chargingTimeResponseNotValidAnswer': {
                //     return actions.chooseChargingTimeResponse(message)
                //     break;
                // }

                case 'foldableQuestion': {
                    return actions.chooseFoldableResponse(message)
                    break;
                }

                case 'androidQuestion': {
                    return actions.chooseAndroidResponse(message)
                    break;
                }
                case 'iosQuestion': {
                    return actions.chooseIosResponse(message)
                    break;
                }
                case 'googleQuestion': {
                    return actions.chooseGoogleResponse(message)
                    break;
                }
                case 'alexaQuestion': {
                    return actions.chooseAlexaResponse(message)
                    break;
                }
                case 'warrantyQuestion':
                case 'warrantyResponseNotValidAnswer':
                case 'warrantyBetweenClear':{
                    return actions.chooseWarrantyResponse(message)
                    break;
                }
                case 'priceQuestion':
                case 'priceQuestionClear':
                case 'priceResponseNotValidAnswer': {
                    return actions.choosePriceResponse(message)
                    break;
                }
                case 'jokeQuestion' : {
                    return actions.chooseJokeAnswer(message)
                    break;
                }
                default: {
                    return actions.handleSorry()
                    break;
                }

            }
        }
    };
    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions
                });
            })}
        </div>
    );
};

export default MessageParser;
