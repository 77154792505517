import React, {useEffect, useRef, useState} from 'react';
import {store} from '../../../store/store';
import {setUserAnswers} from '../../../store/slices/user-slice';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import getDelay from '../../../helpers/getDelay';
import {getSurveyLink} from '../../../helpers/getSurveyLink';
import MessageWidgetAnna from '../AnnaTalking/MessageWidget';
import MessageWidgetMichael from '../MichaelTalking/MessageWidget';


const FinishFirst = (props) => {
    const {botType} = useSelector((state) => state.user);
    const {message, state, setState,} = props

    const [show, setShow] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, botType==='machine-like' ? 100:getDelay(state.botLastMessage.message ||state.botLastMessage.payload.message))
    }, []);
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "start", inline: "nearest"
            });

        }
    }, [ref.current, show]);
    const renderMessageWidget = ()=>{
        if(botType === 'talking-head-f') {
            return  null
        } else
        if(botType === 'talking-head-m') {
            return  null
        }
    }
    return (
       show ?  <div className={'d-flex flex-column w-100'} ref={ref}>
           {message? message : renderMessageWidget()}
            <Link to={getSurveyLink(botType)} className={botType==='machine-like' ? 'mt-10' : ''}>
                <button className={'button border border-1 border-opacity-25 border-dark rounded-1 ml-50 button-white-bg'} style={{width:'80px'}}>OK
                </button>
            </Link>
        </div> : <></>
    );
};

export default FinishFirst;
