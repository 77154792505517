import { createChatBotMessage } from 'react-chatbot-kit';
import {Anna} from './Anna';
import FeatureFirst from '../widgets/FeatureFirst';
import FeatureSecond from '../widgets/FeatureSecond';
import Headphones from '../widgets/Headphones';
import LastMessage from '../widgets/LastMessage';
import AnnaAvatar from './AnnaAvatar';
import Frequency from '../widgets/Frequency';
import FinishFirst from '../widgets/FinishFirst';
import FinishSecond from '../widgets/FinishSecond';
import CustomBotMessage from './CustomBotMesage';
import AdditionalQuestionsQuestion from '../widgets/AdditionalQuestionsQuestion';

const AnnaBot = new Anna()
const botMessage = createChatBotMessage(AnnaBot.hello().message, {
    delay: 200
})
const config = {
    initialMessages: [{...botMessage, botMessageId: 'hello'}],
    botName: 'Anna',
    customComponents: {

        botAvatar: (props) => <AnnaAvatar {...props} />,
        botChatMessage: (props) => <CustomBotMessage {...props} />,

    },
    widgets: [
        {
            widgetName: 'feature1',
            widgetFunc: (props) => <FeatureFirst {...props} />,
            // mapStateToProps: ['messages'],
        },
                {
            widgetName: 'feature2',
            widgetFunc: (props) => <FeatureSecond {...props} />,
            // mapStateToProps: ['messages'],
        },
        {
            widgetName: 'headphones',
            widgetFunc: (props) => <Headphones {...props} />,
            // mapStateToProps: ['messages'],
        },
        {
            widgetName: 'lastMessage',
            widgetFunc: (props) => <LastMessage {...props} />,
            // mapStateToProps: ['messages'],
        },
        {
            widgetName: 'frequencyResponseWidget',
            widgetFunc: (props) => <Frequency {...props} />,
        },
        {
            widgetName: 'finishFirstWidget',
            widgetFunc: (props) => <FinishFirst {...props} />,
        },
        {
            widgetName: 'finishSecondWidget',
            widgetFunc: (props) => <FinishSecond {...props} />,
        },
    ],
    state: {
        productChoosedByBot: null,
        userLastMessage: null,
        botLastMessage: null,
        messageToRepeat: null,
        type: '',
        name: '',

        userAnswers: {
            name:null,
            batteryLife:null,
            // chargeTime: null,
            weight: null,
            // noiseCancellingType: null,
            noiseCancellationQuality: null,
            // foldable: null,
            bluetoothRange: null,
            // frequencyResponse: null,
            androidControl:null,
            // googleAssistant: null,
            iosControl: null,
            // amazonAlexa: null,
            // warranty: null,
            price: null,
            feature1: null,
            feature2: null,
            wantToSeeOtherHeadphones: null,
            joke: null,
        },

    },

};

export default config;
